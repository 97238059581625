import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {IMailRedirectModel} from "../../../models/mail/redirect/IMailRedirectModel";
import {INetworkComponentError} from "../../../network/types/INetworkComponentError";
import {ProductType} from "../../../models/product/ProductType";

export class MailRedirectService {
    public static redirects: IMailRedirectModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.redirects = observable([]);
    }

    /**
     * rest
     */

    public static async findByServer(serverId: string, component: INetworkComponent): Promise<IMailRedirectModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/mails/redirect/${serverId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async create(mailId: string, redirectionName: string, errors: INetworkComponentError[], component?: INetworkComponent): Promise<{
        redirect: IMailRedirectModel,
        status: HttpStatus
    }> {
        Services.handleErrors(component, errors);
        let request = await Network.post(ProductType.PANEL, "/mails/redirect",
            {
                redirectionName: redirectionName,
                mailId: mailId
            }, component);

        if (request.status != HttpStatus.OK) {
            return {redirect: undefined, status: request.status};
        }

        return {redirect: this.store(request.data), status: request.status};
    }

    public static async update(id: string, mailId: string, redirectionName: string, errors: INetworkComponentError[], component?: INetworkComponent): Promise<IMailRedirectModel> {
        Services.handleErrors(component, errors);
        let request = await Network.post(ProductType.PANEL, "/mails/redirect/update",
            {
                id: id,
                redirectionName: redirectionName,
                mailId: mailId
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(redirect: IMailRedirectModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/mails/redirect/${redirect.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(redirect);
            return true;
        }

        return false;
    }

    /**
     * store
     */

    public static storeAll(redirects: IMailRedirectModel[]): IMailRedirectModel[] {
        for (let key in redirects)
            redirects[key] = this.store(redirects[key]);

        return Services.storeAll(redirects);
    }

    public static store(redirect: IMailRedirectModel): IMailRedirectModel {
        return Services.store("id", this.redirects, redirect);
    }

    public static unStore(redirect: IMailRedirectModel) {
        Services.unStore("id", this.redirects, redirect);
    }

}