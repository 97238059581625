/**
 * Transforme du JSON en data pour les requêtes ajax
 */
export function queryString(object: any) {
    let data = "";
    let counter = 1;
    Object.keys(object).forEach((k) => {
        if (object[k] != undefined) {
            data += k + "=" + encodeURIComponent(object[k]) + (counter >= Object.keys(object).length ? "" : "&");
            counter++;
        }
    });
    return data;
}