import {SedestralRouter} from "../../../../sedestral-interface-modules/sedestral-interface-router/SedestralRouter";
import {LoaderStatic} from "../../../panel/components/static/loaders/LoaderStatic";
import {
    ISedestralRouterConfig
} from "../../../../sedestral-interface-modules/sedestral-interface-router/types/ISedestralRouterConfig";
import {CommunityService} from "../../../../services/community/CommunityService";
import {Resources} from "../../../../resources/Resources";
import {CommunityNetworkLostComponent} from "./network/CommunityNetworkLostComponent";

export class CommunityRouter extends SedestralRouter {
    async init(config?: ISedestralRouterConfig): Promise<void> {
        super.init(config);

        if (config.started) {
            super.initStarted();
        } else {
            super.initNotStarted(new CommunityNetworkLostComponent());
        }
    }

    registerPages() {
        this.registerDynamicPage();
    }

    registerDynamicPage() {
        let loaderTimeout;
        this.pages.getDynamicPage = async (path) => {
            loaderTimeout = setTimeout(() => this.static.components.loader.displayShow(), 150);

            let paths = path.split("/");
            let addingToRoute = ``;

            if (paths.includes("page")) {
                let page = paths[3];
                paths.splice(2, path.length - 2);
                addingToRoute = `/page/${page}`;
            }

            if (paths.length == 2) {
                let categoryRoute = paths[1];
                path = `/category/${categoryRoute}${addingToRoute}`;

                if (categoryRoute != "home") {
                    if (!await CommunityService.checkRoute(Resources.language, categoryRoute, undefined)) {
                        this.static.components.loader.displayHide();
                        clearTimeout(loaderTimeout);
                        return;
                    }
                }
            } else if (paths.length >= 3) {

                let categoryRoute = paths[1];
                let articleRoute = paths[2];
                path = `/article/${articleRoute}`;

                if (!await CommunityService.checkRoute(Resources.language, categoryRoute, articleRoute)) {
                    this.static.components.loader.displayHide();
                    clearTimeout(loaderTimeout);
                    return;
                }
            }


            this.pages.dynamicPath = path;
            this.static.components.loader.displayHide();
            clearTimeout(loaderTimeout);
            return await this.pages.getPage(path);
        }
    }

    registerStatic() {
        this.static.register("loader", new LoaderStatic());
        this.static.register("pageLoader", new LoaderStatic());
    }

    onLogin() {
        this.pages.redirect(this.pages.pathHome);
    }

    onLogout() {
        setTimeout(async () => {
            await this.pages.redirect(this.pages.pathLogin);
        }, 3000);
    }
}