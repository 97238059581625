import {Network} from "../../../network/Network";
import {SedestralMachine} from "../machine/SedestralMachine";
import {Resources} from "../../../resources/Resources";
import {config} from "../../../config";
import {ProductName} from "../../../models/product/ProductName";
import {ProductType} from "../../../models/product/ProductType";

export class SedestralSsr {

    private static ssrPath: boolean;

    static async init() {
        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["ssr"] = this;
        }
        this.ssrPath = window.location.pathname?.endsWith("sedestral.ssr");
    }

    static hasSsr(): boolean {
        return this.ssrPath;
    }

    static async awaitProcessing() {
        await this.awaitRequests();
        let promises = SedestralMachine.promises.filter(promise => promise.displayRequired);
        if (promises.length > 0) {
            await Promise.all(promises.map(promise => promise.promise));
        }
    }

    static async awaitRequests() {
        let request = await this.awaitRequest();
        if (request) {
            return await this.awaitRequests();
        }
    }

    static async awaitRequest() {
        return SedestralMachine.promise((resolve) => {
            let origOpen = XMLHttpRequest.prototype.open;
            let timeOut = setTimeout(() => {
                resolve(false);
            });

            XMLHttpRequest.prototype.open = function () {
                this.addEventListener('load', function () {
                    resolve(true);
                });
                clearTimeout(timeOut);
                origOpen.apply(this, arguments);
                XMLHttpRequest.prototype.open = origOpen;
            };
        });
    }

    /**
     * SSR
     */

    static async renderPageSsr(path: string) {
        document.body.className = "";
        await Network.router.pages.reload(path);
        await this.awaitProcessing();

        await SedestralMachine.sleep(1);

        if (Network.router.static.components.loader) {
            Network.router.static.components.loader.displayShow();
        }

        document.body.className += "ssrPageReady";
    }

    static getRoutesSsr() {

        if (Network.productName == ProductName.toString(ProductType.BLOG) || Network.productName === ProductName.toString(ProductType.HELPCENTER)) {
            return Resources.languages.flatMap(language => [
                "/" + language + "/home",
            ]).join(",");
        }

        return Resources.languages.flatMap(language => {
            return Object.keys(Network.router.pages.pages)
                .map(route => "/" + language + route)
        }).join(",");
    }
}