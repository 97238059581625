import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {IMailDomainModel} from "../../../models/mail/domain/IMailDomainModel";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {ProductType} from "../../../models/product/ProductType";

export class MailDomainService {
    public static domains: IMailDomainModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.domains = observable([]);
    }

    /**
     * rest
     */
    public static async create(name: string, serverId: string, component?: INetworkComponent): Promise<IMailDomainModel> {
        let request = await Network.post(ProductType.PANEL, "/mails/domains",
            {
                name: name,
                serverId: serverId
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async verify(id: string, component?: INetworkComponent): Promise<IMailDomainModel> {
        let request = await Network.post(ProductType.PANEL, "/mails/domains/verify",
            {
                id: id
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(domain: IMailDomainModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/mails/domains/${domain.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(domain);
            return true;
        }

        return false;
    }

    public static async findByServer(serverId: string, includeDomainPublic: boolean, component: INetworkComponent): Promise<IMailDomainModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/mails/domains/${serverId}/${includeDomainPublic}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static storeAll(domains: IMailDomainModel[]): IMailDomainModel[] {
        for (let key in domains)
            domains[key] = this.store(domains[key]);

        return Services.storeAll(domains);
    }

    public static store(domain: IMailDomainModel): IMailDomainModel {
        return Services.store("id", this.domains, domain);
    }

    public static unStore(domain: IMailDomainModel) {
        Services.unStore("id", this.domains, domain);
    }
}