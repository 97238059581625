@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.componentLoaderObjectBlog {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.shine {
    .case {
      @include animations.shine;
    }
  }


  .cases {
    gap: 20px;
    column-gap: 60px;
    row-gap: 25px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    .case {
      width: calc(50% - 30px);
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      transition: 0.07s;
      margin-bottom: 15px;
      background: var(--grey-sur);
    }
  }
}