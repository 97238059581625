import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./share.scss";
import {Resources} from "../../../../../resources/Resources";

export class ShareComponent extends Component {

    public networkIcons: Component[];

    constructor(name: string, url: string) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentShare}">
                <div class="${s.title}">${Resources.t("words.share")}</div>
                <div class="${s.networks}">
                    <a aria-label="${Resources.t("words.shareTo")} LinkedIn"
                       href="https://www.linkedin.com/sharing/share-offsite/?url=${url}"
                       target="_blank">
                        <div class="${s.entry} ${s.linkedin}">
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                    <a aria-label="${Resources.t("words.shareTo")} Twitter"
                       href="https://twitter.com/share?text=${name}&url=${url}"
                       target="_blank"
                       rel="noopener">
                        <div class="${s.entry} ${s.twitter}">
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                    <a aria-label="${Resources.t("words.shareTo")} Facebook"
                       href="https://www.facebook.com/sharer/sharer.php?u=${url}&t=${name}"
                       target="_blank">
                        <div class="${s.entry} ${s.facebook}">
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                    <a aria-label="${Resources.t("words.shareTo")} Mail"
                       href="mailto:?body=${name}%0A${url}&subject=${name}"
                       target="_blank">
                        <div class="${s.entry} ${s.mail}">
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();

        this.networkIcons = this.els(s.icon);
    }

}