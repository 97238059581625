import {ProductType} from "./ProductType";

export class ProductName {
    static toString(type: ProductType) {
        switch (type) {
            case ProductType.SEDESTRAL:
                return "sedestral";
            case ProductType.INBOX:
                return "inbox";
            case ProductType.PUBLISHER:
                return "publisher";
            case ProductType.STATISTICS:
                return "statistics";
            case ProductType.CONTACTS:
                return "contacts";
            case ProductType.CHANNELS:
                return "channels";
            case ProductType.LIVECHAT:
                return "livechat";
            case ProductType.PANEL:
                return "panel";
            case ProductType.GENERAL:
                return "general";
            case ProductType.CHAT_BOT:
                return "chatBot";
            case ProductType.MAILS:
                return "mails";
            case ProductType.SELL:
                return "sell";
            case ProductType.ENGAGEMENT:
                return "engagement";
            case ProductType.ADVERTISING:
                return "advertising";
            case ProductType.MAILLING:
                return "mailling";
            case ProductType.MEDIAS:
                return "medias";
            case ProductType.CALENDAR:
                return "calendar";
            case ProductType.DOCUMENTS:
                return "documents";
            case ProductType.PRODUCTS:
                return "products";
            case ProductType.ESTIMATE:
                return "estimate";
            case ProductType.CONTACTS_GREY:
                return "contactsGrey";
            case ProductType.INBOX_GREY:
                return "inboxGrey";
            case ProductType.PUBLISHER_GREY:
                return "publisherGrey";
            case ProductType.HELPCENTER:
                return "helpCenter";
            case ProductType.WEBSITE:
                return "website";
            case ProductType.CLIENT_SERVICE:
                return "clientService";
            case ProductType.BLOG:
                return "blog";
            case ProductType.MOBILE:
                return "mobile";
            case ProductType.COMMUNITY:
                return "community";
            case ProductType.ADMIN:
                return "admin";
        }
    }
}