import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IFileModel} from "../../models/file/IFileModel";
import {Network} from "../../network/Network";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {HttpStatus} from "../../network/status/HttpStatus";
import {FileOrigin} from "../../models/file/FileOrigin";
import {ProductType} from "../../models/product/ProductType";
import {config} from "../../config";

export class FileService {
    public static files: IFileModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.files = observable([]);
    }

    /**
     * rest
     */


    public static async create(siteId: string, origin: FileOrigin, files: File[], progress?: (progress: number) => void, component?: INetworkComponent): Promise<IFileModel[]> {
        let request = await Network.postFormData(ProductType.PANEL, `/files`, {
            files: files,
            siteId: siteId,
            origin: origin
        }, component, {
            onUploadProgress: (progressEvent) => {
                progress(progressEvent.loaded * 100 / progressEvent.total);
            }
        });

        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }


    /**
     * link
     */

    public static link(file: IFileModel) {
        return config.httpPanelUrl + `/files/${file.id}.${file.extension}`;
    }

    public static storeAll(files: IFileModel[]): IFileModel[] {
        for (let key in files)
            files[key] = this.store(files[key]);

        return Services.storeAll(files);
    }

    public static store(file: IFileModel): IFileModel {
        file.link = FileService.link(file);
        return Services.store("id", this.files, file);
    }
}