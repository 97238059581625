/**
 * transforme un texte au format lien
 */

export function txtFormatLink(txt: string) {
    txt = txt.trim().toLowerCase();
    txt = txt.normalize("NFD");
    txt = txt.replace(/[\u0300-\u036f]/g, "");
    txt = txt.replace(/[^a-zA-Z0-9 -]/g, '');
    txt = txt.replace(/[-\s]+/g, '-');
    return txt;
}