import {Component} from "../../../sedestral-interface-component/interface/component/Component";

export abstract class StaticComponent extends Component {
    constructor() {
        super();
        this.template = "<div>static</div>";
    }

    // @ts-ignore
    init(): void {
        this.commit();
    }

    onHash(): void {

    }
}