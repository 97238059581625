import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IAvatarModel} from "../../models/avatar/IAvatarModel";
import {FileService} from "../file/FileService";
import {AvatarColorService} from "./color/AvatarColorService";
import {IAvatarActiveModel} from "../../models/avatar/IAvatarActiveModel";
import {EntityService} from "../entity/EntityService";

export class AvatarService {
    public static avatars: IAvatarModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.avatars = observable([]);
    }

    public static storeAll(avatars: IAvatarModel[]): IAvatarModel[] {
        for (let key in avatars)
            avatars[key] = this.store(avatars[key]);

        return Services.storeAll(avatars);
    }

    public static store(avatar: IAvatarModel): IAvatarModel {
        let storedAvatar = Services.get("id", this.avatars, avatar.id);
        if (storedAvatar != undefined) {

            if (avatar.file == undefined) {
                storedAvatar.file = undefined;
            }

            if (avatar.color == undefined) {
                storedAvatar.color = undefined;
            }
        }


        if (avatar.file != undefined) {
            avatar.file = FileService.store(avatar.file);
        }
        if (avatar.color != undefined) {
            avatar.color = AvatarColorService.store(avatar.color);
        }

        return Services.store("id", this.avatars, avatar);
    }

    public static isActive(activeModel: IAvatarActiveModel) {
        return activeModel["siteId"] != undefined ? activeModel.active && activeModel["siteId"] == EntityService.activeSite.id : activeModel.active;
    }
}