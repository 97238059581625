import {SedestralInterface} from "../interface/SedestralInterface";
import {Component} from "../interface/component/Component";
import {config} from "../../../config";
import {ISedestralRum} from "./rum/ISedestralRum";

export class SedestralDebugger {

    public static rum?: ISedestralRum;
    public static listenersLogs: { log: string, time: number }[] = [];

    public static async init() {
        if (config.rumEnabled) {
            try {
                this.rum = new (await import("./rum/SedestralRum")).SedestralRum();
            } catch (e) {
                console.error(e);
            }
        }

        window.onerror = (error, url, line, column, e) => {
            /*
            console.log(error);
            console.log(url);
            console.log(line);
            console.log(column);

             */
            this.addError("UNDEFINED", e)
        };


        window.onabort = () => {
            console.log("abort")
        }
    }

    public static addError(title, error) {
        if (error) {
            let now = performance.now();
            let listeners = this.listenersLogs.filter(value => value.time > (now - 100));
            let trace = listeners.map(value => value.log).join("\n=> ");

            console.log('%c' + title, 'color: white;font-size:14px;background:red;padding:3px;');
            if (listeners.length > 0) {
                console.log("=> " + trace);
            }

            console.error(error);
            this.rum?.error(error, trace);
        }
    }


    public static eventTarget(component: Component, name: string) {
        let child = SedestralInterface.components.filter(value => value.childrens.find(value => value == component) != undefined)[0];
        this.listenersLogs.push({
            log: "[[" + name + " - " +
                (child == undefined ? "" : child.constructor.name) +
                " - " + component.constructor.name +
                " - " + component.element['className'] +
                "]]", time: performance.now()
        });
    }

}