export enum ValueType {
    UUID = 0,
    STRING = 1,
    BOOLEAN = 2,
    INTEGER = 3,
    DATE = 4,
    LIST = 5,
    LONG = 6,
    NULL = 7,
    PHONE = 8,
    MAIL = 9,
    LANG = 10
}