import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-article.scss";
import {BlogArticleOwnerComponent} from "./owner/BlogArticleOwnerComponent";
import {ICommunityArticleModel} from "../../../../../../../../models/community/article/ICommunityArticleModel";
import {IAccountModel} from "../../../../../../../../models/account/IAccountModel";
import {ICommunityCategoryModel} from "../../../../../../../../models/community/category/ICommunityCategoryModel";
import {Resources} from "../../../../../../../../resources/Resources";
import {ICommunityLiveModel} from "../../../../../../../../models/community/ICommunityLiveModel";
import {
    dateFormatMeta
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormatMeta";
import {
    filterXSS
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {
    dateFormatArticle
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormatArticle";
import 'lazysizes';
import {
    IMG_EMPTY
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/SedestralUtils";
import {CommunityService} from "../../../../../../../../services/community/CommunityService";
import {ProductType} from "../../../../../../../../models/product/ProductType";

export class BlogArticleComponent extends Component {

    public live: ICommunityLiveModel;
    public article: ICommunityArticleModel;

    public imageContainer: Component;
    public owner: BlogArticleOwnerComponent;

    constructor(live: ICommunityLiveModel, article: ICommunityArticleModel, category: ICommunityCategoryModel) {
        super();
        this.live = live;
        this.article = article;

        let writers = article.writersRedactors.concat(article.writersAccounts);
        this.owner = new BlogArticleOwnerComponent(live, article, writers);

        //language=HTML
        this.template = `
            <article itemprop="blogPosts" itemscope itemtype="https://schema.org/BlogPosting"
                     class="${s.componentBlogArticle}">
                <meta itemprop="url" content="${CommunityService.linkArticle(category, article)}">
                <meta itemprop="inLanguage" content="${category.language}">
                <meta itemprop="articleSection" content="${filterXSS(category.title)}">
                <div class="${s.image}"></div>
                <div class="${s.content}">
                    <h2 itemprop="headline" class="${s.title}">
                        ${filterXSS(this.article.title)}
                    </h2>
                    <p itemprop="description" class="${s.text}">
                        ${filterXSS(this.article.snippet)}
                    </p>
                </div>

                <div class="${s.details}">
                    ${dateFormatArticle(new Date(this.article.time))} | ${filterXSS(category.title)} |
                    ${Math.round(this.article.readingSeconds / 60)} ${Resources.t("words.timeToRead")}
                </div>

                ${writers.length > 0 ? `
                    <div class="${s.bottom}">
                        <div class="${s.ownerContainer}">
                            ${this.draw(this.owner)}
                        </div>
                    </div>` : ''}

                ${this.article.keywords.length > 0 ? `<meta itemprop="keywords" content="${filterXSS(this.article.keywords.join(", "))}">` : ``}
                <meta itemprop="datePublished" content="${dateFormatMeta(new Date(this.article.time))}">
                ${this.article.lastEditTime > 0 ? `<meta itemprop="dateModified" content="${dateFormatMeta(new Date(this.article.lastEditTime))}">` : ``}
                <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
                    <meta itemprop="name" content="${this.live.community.name}">
                    ${this.live.community.logoFile ? `<link itemprop="logo" href="${this.live.community.logoFile.link}">` : ``}
                </div>
            </article>
        `;
    }

    commit() {
        this.imageContainer = this.el(s.image);

        let title = this.el(s.title);
        let text = this.el(s.text);
        let details = this.el(s.details);
        this.onReactiveObserve(() => {
            title.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].titleColor};`);
            text.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor};`);
            details.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondTextColor};`);
        });

        this.renderImage();
        super.commit();
    }

    renderImage() {
        if (this.article.imageFile) {
            //language=HTML
            this.imageContainer.append(`
                <img class="lazyload" itemprop="image" alt="${this.article.title}"
                     src="${IMG_EMPTY}"
                     data-src="${this.article.imageFile?.link}" 
                     data-sizes="auto"
                     width="auto"
                     height="auto"
                />
            `);

            //language=HTML
            this.imageContainer.append(`
                <noscript>
                    <img itemprop="image" src="${this.article.imageFile?.link}" alt="${this.article.title}" width="auto" height="auto"/>
                </noscript>
            `);
        } else {
            this.imageContainer.addClass(s.empty);

            if (this.live.community?.logoFile) {
                //language=HTML
                this.imageContainer.append(`
                    <img itemprop="image" alt="${this.live.community.text.metaTitle}"
                         src="${this.live.community.logoFile.link}"
                         width="auto"
                         height="auto"
                    />
                `);
            }
        }
    }
}