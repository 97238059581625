import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IContactModel} from "../../models/contact/IContactModel";
import {AvatarService} from "../avatar/AvatarService";
import {ContactChannelService} from "./channel/ContactChannelService";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {EntityType} from "../../models/entity/types/EntityType";
import {ContactSessionService} from "./session/ContactSessionService";
import {NoteService} from "../note/NoteService";
import {INoteModel} from "../../models/note/INoteModel";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {IContactDetailsModel} from "../../models/contact/IContactDetailsModel";
import {ProductType} from "../../models/product/ProductType";
import {IContactChannelModel} from "../../models/contact/channel/IContactChannelModel";
import {IContactFindOutgoing} from "../../models/contact/IContactFindOutgoing";
import {IContactFindIncoming} from "../../models/contact/IContactFindIncoming";
import {ContactState} from "../../models/contact/ContactState";

export class ContactService {
    public static contacts: IContactModel[] = observable([]);
    public static details: IContactDetailsModel[] = observable([]);

    public static dispose(): void {
        this.contacts = observable([]);
        this.details = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.CONTACT_UPDATE, (data) => this.store(data));
    }

    /**
     * load
     */

    public static async findById(id: string, component?: INetworkComponent): Promise<IContactModel> {
        let request = await Network.get(ProductType.PANEL, `/contact/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async findChannelsById(id: string, component?: INetworkComponent): Promise<IContactChannelModel[]> {
        let request = await Network.get(ProductType.PANEL, `/contact/channels/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return ContactChannelService.storeAll(request.data);
        }

        return undefined;
    }

    public static async findDetailsById(id: string, component?: INetworkComponent): Promise<IContactDetailsModel> {
        component.addNetworkErrors([
            {status: HttpStatus.NOT_FOUND, message: "none"},
            {status: HttpStatus.UNAUTHORIZED, message: "none"}
        ]);


        let request = await Network.get(ProductType.PANEL, `/contact/details/${id}`, component);
        if (request.status == HttpStatus.OK) {
            request.data.contact = this.store(request.data.contact);
            request.data.id = request.data.contact.id;
            if (request.data.notes != undefined) {
                request.data.notes = NoteService.storeAll(request.data.notes);
            }

            request.data.lastSession = ContactSessionService.store(request.data.lastSession);

            return this.storeDetails(request.data);
        }

        return undefined;
    }

    public static async findActivesStats(component?: INetworkComponent): Promise<any> {
        let request = await Network.get(ProductType.PANEL, `/contact/actives`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findCounts(siteId: string, component?: INetworkComponent): Promise<any> {
        let request = await Network.get(ProductType.PANEL, `/contact/counts/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findAll(data: IContactFindOutgoing, component?: INetworkComponent): Promise<IContactFindIncoming> {
        let request = await Network.postJson(ProductType.PANEL, `/contact/find`, data, component);
        if (request.status == HttpStatus.OK) {
            let all: IContactFindIncoming = request.data;
            all.contacts = this.storeAll(all.contacts);

            return all;
        }

        return undefined;
    }

    public static async update(id: string, name: string, email: string, component?: INetworkComponent): Promise<IContactModel> {
        let request = await Network.post(ProductType.PANEL, "/contact/update",
            {
                id: id,
                name: name,
                email: email
            }, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async state(contactId: string, state: ContactState, component?: INetworkComponent): Promise<IContactModel> {
        let request = await Network.get(ProductType.PANEL, `/contact/state/${contactId}/${state}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }


    public static async create(contactId: string, name: string, email: string, numberPhone: string, component?: INetworkComponent): Promise<IContactModel> {
        let request = await Network.post(ProductType.PANEL, "/contact/create",
            {
                contactId: contactId,
                email: email,
                name: name,
                numberPhone: numberPhone
            }, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async updateAll(contacts: IContactModel[], component?: INetworkComponent): Promise<IContactModel[]> {
        let request = await Network.postJson(ProductType.PANEL, "/contact/update/all", contacts, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    /**
     * logic
     */


    public static addNote(contactId: string, note: INoteModel) {
        let details = this.details.find(value => value.id == contactId);
        if (details) {
            if (details.notes != undefined && details.notes.filter(value => value.id == note.id)[0] == undefined) {
                details.notes.push(note);
            }
        }
    }

    public static removeNote(contactId: string, note: INoteModel) {
        let details = this.details.find(value => value.id == contactId);
        if (details) {
            if (details.notes != undefined && details.notes.filter(value => value.id == note.id)[0] != undefined) {
                details.notes.splice(details.notes.indexOf(note), 1);
            }
        }
    }

    /**
     * websocket
     */
    public static async join(id: string) {
        if (id != null) {
            await Network.join(ProductType.PANEL, id, WebSocketPanelEventName.CONTACT_JOIN);
        }
    }

    public static leave(id: string) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.CONTACT_LEAVE, id);
    }

    /**
     * store
     */

    public static storeDetails(details: IContactDetailsModel): IContactDetailsModel {
        return Services.store("id", this.details, details);
    }

    public static storeAll(contacts: IContactModel[]): IContactModel[] {
        for (let key in contacts)
            contacts[key] = this.store(contacts[key]);

        return Services.storeAll(contacts);
    }

    public static store(contact: IContactModel): IContactModel {
        if (contact.avatar != undefined) {
            contact.avatar = AvatarService.store(contact.avatar);
        }

        if (contact.channels != undefined) {
            contact.channels = ContactChannelService.storeAll(contact.channels);
        }

        if (!contact.columnsData) {
            contact.columnsData = {};
        }


        contact.type = EntityType.CONTACT;
        contact = Services.store("id", this.contacts, contact);

        return contact;
    }

    public static storeActives(contactsActives: { [key: string]: boolean }) {
        for (let contactId in contactsActives) {
            let active = contactsActives[contactId];

            let contact = Services.get("id", this.contacts, contactId);
            if (contact !== undefined) {
                contact.active = active;
                Services.store("id", this.contacts, contact);
            }
        }
    }
}