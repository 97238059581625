import {HexFilterColor} from "./HexFilterColor";
import {HexFilterSolver} from "./HexFilterSolver";

export class HexFilterConverter {
    static hexToRgb(hex: string) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? [
                parseInt(result[1], 16),
                parseInt(result[2], 16),
                parseInt(result[3], 16),
            ]
            : null;
    }

    static convert(hex: string): string {
        const rgb = this.hexToRgb(hex);
        const color = new HexFilterColor(rgb[0], rgb[1], rgb[2]);
        const solver = new HexFilterSolver(color);
        const result = solver.solve();

        return result.filter;
    }
}