import {
    Component
} from "../../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-article-owner.scss";
import {ICommunityArticleModel} from "../../../../../../../../../models/community/article/ICommunityArticleModel";
import {Resources} from "../../../../../../../../../resources/Resources";
import {ICommunityLiveModel} from "../../../../../../../../../models/community/ICommunityLiveModel";
import {
    filterXSS
} from "../../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {ProductType} from "../../../../../../../../../models/product/ProductType";
import {
    join
} from "../../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/String";
import {ICommunityRedactor} from "../../../../../../../../../models/community/redactors/ICommunityRedactor";

export class BlogArticleOwnerComponent extends Component {

    public live: ICommunityLiveModel;

    public writers: ICommunityRedactor[];
    public article: ICommunityArticleModel;

    public names: Component;

    constructor(live: ICommunityLiveModel, article: ICommunityArticleModel, writers: ICommunityRedactor[]) {
        super();
        this.live = live;
        this.writers = writers;
        this.article = article;

        //language=HTML
        this.template = `
            <div class="${s.componentBlogArticleOwner}">
                <div itemprop="author" class="${s.names}">
                    <div itemprop="name" class="${s.creatorName}">
                        ${join(writers.map(value => filterXSS(value.name)))}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.names = this.el(s.names);

        this.onReactiveObserve(() => {
            this.names.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor};`);
        });

        super.commit();
    }

}