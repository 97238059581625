import {observable} from "@nx-js/observer-util";
import {Services} from "../../../Services";
import {
    IInboxConversationMessageModel
} from "../../../../models/inbox/conversation/message/IInboxConversationMessageModel";
import {InboxConversationMessageAttachmentService} from "./attachment/InboxConversationMessageAttachmentService";
import {INetworkComponent} from "../../../../network/types/INetworkComponent";
import {Network} from "../../../../network/Network";
import {HttpStatus} from "../../../../network/status/HttpStatus";
import {IFileModel} from "../../../../models/file/IFileModel";
import {IPreviewModel} from "../../../../models/preview/IPreviewModel";
import {WebSocketPanelEventName} from "../../../../network/socket/names/WebSocketPanelEventName";
import {InboxConversationService} from "../InboxConversationService";
import {InboxConversationMessageType} from "../../../../models/inbox/conversation/message/InboxConversationMessageType";
import {EntityType} from "../../../../models/entity/types/EntityType";
import {IEntityModel} from "../../../../models/entity/IEntityModel";
import {ComType} from "../../../../models/enums/ComType";
import {IInboxConversationModel} from "../../../../models/inbox/conversation/IInboxConversationModel";
import {ComState} from "../../../../models/enums/ComState";
import {
    IInboxConversationMessageMetaDataModel
} from "../../../../models/inbox/conversation/message/metadata/IInboxConversationMessageMetaDataModel";
import {
    IInboxConversationMessageMetaDataMailRecipient
} from "../../../../models/inbox/conversation/message/metadata/mail/IInboxConversationMessageMetaDataMailRecipient";
import {ComSendType} from "../../../../models/enums/ComSendType";
import {EntityService} from "../../../entity/EntityService";
import {MailRecipientsType} from "../../../../models/enums/MailRecipientsType";
import {ComTransmittedStatus} from "../../../../models/enums/ComTransmittedStatus";
import {
    IInboxConversationMessageOutgoing
} from "../../../../models/inbox/conversation/message/IInboxConversationMessageOutgoing";
import {ProductType} from "../../../../models/product/ProductType";
import {ErrorCode} from "../../../../network/status/error/ErrorCode";
import {
    IInboxConversationMessageCreatorOutgoing
} from "../../../../models/inbox/conversation/message/IInboxConversationMessageCreatorOutgoing";
import {Resources} from "../../../../resources/Resources";
import {
    objectEquals
} from "../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectEquals";

export class InboxConversationMessageService {
    public static messages: IInboxConversationMessageModel[] = observable([]);

    public static dispose(): void {
        this.messages = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.MESSAGE_NEW, (data) => {
            let message: IInboxConversationMessageModel = data;
            message = this.store(message);
            InboxConversationService.newMessage(message);
        });

        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.MESSAGE_UPDATE, (data) => {
            let message: IInboxConversationMessageModel = data;
            this.store(message);
        });
    }

    /**
     * HTTP
     */

    public static async findByConversationId(conversationId: string, count: number, beforeTime: number, component?: INetworkComponent): Promise<IInboxConversationMessageModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/messages/conversation/${conversationId}/${count}/${beforeTime}`, component);
        if (request.status == HttpStatus.OK) {
            //TODO récupérer l'état d'importation ici request.data.inProgressImportation
            return this.storeAll(request.data.messages);
        }

        return [];
    }

    public static async findCreator(messageId: string, component?: INetworkComponent): Promise<IInboxConversationMessageCreatorOutgoing> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/messages/creator/${messageId}`, component);
        if (request.status == HttpStatus.OK) {
            return {
                message: this.store(request.data.message),
                conversation: InboxConversationService.store(request.data.conversation)
            }
        }

        return undefined;
    }


    public static async create(p: {
        conversationId: string,
        siteChannelId: string,
        type: InboxConversationMessageType,
        text: string,
        filesIds: string[],
        previewsUrls: string[],
        sendType: ComSendType,
        replyToId?: string,
        comType?: ComType,
        comState?: ComState,
        scheduledTime?: number,
        metaData?: IInboxConversationMessageMetaDataModel,
        deltas?: any
    }, component?: INetworkComponent): Promise<IInboxConversationMessageModel> {

        Services.handleErrors(component, [
            {errorCode: ErrorCode.URL_FORMAT, message: Resources.t("words.invalidAttachmentUrl")},
            {status: HttpStatus.NOT_FOUND, message: Resources.t("words.messageNoFound")}
        ]);

        let request = await Network.postJson(ProductType.PANEL, "/messages", p, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }


    public static async retry(messageId: string, subId?: string, component?: INetworkComponent): Promise<HttpStatus> {
        let request = await Network.post(ProductType.PANEL, "/messages/retry", {
            messageId: messageId,
            subId: subId
        }, component);

        return request.status;
    }

    public static async update(messageId: string, text: string, files: IFileModel[], previews: IPreviewModel[], component?: INetworkComponent): Promise<IInboxConversationMessageModel> {
        let filesIds = files.map(value => value.id);
        let previewsUrls = previews.map(value => value.baseUrl);
        let request = await Network.post(ProductType.PANEL, "/messages/update",
            {
                messageId: messageId,
                text: text,
                filesIds: filesIds,
                previewsUrls: previewsUrls
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async sendDraft(messageId: string, html: string, scheduleTime: number, component: INetworkComponent): Promise<IInboxConversationMessageModel | number> {
        Services.handleErrors(component, [
            {status: HttpStatus.PAYLOAD_TOO_LARGE, message: Resources.t("words.emailSizeExceeded")},
            {
                status: HttpStatus.INSUFFICIENT_STORAGE,
                message: Resources.t("words.storageLimitExceeded")
            },
            {
                status: HttpStatus.BANDWIDTH_LIMIT,
                message: Resources.t("words.sendingLimitExceeded")
            }
        ]);

        let request = await Network.post(ProductType.PANEL, "/messages/send/draft",
            {
                messageId: messageId,
                scheduleTime: scheduleTime,
                html: html
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return request.status;
    }

    public static async delete(id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/messages/${id}`, component);
        if (request.status == HttpStatus.OK) {
            InboxConversationMessageService.store(request.data);
            return true;
        }

        return false;
    }

    /**
     * logic
     */

    public static findingCount(conversation: IInboxConversationModel): number {
        if (conversation) {
            switch (conversation.comType) {
                case ComType.DIRECT_MESSAGE:
                    return 30;
                case ComType.EMAIL:
                    return 25;
            }
        }

        return 25;
    }

    public static findingRecipients(conversation: IInboxConversationModel, message: IInboxConversationMessageModel, type: MailRecipientsType): IInboxConversationMessageMetaDataMailRecipient[] {
        let finding: IInboxConversationMessageMetaDataMailRecipient[] = [];
        let recipients = message.metaData.mail[type as string];

        recipients.forEach(recipient => {
            if (!finding.find(v => objectEquals(recipient, v))) {
                finding.push({...recipient});
            }
        });

        return finding;
    }

    public static findingRecipientFrom(conversation: IInboxConversationModel, message: IInboxConversationMessageModel): IInboxConversationMessageMetaDataMailRecipient {
        if (message.senderType == EntityType.ACCOUNT) {
            let siteChannel = conversation.siteChannels.find(value => value.id == message.senderSiteChannelId);
            let participant = InboxConversationService.getParticipantByEntity(message.senderType, message.senderId, conversation);
            return {
                mail: siteChannel.name,
                name: participant.participant.name,
                entityType: message.senderType,
                entityId: message.senderId
            };
        } else if (message.senderType == EntityType.CONTACT) {
            let contactChannel = conversation.contactChannels.find(value => value.id == message.senderContactChannelId);
            return {
                mail: contactChannel.identifier,
                name: contactChannel.identifierName,
                entityType: message.senderType,
                entityId: message.senderId
            };
        }
    }

    public static toReplyMetaData(conversation: IInboxConversationModel, message: IInboxConversationMessageModel, sendType: ComSendType, all?: boolean): IInboxConversationMessageMetaDataModel {
        let prefix = "RE: ";
        let subject = message.metaData.mail.subject;

        let metadata: IInboxConversationMessageMetaDataModel = {
            mail: {
                to: [this.findingRecipientFrom(conversation, message)],
                subject: subject.toLowerCase().startsWith(prefix.toLowerCase()) ? subject : (prefix + subject),
                sendType: sendType
            }
        };

        if (all) {
            metadata.mail.cc = this.findingRecipients(conversation, message, MailRecipientsType.TO)
                .concat(this.findingRecipients(conversation, message, MailRecipientsType.CC));
            metadata.mail.bcc = this.findingRecipients(conversation, message, MailRecipientsType.BCC);
        }

        return metadata;
    }

    public static isMine(message: IInboxConversationMessageModel) {
        return message.senderId == EntityService.activeEntity.id && message.senderType == EntityService.activeEntity.type;
    }

    public static isError(message: IInboxConversationMessageModel) {
        return message.transmittedStatus == ComTransmittedStatus.ERROR ||
            message.transmittedStatus == ComTransmittedStatus.ERROR_RETRY ||
            message.transmittedStatus == ComTransmittedStatus.HELD;
    }

    public static getSiteChannel(conversation: IInboxConversationModel, message: IInboxConversationMessageModel) {
        let siteChannelId = message.receiveSiteChannelId != null ? message.receiveSiteChannelId : message.senderSiteChannelId;
        return conversation.siteChannels.find(value => value.id == siteChannelId);
    }

    /**
     * virtual
     */

    public static virtualGenerate(text: string, entity?: IEntityModel, files?: IFileModel[], previews?: IPreviewModel[]): IInboxConversationMessageModel[] {
        let message: IInboxConversationMessageModel = {
            id: "virtualId",
            conversationId: "virtualId",
            senderContactChannelId: "xx",
            senderSiteChannelId: "xx",
            receiveSiteChannelId: null,
            text: text,
            senderId: entity == undefined ? "virtualId" : entity.id,
            senderType: entity == undefined ? EntityType.ACCOUNT : entity.type,
            type: InboxConversationMessageType.NORMAL,
            time: Date.now(),
            transmittedTime: 0,
            deletedTime: 0,
            comType: ComType.DIRECT_MESSAGE,
            metaData: {},
            attachments: InboxConversationMessageAttachmentService.virtualGenerate(files, previews),
            replyToId: "replyId",
            reply: "reply",
            state: ComState.NONE,
            transmittedStatus: 0
        };


        return [observable(message)];
    }

    public static virtualGenerateDto(comType: ComType, text?: string): IInboxConversationMessageOutgoing {
        return {
            type: InboxConversationMessageType.NORMAL,
            text: text,
            scheduledTime: 0,
            filesIds: [],
            previewsUrls: [],
            comType: comType,
            comState: ComState.NONE,
            metaData: {},
            sendType: ComSendType.SEND
        }
    }

    /**
     * store
     */
    public static storeAll(messages: IInboxConversationMessageModel[]): IInboxConversationMessageModel[] {
        for (let key in messages)
            messages[key] = this.store(messages[key]);

        return Services.storeAll(messages);
    }

    public static store(message: IInboxConversationMessageModel): IInboxConversationMessageModel {
        message.attachments = InboxConversationMessageAttachmentService.storeAll(message.attachments);
        message = Services.store("id", this.messages, message);


        return message;
    }
}