import {SedestralPageRouter} from "./pages/SedestralPageRouter";
import {SedestralStaticRouter} from "./static/SedestralStaticRouter";
import {ProductType} from "../../models/product/ProductType";
import {Network} from "../../network/Network";
import {EntityService} from "../../services/entity/EntityService";
import {Component} from "../sedestral-interface-component/interface/component/Component";
import {SedestralInterface} from "../sedestral-interface-component/interface/SedestralInterface";
import {SedestralSsrPage} from "./pages/ssr/SedestralSsrPage";
import {ISedestralRouterConfig} from "./types/ISedestralRouterConfig";
import {SedestralStorage} from "../sedestral-interface-component/memory/SedestralStorage";
import {Resources} from "../../resources/Resources";

export abstract class SedestralRouter {
    public pages: SedestralPageRouter;
    public static: SedestralStaticRouter;

    public config: ISedestralRouterConfig
    public languages: string[];

    public pageLoaderTimeout: any;

    constructor(languages?: string[]) {
        this.languages = languages;
    }

    /**
     * init
     */

    public init(config?: ISedestralRouterConfig): void {
        this.config = config;
    }

    public initStarted(): void {
        this.pages = new SedestralPageRouter(this, this.config?.languages, this.config?.defaultLanguage);
        this.static = new SedestralStaticRouter(this);

        this.pages.isOnline = () => Network.logged;
        this.pages.isNoneSite = () => EntityService.activeSite == null;

        this.registerStatic();
        this.registerPages();
        this.registerSsr();

        this.static.components.loader.displayHide();
        this.pages.init();

        this.onStarted();
    }

    public initNotStarted(errorComponent: Component) {
        this.pages = new SedestralPageRouter(this);
        this.static = new SedestralStaticRouter(this);

        this.registerStatic();
        this.static.components.loader.displayHide();
        SedestralInterface.body.render(errorComponent);
    }

    /**
     * register
     */

    public registerStatic(): void {

    }

    public registerPages(): void {

    }

    public registerSsr(): void {
        this.pages.registerPage("/sedestral.ssr", () => new SedestralSsrPage());
    }

    /**
     * import
     */

    async importPage(func: () => Promise<any>, product?: ProductType): Promise<any> {
        let key = "panelRouter" + product;//+ Date.now();
        if ((Date.now() - (3600 * 1000)) < (new Date(parseInt(SedestralStorage.getItem(key))).getTime())) {
            product = undefined;
        } else {
            SedestralStorage.setItem(key, "" + Date.now());
        }

        this.static.components.pageLoader.remove();
        clearTimeout(this.pageLoaderTimeout);

        this.static.components.pageLoader.product = product;
        this.static.components.pageLoader.init();

        let module = await func();
        if (product != undefined) {
            this.pageLoaderTimeout = setTimeout(() => {
                this.static.components.pageLoader.animateRemove();
            }, 2500);
        } else {
            this.static.components.pageLoader.remove();
        }

        return module;
    }

    /**
     * language
     */
    public language(defaultLanguage?: string): string {
        let firstPath = window.location.pathname.split("/").filter(value => value != "")[0];
        if (this.languages.includes(firstPath)) {
            return firstPath;
        }

        if (this.pages && this.pages.defaultLanguage) {
            return this.pages.defaultLanguage;
        }

        return defaultLanguage ?? Resources.getBestLanguageCandidate(this.languages) ?? Resources.getDefaultLanguage();
    }

    /**
     * override
     */

    onLogin() {
    }

    onLogout() {
    }

    onStarted() {

    }
}