/**
 * array
 */

import {objectEquals} from "./ObjectEquals";

/**
 * Vérifie si deux arrays sont équals
 */
export function arrayEquals(arr1: any[], arr2: any[]): boolean {
    if (arr1 == undefined || arr2 == undefined) {
        return false;
    }
    if (arr1.length != arr2.length) {
        return false;
    }
    if (arr1.length == 0 && arr2.length == 0) {
        return true;
    }
    arr1 = [...arr1];
    arr2 = [...arr2];

    arr1 = arr1.sort(function (a, b) {
        return a > b ? 1 : -1
    });
    arr2 = arr2.sort(function (a, b) {
        return a > b ? 1 : -1
    })
    for (let i = 0; i < arr1.length; i++) {
        if (typeof arr1[i] == "object") {
            if (!objectEquals(arr1[i], arr2[i])) {
                return false;
            }
        } else {
            if (arr1[i] != arr2[i]) {
                return false;
            }
        }
    }

    return true;
}