@use "../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentShare {

  .title {
    margin-bottom: 15px;
  }

  .networks {
    display: flex;
    gap: 10px;

    .entry {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background: var(--grey-sur);
      }

      &.linkedin {
        .icon {
          height: 24px;
          width: 24px;
          margin-top: -2px;

          @include icons.channels("linkedin-black");
        }
      }

      &.twitter {
        .icon {
          @include icons.channels("twitter-black");
        }
      }

      &.facebook {
        .icon {
          height: 22px;
          width: 22px;

          @include icons.channels("facebook-black");
        }
      }

      &.mail {
        .icon {
          @include icons.channels("mail-black");
        }
      }

      .icon {
        height: 30px;
        width: 30px;
      }
    }
  }
}