export class PageStatus {
    public constructor() {
        this._online = false;
        this._open = false;
    }

    private _online: boolean;

    get online(): boolean {
        return this._online;
    }

    set online(value: boolean) {
        this._online = value;
    }

    private _open: boolean;

    get open(): boolean {
        return this._open;
    }

    set open(value: boolean) {
        this._open = value;
    }
}