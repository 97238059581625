export enum OperatorType {
    IS = 1,
    IS_NOT = 2,
    CONTAINS_ALL = 12,
    CONTAINS = 3,
    NOT_CONTAINS = 4,
    EXIST = 5,
    NOT_EXIST = 6,
    GREATER = 7,
    AFTER = 10,
    LESS = 8,
    BEFORE = 9,
    STARTS_WITH = 16,
    ENDS_WITH = 17,
    ORDER_DESC = 14, //A FAIRE en local
    ORDER_ASC = 15,//A FAIRE en local
    INTERVAL = 11
}