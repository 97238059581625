export function dateSecondsToIso8601(durationInSeconds: number) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    let iso8601Duration = "PT";
    if (hours > 0) iso8601Duration += `${hours}H`;
    if (minutes > 0 || (hours > 0 && seconds > 0)) iso8601Duration += `${minutes}M`;
    if (seconds > 0 && hours === 0) iso8601Duration += `${seconds}S`;

    return iso8601Duration;
}
