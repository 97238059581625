import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IContactChannelModel} from "../../../models/contact/channel/IContactChannelModel";
import {SiteChannelService} from "../../site/channel/SiteChannelService";

export class ContactChannelService {
    public static channels: IContactChannelModel[] = observable([]);

    public static dispose(): void {
        this.channels = observable([]);
    }

    public static init(): void {

    }

    /**
     * store
     */

    public static storeAll(channels: IContactChannelModel[]): IContactChannelModel[] {
        for (let key in channels)
            channels[key] = this.store(channels[key]);

        return Services.storeAll(channels);
    }

    public static store(channel: IContactChannelModel): IContactChannelModel {
        if (channel.siteChannels) {
            channel.siteChannels = SiteChannelService.storeAll(channel.siteChannels);
        }
        channel = Services.store("id", this.channels, channel);
        return channel;
    }
}