import {BlogPageComponent} from "../BlogPageComponent";
import {BlogArticlesComponent} from "../../blog/articles/BlogArticlesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {CommunityService} from "../../../../../../../services/community/CommunityService";
import {ICommunityFileModel} from "../../../../../../../models/community/files/ICommunityFileModel";
import * as s from "../blog-page.scss";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {Network} from "../../../../../../../network/Network";
import {ICommunityCategoryModel} from "../../../../../../../models/community/category/ICommunityCategoryModel";
import {
    HexFilterConverter
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/colors/types/HexFilterConverter";
import {
    filterXSS
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {ICommunityLiveModel} from "../../../../../../../models/community/ICommunityLiveModel";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class BlogPageCategoryComponent extends BlogPageComponent {

    public navigationContentContainer: Component;
    public categoriesComponents: { category: ICommunityCategoryModel, component: Component }[] = [];
    public categoriesContainer: Component;

    public articlesComponent: BlogArticlesComponent;

    public navigationCategoryIndex: number;

    constructor(live?: ICommunityLiveModel) {
        super(live);
    }


    commit() {
        super.commit();
        Network.router.pages.onPopState(this, () => this.renderBodyContent());
    }

    /**
     * render
     */

    renderNavigation() {
        super.renderNavigation();
        //language=HTML
        this.navigationContentContainer = this.navigationContainer.append(`
            <nav aria-label="${Resources.t("words.menu")}" class="${s.content}">
                <ul class="${s.list}" itemscope itemtype="https://schema.org/ItemList"></ul>
            </nav>`);
        this.navigationContentContainer.pendAfter(this.navigationBrandContainer);
        this.categoriesContainer = this.navigationContentContainer.el(s.list);

        this.renderNavigationCategories();
    }

    renderBody() {
        super.renderBody();
    }

    renderNavigationCategories() {
        this.navigationCategoryIndex = 1;
        this.renderNavigationCategory({
            title: Resources.t("words.lastArticles"),
            route: `/${Resources.language}/home`
        });

        this.live.categories.forEach(category => this.renderNavigationCategory(category));
    }

    renderNavigationCategory(category: {
        title: string,
        route?: string,
        imageFile?: ICommunityFileModel
    }) {
        let path = CommunityService.linkCategory(category as any);

        //language=HTML
        let li = this.categoriesContainer.append(`
            <li class="${category.route == this.getBasePath() ? s.active : ``}" itemprop="itemListElement" itemscope
                itemtype="https://schema.org/ListItem">
                <a href="${path}" itemprop="item">
                    <div class="${s.category}">
                        <div class="${s.icon}"></div>
                        <div class="${s.name}" itemprop="name">
                            ${filterXSS(category.title)}
                        </div>
                    </div>
                </a>
                <meta itemprop="position" content="${this.navigationCategoryIndex}">
            </li>
        `);
        this.categoriesComponents.push({category: category as any, component: li});

        let categoryComponent = li.el(s.category);
        let icon = li.el(s.icon);
        icon.clearAll();
        if (category.imageFile) {
            icon.append(`<img alt="${Resources.t("words.category")} ${category.title}" src="${category.imageFile.link}"/>`);
        }

        let updateTheme = () => {
            if (li.hasClass(s.active)) {
                categoryComponent.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor};border-color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor}`);
                if (category.imageFile) {
                    icon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor));
                }
            } else {
                categoryComponent.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor};`);
                if (category.imageFile) {
                    icon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor));
                }
            }
        }
        this.onReactiveObserve(() => updateTheme());
        li.onChange(() => updateTheme());

        this.navigationCategoryIndex++;
    }

    async renderBodyContent(): Promise<void> {
        this.updateNavigationCategories();

        if (!this.articlesComponent) {
            this.articlesComponent = new BlogArticlesComponent(this.live);
            this.bodyContainer.render(this.articlesComponent);
        }


        this.updateMetas();
        await this.articlesComponent.renderAll(this.getBaseCategory());
    }

    visualizer() {
        this.renderBodyContent();
    }

    /**
     * update
     */

    updateMetas() {
        let pageCount = this.articlesComponent?.pagingComponent.getPageParam();
        let pageMetaData = (pageCount ? ` ${Resources.t("words.page")} ${pageCount}` : ``);

        let category = this.getBaseCategory();
        let title = (category ? category.title : this.live.community.text.metaTitle) + (pageMetaData ? ` -${pageMetaData}` : '');
        let description = category ? category.description : this.live.community.text.metaDescription + pageMetaData;
        let alternateLang = category ? new Map(Object.entries(category.alternateLanguages)) : undefined;
        Network.router.pages.updateMetasCommunity(this.live.community, false, title, description, undefined, category?.imageFile, alternateLang);
    }

    updateNavigationCategories() {
        let path = this.getBasePath();
        this.categoriesComponents.forEach(value => {
            if (value.category.route == path) {
                value.component.addClass(s.active);
            } else {
                value.component.removeClass(s.active);
            }
        });
    }
}