import {VisualFloatingComponent} from "../floating/VisualFloatingComponent";
import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./tooltip.scss";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";
import {SedestralInterface} from "../../sedestral-interface-component/interface/SedestralInterface";
import {isMobile} from "../../sedestral-interface-component/utilities/IsMobile";

export class VisualHoverTooltipWhiteComponent extends VisualFloatingComponent {
    public _animation: boolean;
    public _delay: number;
    public _mobileStyle: boolean;
    public _disableLeave: boolean;
    public _autoMaxHeight: boolean;
    public _keyId: string;

    constructor(component: Component, minHeight: number, width: number) {
        super(component);
        this._hovered = false;
        this._minHeight = minHeight;
        this._width = width;
        this._animation = true;
        this._keyId = "tooltip-" + this.constructor.name;
    }

    public _hovered: boolean;

    get hovered(): boolean {
        return this._hovered;
    }

    public _minHeight: number;

    get minHeight(): number {
        return this._minHeight;
    }

    set minHeight(value: number) {
        this._minHeight = value;
    }

    public _width: number;

    get width(): number {
        return this._width;
    }

    set width(value: number) {
        this._width = value;
    }

    public _hoverDelay: number;

    get hoverDelay(): number {
        return this._hoverDelay;
    }

    set hoverDelay(value: number) {
        this._hoverDelay = value;
    }

    public _leaveDelay: number;

    get leaveDelay(): number {
        return this._leaveDelay;
    }

    set leaveDelay(value: number) {
        this._leaveDelay = value;
    }

    /**
     * get set
     */

    get isAnimation(): boolean {
        return this._animation;
    }

    get animationDelay(): number {
        return this._delay;
    }

    set animationDelay(value: number) {
        this._delay = value;
    }

    commit() {
        if (this._autoMaxHeight) {
            this.calcMaxHeight();
            SedestralMachine.requestFrame()(() => {
                this.calcMaxHeight();
                this.onChange(() => {
                    this.calcMaxHeight();
                });
            });
        }

        this.bind();
        SedestralMachine.requestFrame()(() => {
            this.place();
        });
        super.commit();
    }

    /**
     * bind
     */

    bind() {
        if (!isMobile() && !this._disableLeave) {
            this.onLeave(async () => {
                await this.dispose();
            });
            let listener = this.component.onLeave(async () => {
                if (!this._hovered) {
                    await this.dispose();
                    this.component.deleteListener(listener);
                }
            }, this._leaveDelay == undefined ? 200 : this._leaveDelay);

            this.onHover(() => {
                if (!this._hovered) {
                    this._hovered = true;
                    this.component.deleteListener(listener);
                }
            }, this._hoverDelay == undefined ? 0 : this._hoverDelay);
        } else {
            this.onOutsideClick(() => this.dispose());
        }
    }

    /**
     * init and dispose
     */

    async dispose() {
        this.beforeDispose();
        if (this._animation) {
            this._mobileStyle ? await this.closeDown(this._delay) : await this.scaleHide(this._delay);
        } else {
            this.displayHide();
            this.clearAll();
        }

        if (this._disableLeave) {
            this.component[this._keyId] = undefined;
        }

        super.dispose();
    }

    create(): void {
        if (this._disableLeave) {
            if (this.component[this._keyId]) {
                SedestralInterface.unStore(this);
                this.clearAll();
                return;
            }

            this.component[this._keyId] = true;
        }

        super.create();
        if (this._animation) {
            this._mobileStyle ? this.openUp(this._delay) : this.scaleShow(this._delay);
        } else {
            this.displayShow();
        }
    }

    place() {
        if (this._mobileStyle && isMobile()) {
            let paddingLeft = window.getComputedStyle(this.getHTMLElement(), null).getPropertyValue('padding-left');
            let paddingRight = window.getComputedStyle(this.getHTMLElement(), null).getPropertyValue('padding-right');
            this.setStyle(`width:calc(100% - ${parseInt(paddingLeft) + parseInt(paddingRight)}px);left:unset !important;right:0 !important;top:unset !important;bottom:0;border-radius: 15px 15px 0px 0px;`);

            this.getHTMLElement().lastElementChild['style'].cssText += "padding-bottom: 20px !important;";
        } else {
            super.place();
        }


    }

    /**
     * template
     */

    getTemplate(): string {
        if (this._width !== 0) {
            return this.template = `
                <div style="min-height: ${this._minHeight}px;width:${this._width}px;" class="${s.visualTooltip}">
                     ${super.getTemplate()}
                </div>
            `;
        } else {
            return this.template = `
                <div style="min-height: ${this._minHeight}px;width: calc(100% - 60px); left: 15px !important; top: 15px !important" class="${s.visualTooltip}">
                     ${super.getTemplate()}
                </div>
            `;
        }
    }

    /**
     * settings
     */

    animation(value: boolean): VisualHoverTooltipWhiteComponent {
        this._animation = value;
        return this;
    }

    mobileStyle(value: boolean): VisualHoverTooltipWhiteComponent {
        this._mobileStyle = value;
        return this;
    }

    autoMaxHeight(value: boolean): VisualHoverTooltipWhiteComponent {
        this._autoMaxHeight = value;
        return this;
    }

    /**
     * height
     */

    calcMaxHeight() {
        if (!this.component.isNull() && !this.isNull()) {
            let firstElement = this.getFirstChildElement();

            if (firstElement != undefined) {
                let maxHeight;
                if (this.component.getOffsets().top > (window.innerHeight / 2)) {
                    let bottom = this.component.getHeight() + this.component.getOffsets().bottom + 30;
                    maxHeight = window.innerHeight - bottom;
                } else {
                    let top = this.component.getHeight() + this.component.getOffsets().top + 30;
                    maxHeight = window.innerHeight - top;
                }

                let child = firstElement as HTMLElement;
                for (let i = 0; i < child.children.length; i++)
                    child.children[i]['_scrollTop'] = child.children[i].scrollTop;

                child.style.cssText += `height:fit-content !important;`;
                let childHeight = child.scrollHeight;

                if ((childHeight > (maxHeight - 10))) {
                    child.style.cssText += `height:${maxHeight - (isMobile() ? 40 : 0)}px !important;`;
                    this.setStyle(`height:${maxHeight}px;transition:0s;`);
                } else {
                    child.style.cssText += `height:${childHeight + 1}px !important;`;
                    this.setStyle(`height:fit-content;transition:0s;`);
                }

                for (let i = 0; i < child.children.length; i++)
                    child.children[i]['scrollTop'] = child.children[i]['_scrollTop'];


                this.place();
            }

        }
    }

    getFirstChildElement() {
        let firstElement = this.getHTMLElement().firstElementChild;
        if (firstElement && firstElement.hasAttribute("swapBar")) {
            return firstElement.nextElementSibling;
        }

        return firstElement;
    }
}