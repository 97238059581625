import {dateToCompare} from "./DateToCompare";

/**
 * Compte le nombre de jours entre deux dates
 */
export function dateCountDaysBetweenDates(date: Date, futureDate: Date, noCountToday?: boolean): number {
    date = dateToCompare(new Date(date));
    futureDate = new Date(futureDate);

    let days = [];
    while (date.getTime() < futureDate.getTime()) {
        let key = "" + date.getFullYear() + date.getMonth() + date.getDate();
        if (!days.includes(key)) {
            days.push(key);
        }

        date.setDate(date.getDate() + 1);
    }

    return days.length - (noCountToday != undefined && noCountToday ? 1 : 0);
}