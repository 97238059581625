@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.tooltip {
  z-index: 9999999999;
  position: fixed;
  pointer-events: none;
  font-size: 11px;
  background: var(--white);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 0 20px 0 rgb(0 0 0 / 20%);
  border-radius: 10px;
  opacity: 0;

  @include texts.font("semi-bold");


  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      box-shadow: 0 4px 14px 0 rgb(0 0 0 / 20%), 0 0 0 1px rgb(0 0 0 / 5%);
      background-color: var(--white);
    }
  }

  .content {
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: var(--white);
    position: relative;
    color: #222;
    z-index: 2;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}