import "../../../sedestral-interface-modules/sedestral-interface-sass/sedestral.scss";
import "../../../sedestral-interface-modules/sedestral-interface-component-global/loader/background/loader-background.scss";
import {Resources} from "../../../resources/Resources";
import {Network} from "../../../network/Network";
import {BlogRouter} from "./components/BlogRouter";
import "./Blog.scss";
import {ProductsLanguages} from "../../ProductsLanguages";

export class Blog {
    public static router: BlogRouter;

    public static async init(): Promise<void> {
        this.router = new BlogRouter(ProductsLanguages.blog);

        let started = await Network.start(this.router);
        await Resources.init([this.router.language()], this.router.languages);

        try {
            await Promise.all([
                await Resources.loadI18n("blog"),
            ]);
        } catch (e) {
        }

        await this.router.init({started: started, defaultLanguage: Resources.language, languages: Resources.languages});
    }
}