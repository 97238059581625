import {Resources} from "../../../../../../../resources/Resources";
import {AppearanceTheme} from "../../../../../../../models/enums/AppearanceTheme";
import {ICommunityModel} from "../../../../../../../models/community/ICommunityModel";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class CommunityTheme {
    static change(community: ICommunityModel, theme: AppearanceTheme, productType: ProductType, silent?: boolean) {
        Resources.setTheme(productType, theme, silent);
        let invertTheme = Resources.invertTheme(productType);

        Object.keys(community.appearances[invertTheme])
            .forEach(value => {
                let saved = community.appearances[invertTheme][value];
                community.appearances[invertTheme][value] = undefined;
                community.appearances[invertTheme][value] = saved;
            });
    }
}