import {Component} from "./component/Component";
import {SedestralMachine} from "../machine/SedestralMachine";

export class SedestralInterface {

    public static log: boolean = false;
    public static components: Component[];

    public static html: Component;
    public static body: Component;
    public static main: Component;

    public static async init(): Promise<void> {
        this.components = [];
        if (this.log) {
            SedestralMachine.setInterval(() => {
                let nullComponents = [];
                for (let component of this.components) {
                    if (component.element == undefined) {
                        // if (component.constructor.name != "PageComponent")
                        nullComponents.push(component);
                    }
                }
                console.log(nullComponents)
                console.log(this.components)
                console.log("comp: " + (this.components.length - nullComponents.length));
                console.log("comp n: " + nullComponents.length);
            }, 3000);
        }

        this.html = this.ec("html");
        this.body = this.ec("body");
        this.main = this.ec("main");

        let listeners = ['dragenter', 'dragover', 'dragleave', 'drop'];
        listeners.forEach(eventName => this.body.putListener(window, eventName, (e) => e.preventDefault()));
    }

    public static dispose() {
        this.components.forEach(c => {
            c.nullify();
        });
        this.components = [];
        this.body = undefined;
        this.main = undefined;
    }

    public static store(element: Component): void {
        this.components.push(element);
    }

    public static unStore(element: Component): void {
        let index = this.components.indexOf(element);
        if (index >= 0) {
            this.components.splice(index, 1);
        }
    }

    public static ec(selector: string): Component {
        let e = document.querySelector(selector);
        if (e != undefined) {
            return new Component(e);
        }

        return undefined;
    }

    public static el(element: Node): Component {
        return new Component(element);
    }
}