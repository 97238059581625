import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {
    Component
} from "../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    ICommunitySEOKeywordsSearchOutgoing
} from "../../../models/community/seo/keywords/ICommunitySEOKeywordsSearchOutgoing";
import {ICommunitySEOKeywordsOutgoing} from "../../../models/community/seo/keywords/ICommunitySEOKeywordsOutgoing";
import {
    ICommunitySEOKeywordsRankingIncoming
} from "../../../models/community/seo/ranking/ICommunitySEOKeywordsRankingIncoming";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {
    ICommunitySEOKeywordsRankingHostIncoming
} from "../../../models/community/seo/ranking/ICommunitySEOKeywordsRankingHostIncoming";
import {Services} from "../../Services";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {Resources} from "../../../resources/Resources";

export class CommunityKeywordsService {

    public static dispose(): void {

    }

    public static init(): void {
    }

    public static async getCommunityKeywords(search: ICommunitySEOKeywordsSearchOutgoing, component: Component): Promise<ICommunitySEOKeywordsOutgoing> {
        Services.handleErrors(component, [
            {status: HttpStatus.BAD_REQUEST, message: "none"}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/keywords`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async searchKeywords(name: string, search: ICommunitySEOKeywordsSearchOutgoing, component?: Component): Promise<ICommunitySEOKeywordsOutgoing> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/keywords/search/${name}`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }


    public static async createRanking(host: string, search: ICommunitySEOKeywordsSearchOutgoing, component?: Component): Promise<ICommunitySEOKeywordsRankingIncoming> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/keywords/ranking/create/${host}`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getRankings(ids: string[], search: ICommunitySEOKeywordsSearchOutgoing, component?: Component): Promise<ICommunitySEOKeywordsRankingIncoming[]> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/keywords/rankings/${ids.join(",")}`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async deleteRanking(communityId: string, id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/keywords/ranking/${communityId}/${id}`, component);
        return request.status == HttpStatus.OK;
    }

    public static async getRankingHosts(communityId: string, component?: INetworkComponent): Promise<ICommunitySEOKeywordsRankingHostIncoming[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/keywords/ranking/hosts/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data
        }

        return undefined;
    }
}