import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {INoteModel} from "../../models/note/INoteModel";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {NoteType} from "../../models/note/NoteType";
import {EntityService} from "../entity/EntityService";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {ContactService} from "../contact/ContactService";
import {ProductType} from "../../models/product/ProductType";

export class NoteService {
    public static notes: INoteModel[] = observable([]);

    public static dispose(): void {
        this.notes = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.NOTE_NEW, (data) => {
            this.store(data);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.NOTE_DELETE, (data) => {
            this.unStore(data);
        });

    }

    /**
     * load
     */

    public static async create(contentId: string, type: NoteType, text: string, component?: INetworkComponent): Promise<INoteModel> {
        let request = await Network.post(ProductType.PANEL, "/notes", {
            contentId: contentId,
            type: type,
            text: text
        }, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(noteId: string, component?: INetworkComponent): Promise<void> {
        let request = await Network.delete(ProductType.PANEL, `/notes/${noteId}`, component);

        /*
        if (request.status == HttpStatus.OK)
            this.unStore(request.data);

         */
    }

    /**
     * store
     */
    public static storeAll(notes: INoteModel[]): INoteModel[] {
        for (let key in notes)
            notes[key] = this.store(notes[key]);

        return Services.storeAll(notes);
    }

    public static store(note: INoteModel): INoteModel {
        note.author = EntityService.store(note.author);
        note = Services.store("id", this.notes, note);

        this.entityUpdate(note, true);
        return note;
    }

    public static unStore(note: INoteModel): void {
        note = Services.unStore("id", this.notes, note);
        if (note != undefined) {
            this.entityUpdate(note, false);
        }
    }

    public static entityUpdate(note: INoteModel, isStore: boolean) {
        if (note.type == NoteType.CONTACT) {
            if (isStore) {
                ContactService.addNote(note.contentId, note);
            } else {
                ContactService.removeNote(note.contentId, note);
            }
        }
    }

}