import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-object-block.scss";

export class LoaderObjectBlockComponent extends Component {
    constructor(shine: boolean) {
        super();
        //language=HTML
        this.template = `
            <div loaderBlock class="${s.globalLoaderObjectBlock} ${shine ? s.shine : ``}">
            </div>
        `;
    }
}