/**
 * Vérifie de manière recursif si deux objets sont identique
 */
import {arrayEquals} from "./ArrayEquals";

export function objectEquals(obj1: any, obj2: any) {
    if (obj1 == undefined || obj2 == undefined) {
        return false;
    }

    for (let property in obj1) {
        if (obj1.hasOwnProperty(property)) {
            let value = obj1[property];
            let compareValue = obj2[property];

            if (typeof value == "object") {
                if (!objectEquals(value, compareValue)) {
                    return false;
                }
            } else if (Array.isArray(value)) {
                if (!arrayEquals(value, compareValue)) {
                    return false;
                }
            } else {
                if (typeof value === 'string') {
                    let valueNumber = Number(value);
                    if (!Number.isNaN(valueNumber)) {
                        value = valueNumber;
                    }
                }

                if (typeof compareValue === 'string') {
                    let valueNumber = Number(value);
                    if (!Number.isNaN(valueNumber)) {
                        compareValue = valueNumber;
                    }
                }

                if (value != compareValue) {
                    return false;
                }
            }
        }
    }

    return true;
}