/**
 * Vérifie si un nom est un visiteur
 */

export function txtNameIsVisitor(name: string) {
    if (!name) {
        return false;
    }

    const re = /^Visitor \d{4}$/;
    return re.test(String(name));
}