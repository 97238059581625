@use "../../../../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentBlogArticleOwner {
  display: flex;
  align-items: center;

  .names {
    .creatorName {
      font-size: 14px;
      cursor: pointer;
    }
  }
}