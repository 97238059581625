import {IOffsets} from "./types/IOffsets";

/**
 * Retounre les offsets d'un élément
 */
export function elementOffsets(element: Node): IOffsets {
    if (element instanceof HTMLElement) {
        let offsets = element.getBoundingClientRect();
        return {
            top: offsets.top,
            left: offsets.left,
            right: window.innerWidth - (offsets.left + element.clientWidth),
            bottom: window.innerHeight - (offsets.top + element.clientHeight)
        };
    }
}