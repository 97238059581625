import {observable} from "@nx-js/observer-util";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {Services} from "../../Services";
import {ProductType} from "../../../models/product/ProductType";
import {IAccountComposerModel} from "../../../models/account/composers/IAccountComposerModel";
import {ComposerType} from "../../../products/panel/components/static/composer/types/ComposerType";
import {IComposer} from "../../../products/panel/components/static/composer/types/IComposer";

export class AccountComposerService {

    public static composers: IAccountComposerModel[] = observable([]);

    public static dispose(): void {
        this.composers = observable([]);
    }

    public static init(): void {
    }

    /**
     * rest
     */
    public static async create(type: ComposerType, contentId: string, component?: INetworkComponent): Promise<IAccountComposerModel> {
        let request = await Network.post(ProductType.PANEL, "/account/composer",
            {
                type: type,
                contentId: contentId
            }, component);

        if (request.status == HttpStatus.OK) {
            return AccountComposerService.store(request.data);
        }

        return undefined;
    }

    public static async delete(id: string, component?: INetworkComponent): Promise<IAccountComposerModel> {
        let request = await Network.delete(ProductType.PANEL, `/account/composer/${id}`, component);

        if (request.status == HttpStatus.OK) {
            this.unStore(this.composers.find(value => value.id == id));
        }

        return undefined;
    }

    /**
     * tab
     */

    public static async createTab(composer: IComposer, component?: INetworkComponent) {
        let accountComposer = await this.create(composer.type, composer.contentId, component);
        Network.router.static.components.composer.renderTab(accountComposer, true);
    }

    /**
     * store
     */

    public static unStore(composer: IAccountComposerModel) {
        Services.unStore("id", this.composers, composer);
    }

    public static storeAll(composers: IAccountComposerModel[]): IAccountComposerModel[] {
        for (let key in composers) {
            composers[key] = this.store(composers[key]);
        }

        return Services.storeAll(composers);
    }

    public static store(composer: IAccountComposerModel): IAccountComposerModel {
        if (composer != undefined) {
            return Services.store("id", this.composers, composer);
        }
    }

}