@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentTheme {
  display: flex;
  width: 100%;
  border: unset;
  height: 100%;
  align-items: center;

  .icon {
    height: 22px;
    width: 22px;
    flex: 0 0 22px;
    margin-left: auto;
    margin-right: 30px;
    filter: var(--icon-black);
    position: absolute;
    right: 0;
    pointer-events: none;

    @include icons.ico("black-brightness");
  }
}