import 'regenerator-runtime/runtime';
import {SedestralLogic} from "./sedestral-interface-modules/sedestral-interface-component/SedestralLogic";
import {config} from "./config";
import {Network} from "./network/Network";
import {ProductName} from "./models/product/ProductName";
import {ProductType} from "./models/product/ProductType";
import {CommunityNetwork} from "./products/community/abstract/network/CommunityNetwork";
import {Blog} from "./products/community/blog/Blog";

config.import(ProductName.toString(ProductType.BLOG));
SedestralLogic.ready(async () => {
    await SedestralLogic.init();
    await CommunityNetwork.init();
    await Network.init(ProductName.toString(ProductType.BLOG));
    await Blog.init();
});