@use "../../../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "../../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "../../../../../../../sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/editor";

.componentBlogRead {
  width: 100%;
  padding: 25px 0;
  max-width: 696px;

  .loader {
    height: calc(100vh - 50px);
    overflow: hidden;
    position: relative;
  }

  article {
    font-size: 15px;


    header {
      margin-bottom: 25px;

      .title {
        visibility: visible
      }

      h1 {
        font-size: 40px;
        margin-bottom: 10px;
        margin-top: 0;

        @include texts.font("bold");
      }

      p {
        margin: 0;
      }
    }

    .content {
      word-break: break-word;

      @include editor.tags;
      @include editor.tagsDocs;
    }

    footer {
      margin-bottom: 50px;
    }

    .time, .update {
      color: var(--grey-text);
      font-size: 13px;
      margin-bottom: 15px;

      a {
        color: unset;
      }
    }

    .share {
      border: 1px solid var(--grey-sur);
      padding: 12px;
      border-radius: 12px;
      width: fit-content;
    }
  }

  @media screen and (max-width: 950px) {
    padding: 0;
    margin-top: -20px;
  }

  @media screen and (max-width: 480px) {
    article {
      header {
        h1 {
          font-size: 28px;
        }
      }
    }
  }
}