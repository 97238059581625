import {
    IInboxConversationCategoryModel
} from "../../../../models/inbox/conversation/category/IInboxConversationCategoryModel";
import {observable} from "@nx-js/observer-util";
import {Services} from "../../../Services";
import {CategoryService} from "../../../category/CategoryService";

export class InboxConversationCategoryService {
    public static categories: IInboxConversationCategoryModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.categories = observable([]);
    }

    public static storeAll(categories: IInboxConversationCategoryModel[]): IInboxConversationCategoryModel[] {
        for (let key in categories)
            categories[key] = this.store(categories[key]);

        return Services.storeAll(categories);
    }

    public static store(category: IInboxConversationCategoryModel): IInboxConversationCategoryModel {
        category.category = CategoryService.store(category.category);
        return Services.store("id", this.categories, category);
    }
}