import {FilterBuilderOperator} from "./FilterBuilderOperator";
import {FiltersOperatorIdentifiers} from "./FiltersOperatorIdentifiers";
import {OperatorType} from "../../../../enums/OperatorType";
import {ValueType} from "../../../../enums/ValueType";

export class FilterOperatorIdentifiersEntries {

    public static entries: FilterBuilderOperator[] = [
        {
            identifier: FiltersOperatorIdentifiers.CONTACT_NAME,
            operators: [OperatorType.IS, OperatorType.IS_NOT, OperatorType.CONTAINS, OperatorType.NOT_CONTAINS, OperatorType.STARTS_WITH, OperatorType.ENDS_WITH],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 2
        }, {
            identifier: FiltersOperatorIdentifiers.CONTACT_MAIL,
            operators: [OperatorType.IS, OperatorType.IS_NOT, OperatorType.CONTAINS, OperatorType.NOT_CONTAINS, OperatorType.STARTS_WITH, OperatorType.ENDS_WITH],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 2
        }, {
            identifier: FiltersOperatorIdentifiers.SITE_CHANNELS,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.CHANNELS,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3,
        }, {
            identifier: FiltersOperatorIdentifiers.ASSIGNED,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.BOOLEAN,
            valueType: ValueType.BOOLEAN,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.ASSIGNED_TO,
            operators: [OperatorType.CONTAINS, OperatorType.CONTAINS_ALL, OperatorType.NOT_CONTAINS],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.ASSIGNED_TO_HAS_UNREADS,
            operators: [OperatorType.CONTAINS, OperatorType.CONTAINS_ALL, OperatorType.NOT_CONTAINS],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.ASSIGNED_INVITED,
            operators: [OperatorType.CONTAINS, OperatorType.CONTAINS_ALL, OperatorType.NOT_CONTAINS],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.STATUS,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.STATE,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.CATEGORY, // OK
            operators: [OperatorType.CONTAINS, OperatorType.CONTAINS_ALL, OperatorType.NOT_CONTAINS],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.ARCHIVED,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.BOOLEAN,
            valueType: ValueType.BOOLEAN,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.TRANSMITTED_STATUS,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.BEFORE_UPDATED_TIME,
            operators: [OperatorType.LESS, OperatorType.GREATER],
            type: ValueType.DATE,
            valueType: ValueType.LONG,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.SITE_ID,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.TIME,
            operators: [OperatorType.INTERVAL, OperatorType.AFTER, OperatorType.BEFORE],
            type: ValueType.DATE,
            valueType: ValueType.LONG,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.UPDATED_TIME,
            operators: [OperatorType.INTERVAL, OperatorType.AFTER, OperatorType.BEFORE],
            type: ValueType.DATE,
            valueType: ValueType.LONG,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.RECEIVED_OFFLINE,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.BOOLEAN,
            valueType: ValueType.BOOLEAN,
            maxItems: 1,
        }, {
            identifier: FiltersOperatorIdentifiers.HAS_MESSAGE_DRAFT,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.ACCOUNT_OWNER,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.MESSAGE_MAIL_SERVER_ID,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.STRING,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.MESSAGE_TYPE,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.SENDER_TYPE,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.NEWSLETTER,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.ORIGIN,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.LIST,
            valueType: ValueType.INTEGER,
            maxItems: 3
        }, {
            identifier: FiltersOperatorIdentifiers.BANNED,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.BOOLEAN,
            valueType: ValueType.BOOLEAN,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.BANNED,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.BOOLEAN,
            valueType: ValueType.BOOLEAN,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.HAS_CONVERSATIONS,
            operators: [OperatorType.IS, OperatorType.IS_NOT],
            type: ValueType.BOOLEAN,
            valueType: ValueType.BOOLEAN,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.SORT_COLUMN_NAME,
            operators: [OperatorType.ORDER_ASC, OperatorType.ORDER_DESC],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 1
        }, {
            identifier: FiltersOperatorIdentifiers.SORT_JSON_COLUMN_NAME,
            operators: [OperatorType.ORDER_ASC, OperatorType.ORDER_DESC],
            type: ValueType.STRING,
            valueType: ValueType.STRING,
            maxItems: 1
        }
    ];

    public static get(identifier: string): FilterBuilderOperator {
        return this.entries.filter(entry => entry.identifier == identifier)[0];
    }

}