import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-light.scss";

export class LoaderLightComponent extends Component {

    constructor(p?: { width?: number, strokeWidth?: number, strokeColor?: string }) {
        super();

        this.template = `
            <div loaderLight class="${s.globalLoaderLight}">
                <div class="${s.lightContainer}">
                    <svg style="${p && p.width ? `width:${p.width}px;height:${p.width}px;` : ``}" viewBox="0 0 50 50">
                        <circle style="${p && p.strokeWidth ? `stroke-width:${p.strokeWidth}px;` : ``}${p && p.strokeColor ? `stroke:${p.strokeColor};` : ``}" class="${s.path}" cx="25" cy="25" r="20" fill="none" stroke-width="2"/>
                    </svg>
                </div>
            </div>
        `;
    }
}