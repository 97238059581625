export enum FiltersOperatorIdentifiers {

    CONTACT_NAME = "CONTACT_NAME",
    CONTACT_MAIL = "CONTACT_MAIL",

    MESSAGE_MAIL_SERVER_ID = "MESSAGE_MAIL_SERVER_ID",
    MESSAGE_TYPE = "MESSAGE_TYPE",

    HAS_CONVERSATIONS = "HAS_CONVERSATIONS",
    NEWSLETTER = "NEWSLETTER",
    ORIGIN = "ORIGIN",
    BANNED = "BANNED",
    SITE_CHANNELS = "SITE_CHANNELS",
    CHANNELS = "CHANNELS",
    ASSIGNED = "ASSIGNED",
    ASSIGNED_TO = "ASSIGNED_TO",
    ASSIGNED_TO_HAS_UNREADS = "ASSIGNED_TO_HAS_UNREADS",
    ASSIGNED_INVITED = "ASSIGNED_INVITED",
    CONTACT_ID = "CONTACT_ID",
    STATUS = "STATUS",
    CATEGORY = "CATEGORY",
    ARCHIVED = "ARCHIVED",
    BEFORE_UPDATED_TIME = "BEFORE_UPDATED_TIME", // server only
    SITE_ID = "SITE_ID", // server only
    TIME = "TIME",
    UPDATED_TIME = "UPDATED_TIME",
    RECEIVED_OFFLINE = "RECEIVED_OFFLINE",
    STATE = "STATE",
    ACCOUNT_OWNER = "ACCOUNT_OWNER",
    HAS_MESSAGE_DRAFT = "HAS_MESSAGE_DRAFT",
    SORT_COLUMN_NAME = "SORT_COLUMN_NAME",
    SORT_JSON_COLUMN_NAME = "SORT_JSON_COLUMN_NAME",

    SENDER_TYPE = "SENDER_TYPE",
    TRANSMITTED_STATUS = "TRANSMITTED_STATUS",
}