import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IContactSessionModel} from "../../../models/contact/session/IContactSessionModel";

export class ContactSessionService {
    public static sessions: IContactSessionModel[] = observable([]);

    public static dispose(): void {
        this.sessions = observable([]);
    }

    public static init(): void {

    }

    /**
     * store
     */

    public static storeAll(sessions: IContactSessionModel[]): IContactSessionModel[] {
        for (let key in sessions)
            sessions[key] = this.store(sessions[key]);

        return Services.storeAll(sessions);
    }

    public static store(session: IContactSessionModel): IContactSessionModel {
        if (session != undefined) {
            session = Services.store("id", this.sessions, session);
            return session;
        }
    }

}