@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.globalLoaderObjectList {
  width: 100%;
  overflow: hidden;

  &.shine {
    @include animations.shine;
  }

  .object {
    width: 100%;
    border-radius: 14px;
    margin-top: 10px;
    position: relative;
    background: var(--grey-sur);
  }
}