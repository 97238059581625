import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-object-blog.scss";
export class LoaderObjectBlogComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentLoaderObjectBlog} ${s.shine}">
                <div class="${s.cases}">
                    <div class="${s.case}"></div>
                    <div class="${s.case}"></div>
                    <div class="${s.case}"></div>
                    <div class="${s.case}"></div>
                </div>
            </div>
        `;
    }
}