/**
 * Vérifie si un text est un nombre
 */
export function txtIsNumber(str: string) {
    if (!str)
        return false;

    if (typeof str != "string") {
        return true
    }
    // @ts-ignore
    return !isNaN(str) &&
        !isNaN(parseFloat(str))
}