import {ISiteColumnsColumnModel} from "../../../models/site/columns/ISiteColumnsColumnModel";
import {config} from "../../../config";
import {Resources} from "../../../resources/Resources";
import {ISiteColumnsLabelModel} from "../../../models/site/columns/ISiteColumnsLabelModel";
import {ValueType} from "../../../models/enums/ValueType";
import {ISiteColumnsModel} from "../../../models/site/columns/ISiteColumnsModel";
import {
    txtCapitalize
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtCapitalize";
import {
    txtNameIsVisitor
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtNameIsVisitor";
import {txtCamelCase} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtCamelCase";
import {txtIsNumber} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtIsNumber";
import {
    txtIsValidMail
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtIsValidMail";

export abstract class SiteColumnsService {
    public static value(object: any, column: ISiteColumnsColumnModel): any {
        let r;
        if (column.rowColumn) {
            r = object[txtCamelCase(column.id)];
        } else {
            r = object.columnsData ? object.columnsData[column.id] : undefined;
        }

        if (column.id == "name" && config.product == "livechat" && txtNameIsVisitor(r)) {
            return undefined;
        }

        return r;
    }

    public static label(column: ISiteColumnsColumnModel): string {
        if (!column.labels || column.labels.length == 0) {
            return Resources.t("words." + column.id);
        }
        let label = this.findLabel(column.labels);

        return label ? label.name : column.id;
    }

    public static type(column: ISiteColumnsColumnModel): string {
        let value = ValueType[column.type];
        if (column.type == ValueType.LIST) {
            return `List of ${ValueType[column.valueType].toLowerCase()}`;
        }

        return txtCapitalize(value.toLowerCase());
    }

    public static inputType(value: ValueType): string {
        switch (value) {
            case ValueType.MAIL:
                return "email";
            case ValueType.INTEGER:
            case ValueType.LONG:
                return "number";
            case ValueType.PHONE:
                return "tel";
            default:
                return "text";
        }
    }

    public static filledFields(siteColumns: ISiteColumnsModel, object: any, columnsIds: string[]) {
        let columns = siteColumns.columns.filter(c => columnsIds.includes(c.id));
        return columns.find(column => {
            let value = this.value(object, column);
            return value != 0 && (value == undefined || value == "");
        }) == undefined;
    }

    /**
     * verifications
     */

    public static verifyColumnData(value: any, column: ISiteColumnsColumnModel): boolean {
        if (!column.required && (value == undefined || value.length == 0)) {
            return true;
        }

        if (value != undefined) {
            switch (column.valueType) {
                case ValueType.MAIL:
                    return txtIsValidMail(value);
                case ValueType.INTEGER:
                    if (value.length > column.maxLength) {
                        return false;
                    }

                    return txtIsNumber(value);

                default:
                    return value.length <= column.maxLength;

            }
        }


        return false;
    }

    public static sort(columns: ISiteColumnsModel): ISiteColumnsModel {
        return {columns: columns.columns.sort((a, b) => (a.order < b.order) ? 1 : -1).reverse()};
    }

    /**
     * store
     */


    public static storeAbstract(standard: ISiteColumnsModel, columns: ISiteColumnsModel): ISiteColumnsModel {
        standard.columns.forEach(standardColumn => {

            let findColumn = columns.columns.find(v => v.id == standardColumn.id);
            if (!findColumn) {
                columns.columns.push(standardColumn);
            } else {
                findColumn.possibleValues = standardColumn.possibleValues;
                findColumn.labels = standardColumn.labels;
            }
        });

        return columns;
    }

    private static findLabel(labels: ISiteColumnsLabelModel[]): ISiteColumnsLabelModel {
        return labels.find(label => label.language == Resources.language) || labels[0];
    }
}