import {ThemeComponent} from "../../../../../panel/components/components/theme/ThemeComponent";
import {AppearanceTheme} from "../../../../../../models/enums/AppearanceTheme";
import {
    ISelectBoxEntry
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/types/ISelectBoxEntry";
import {Resources} from "../../../../../../resources/Resources";
import {ICommunityLiveModel} from "../../../../../../models/community/ICommunityLiveModel";
import {
    HexFilterConverter
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/colors/types/HexFilterConverter";
import {CommunityTheme} from "./types/CommunityTheme";
import {ProductType} from "../../../../../../models/product/ProductType";

export class CommunityThemeComponent extends ThemeComponent {

    public live: ICommunityLiveModel;

    constructor(live: ICommunityLiveModel, selectedValue: AppearanceTheme) {
        super(selectedValue);
        this.live = live;
    }

    commit() {
        super.commit();

        this.onReactiveObserve(() => {
            this.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor};`);
            this.iconComponent.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor));
            this.selectIcon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor));
        });
    }

    onSelect(entry: ISelectBoxEntry) {
        CommunityTheme.change(this.live.community, entry.value as any, ProductType.BLOG);
    }

}