import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {VisualHoverTooltipWhiteComponent} from "./VisualHoverTooltipWhiteComponent";
import {SedestralInterface} from "../../sedestral-interface-component/interface/SedestralInterface";
import * as s from "./tooltip.scss";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";

export class VisualClickTooltipWhiteComponent extends VisualHoverTooltipWhiteComponent {
    private _screen: boolean;
    private _unClickable: boolean;

    constructor(component: Component, minHeight: number, width: number) {
        super(component, minHeight, width);
        this._screen = true;
        this._unClickable = true;
        this._isReady = false;
    }

    private _screenContainer: Component;

    /**
     * get and set
     */
    get screenContainer(): Component {
        return this._screenContainer;
    }

    private _isReady: boolean;

    get isReady(): boolean {
        return this._isReady;
    }

    bind() {
        this.addListener("contextmenu", (e) => e.stopPropagation());
        this.onOutsideClick(async () => await this.dispose());
        this.onOutsideRightClick(async () => await this.dispose());

        this.component.onRemove(async () => {
            this.disposeScreen();
            await this.dispose();
        })
    }

    async create(hidden?: boolean): Promise<void> {
        if (this.component[this._keyId]) {
            SedestralInterface.unStore(this);
            this.clearAll();
            return;
        }

        this.component[this._keyId] = true;
        SedestralInterface.main.render(this);

        if (this._screen) {
            this._screenContainer = SedestralInterface.body.append(`<div class="${s.visualScreen}"></div>`);
            SedestralInterface.body.removeChildren(this._screenContainer);
        }

        if (this._unClickable) {
            this.unClickable();
        }

        if (!hidden) {
            if (this.isAnimation) {
                this._mobileStyle ? await this.openUp(this.animationDelay) : await this.scaleShow(this.animationDelay);
            } else {
                this.displayShow();
            }
        } else {
            this.displayHide();
        }

        SedestralMachine.requestFrame()(() => {
            this._isReady = true;
        });
    }

    async dispose(): Promise<void> {
        this.disposeScreen();
        await super.dispose();
        this._isReady = false;
        this.component[this._keyId] = undefined;
    }

    disposeScreen() {
        if (this._screen && this._screenContainer != undefined) {
            this._screenContainer.remove();
            this._screenContainer = undefined;
        }
    }

    /**
     * settings
     */

    screen(value: boolean): VisualClickTooltipWhiteComponent {
        this._screen = value;
        return this;
    }

    setUnClickable(value: boolean): VisualClickTooltipWhiteComponent {
        this._unClickable = value;
        return this;
    }

    setComponentUnClickable(classValue) {
        if (!this.isNull()) {
            this.onClick((e) => {
                SedestralMachine.outsideClickListeners.forEach(value => {
                    if (!(value['component'] instanceof classValue)) {
                        value.callback(e);
                    }
                })
            }, true);
        }
    }

}