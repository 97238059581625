import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {ICommunityTextModel} from "../../../models/community/text/ICommunityTextModel";
import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";

export class CommunityTextsService {
    public static texts: ICommunityTextModel[] = observable([]);

    public static dispose(): void {
    }

    public static init(): void {
    }


    /**
     * store
     */

    public static async update(id: string, text: ICommunityTextModel): Promise<ICommunityTextModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, "/community/text/update/" + id, text);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }


    public static storeAll(texts: ICommunityTextModel[]): ICommunityTextModel[] {
        for (let key in texts) {
            texts[key] = this.store(texts[key]);
        }
        return Services.storeAll(texts);
    }

    public static store(text: ICommunityTextModel): ICommunityTextModel {
        return Services.store("id", this.texts, text);
    }
}