import {
    VisualSelectBoxComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/VisualSelectBoxComponent";
import * as s from "./theme.scss";
import {AppearanceTheme} from "../../../../../models/enums/AppearanceTheme";
import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {Resources} from "../../../../../resources/Resources";

export class ThemeComponent extends VisualSelectBoxComponent {

    public iconComponent: Component;

    constructor(selectedValue: AppearanceTheme) {
        super({
            entries: [
                {label: Resources.t("words.themeLight"), value: AppearanceTheme.LIGHT},
                {label: Resources.t("words.themeDark"), value: AppearanceTheme.DARK},
                {label: Resources.t("words.themeAuto"), value: AppearanceTheme.AUTO}
            ],
            selectedValue: selectedValue
        });
    }

    commit() {
        this.addClass(s.componentTheme);
        this.iconComponent = this.append(`<div class="${s.icon}"></div>`);
        super.commit();
    }
}