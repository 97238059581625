import {arrayEquals} from "./ArrayEquals";

/**
 * Permet de mettre à jour tous les propriété d'un objet si elles sont différentes
 */
export function objectUpdate(objectBase: any, newObject): any {
    if (newObject) {
        for (let property in objectBase) {
            if (objectBase.hasOwnProperty(property)) {
                if (typeof newObject[property] !== "undefined") {
                    if (!Array.isArray(newObject[property])) {
                        if (objectBase[property] != newObject[property]) {
                            objectBase[property] = newObject[property];
                        }
                    } else {
                        if (!arrayEquals(objectBase[property], newObject[property])) {
                            objectBase[property] = newObject[property];
                        }
                    }
                }
            }
        }

        for (let property in newObject) {
            if (!objectBase.hasOwnProperty(property)) {
                if (typeof newObject[property] !== "undefined") {
                    objectBase[property] = newObject[property]
                }
            }
        }
    }

    return objectBase;
}