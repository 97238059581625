/**
 * Met les heures, minutes etc à 0 ou les minutes etc à 59
 */
export function dateToCompare(d: Date, end?: boolean): Date {
    d = new Date(d);
    if (d != undefined) {
        if (end) {
            d.setHours(23);
            d.setMinutes(59);
            d.setSeconds(59);
            d.setMilliseconds(999);
        } else {
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
        }
    }

    return d;
}