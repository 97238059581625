export class WebSocketPanelEventName {
    public static CONNECTION_READY = "connection/ready";
    public static CONNECTION_ERROR = "connection/error";


    //manage room
    public static INBOX_JOIN = "inbox/join"; // receive join and compose inbox
    public static INBOX_LEAVE = "inbox/leave"; // receive leave
    //inbox room
    public static INBOX_JOIN_ACCOUNT = "inbox/join/account"; // compose account
    public static INBOX_LEAVE_ACCOUNT = "inbox/leave/account"; // compose account
    public static INBOX_UPDATE_COUNTS = "inbox/update/counts"; // compose inbox counts

    //collab editor room
    public static COLLABORATION_EDITOR_JOIN = "collaboration/editor/join"; //compose editor
    public static COLLABORATION_EDITOR_LEAVE = "collaboration/editor/leave"; //compose editor
    public static COLLABORATION_EDITOR_SAVE_ATTACHMENTS = "collaboration/editor/attachments"; //compose editor
    public static COLLABORATION_EDITOR_SAVE_METADATA = "collaboration/editor/metadata"; //compose editor
    public static COLLABORATION_EDITOR_VIEWER_JOIN = "collaboration/editor/viewer/join"; //compose editor
    public static COLLABORATION_EDITOR_VIEWER_LEAVE = "collaboration/editor/viewer/leave"; //compose editor

    //manage room
    public static SITE_JOIN = "site/join"; // receive join
    public static SITE_LEAVE = "site/leave"; // receive leave
    //site room
    public static SITE_UPDATE_ACTIVE_CONTACT = "site/active/contact"; //compose update active contact
    public static SITE_UPDATE = "site/update"; //compose site
    //site and livechat room
    public static SITE_JOIN_ACCOUNT = "site/join/account"; //compose joined account
    public static SITE_LEAVE_ACCOUNT = "site/leave/account"; //compose account
    //inbox room
    public static SITE_NEW_CONVERSATION = "site/new/conversation"; //compose new conversations
    //no room
    public static SITE_RELOAD = "site/reload"; //compose true


    //manage room
    public static LIVECHAT_JOIN = "livechat/join";
    public static LIVECHAT_LEAVE = "livechat/leave";
    public static LIVECHAT_DELETE = "livechat/delete";
    //livechat room
    public static LIVECHAT_ACTIVITY_UPDATE = "livechat/activity/update";
    public static LIVECHAT_APPEARANCE_UPDATE = "livechat/appearances/update";
    public static LIVECHAT_SECURITY_UPDATE = "livechat/security/update";
    public static LIVECHAT_SETTINGS_UPDATE = "livechat/settings/update";
    public static LIVECHAT_TEXT_UPDATE = "livechat/texts/update";
    public static LIVECHAT_FORM_UPDATE = "livechat/form/update";

    //manage room
    public static INBOX_CONVERSATION_JOIN = "conversation/join"; // receive join
    public static INBOX_CONVERSATION_LEAVE = "conversation/leave"; // receive leave
    // livechat and site room
    public static INBOX_CONVERSATION_UPDATE = "conversation/update"; //compose conversation
    public static INBOX_CONVERSATION_UPDATE_LIST = "conversation/update/list"; //compose conversation
    public static INBOX_CONVERSATION_UPDATE_LIGHT = "conversation/update/light"; //compose conversation
    public static INBOX_CONVERSATION_UPDATE_LIGHT_LIST = "conversation/update/light/list"; //compose conversation
    public static INBOX_CONVERSATIONS_DELETE = "conversations/delete";

    //site room
    public static ROLE_UPDATE = "role/update"; //compose role
    //set to roles room
    public static ROLE_CREATE = "role/create"; //compose role
    public static ROLE_DELETE = "role/delete"; //compose role


    //site room
    public static CONTACT_JOIN = "contact/join"; //compose contact
    public static CONTACT_LEAVE = "contact/leave"; //compose contact
    public static CONTACT_UPDATE = "contact/update"; //compose contact
    public static CONTACT_UPDATE_COUNTS = "contact/update/counts"; // compose inbox counts

    //no room
    public static ACCOUNT_SOCIAL_LOGIN = "account/social/login"; //compose account
    public static ACCOUNT_LOGOUT = "account/logout";//compose true
    public static ACCOUNT_UPDATE = "account/update"; //compose account
    //site room
    public static ACCOUNT_KICK = "account/kick"; //compose account

    // inbox and livechat room
    public static ENTITY_TYPING = "entity/typing"; //compose entity typing
    //site room
    public static ENTITY_UPDATE = "entity/update"; //compose contact or account

    //site inbox and livechat room
    public static MESSAGE_NEW = "message/new"; //compose new message
    public static MESSAGE_UPDATE = "message/update"; //compose new message

    //site channel
    public static SITE_CHANNEL_UPDATE = "sitechannel/update";

    //inbox room
    public static FILTER_UPDATE = "filter/update"; //compose new filter
    public static FILTER_DELETE = "filter/delete"; //compose new filter

    //inbox room
    public static CATEGORY_UPDATE = "category/update"; //compose new category
    public static CATEGORY_DELETE = "category/delete"; //compose new category

    //contact room
    public static NOTE_NEW = "note/new"; //compose note
    public static NOTE_DELETE = "note/delete"; //compose note

    //site room
    public static INBOX_SHORTCUT_NEW = "shortcut/new"; //compose note
    public static INBOX_SHORTCUT_DELETE = "shortcut/delete"; //compose note

    //ws entity and inbox
    public static NOTIFICATION_ACCOUNT_UPDATE = "notification/account/update"; //compose account
    public static NOTIFICATION_CONTACT_UPDATE = "notification/contact/update"; //compose account

    //ws publisher
    public static PUBLISHER_JOIN = "publisher/join"; // receive join
    public static PUBLISHER_LEAVE = "publisher/leave"; // receive leave
    public static PUBLISHER_JOIN_ACCOUNT = "publisher/join/account"; // receive join
    public static PUBLISHER_LEAVE_ACCOUNT = "publisher/leave/account"; // receive leave
    public static PUBLISHER_POST_DELETE = "publisher/post/delete"; // receive join
    public static PUBLISHER_POST_UPDATE = "publisher/post/update"; // receive join
    public static PUBLISHER_POST_UPDATES = "publisher/post/updates"; // receive join

    //gpt service
    public static GPT_SEGMENT = "gpt/segment"; // receive
}