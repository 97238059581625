@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentCommunitySearch {
  display: flex;
  flex-direction: column;
  gap: 4px;

  div[scrolltrack] {
    right: 5px;
    top: 10px;
    height: calc(100% - 20px);
  }

  .entry {
    padding: 6px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: var(--grey-sur);
    }

    .title {
      font-size: 16px;
      color: var(--black);

      @include texts.font("semi-bold");
    }
  }
}