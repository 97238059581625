import {observable} from "@nx-js/observer-util";
import {Network} from "../network/Network";
import {INetworkComponentError} from "../network/types/INetworkComponentError";
import {INetworkComponent} from "../network/types/INetworkComponent";
import {ProductType} from "../models/product/ProductType";
import {objectUpdate} from "../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectUpdate";

export class Services {
    public static storeAll<T>(list: T[]): T[] {
        if (list == undefined) {
            return undefined;
        }

        return observable(list);
    }

    public static store<T>(key: string, array: T[], object: T): T {
        // @ts-ignore
        object = observable(object);
        let cache = array.filter(a => a[key] == object[key])[0];
        if (cache != undefined) {
            objectUpdate(cache, object);
            return cache;
        }

        array.push(object);
        return object;
    }

    public static unStore<T>(key: string, array: T[], object: T): T {
        let cache = array.filter(a => a[key] == object[key])[0];
        if (cache != undefined) {
            array.splice(array.indexOf(cache), 1);
            return cache;
        }

        return undefined;
    }

    public static get<T>(key: string, array: T[], value: string): T {
        return array.filter(a => a[key] == value)[0];
    }

    public static on(service: any, type: ProductType, event: string, func: (data: any) => void) {
        if (!service.socketHandlers) {
            service.socketHandlers = [];
        }

        service.socketHandlers.push({event: event, func: func});
        Network.on(type, event, func);
    }

    public static beforeInit(service: any) {
        if (service.socketHandlers && Network.getSocket()) {
            service.socketHandlers.forEach(v => Network.getSocket().removeListener(v.event));
            service.socketHandlers = [];
        }
    }

    public static handleErrors(component: INetworkComponent, errors: INetworkComponentError[]) {
        if (!component) {
            component = {
                networkErrorsHandler: [],
                addNetworkErrors(errors: INetworkComponentError[]): void {
                    errors.forEach(error => this.networkErrorsHandler.push(error));
                }
            }
        }

        if (component && component.networkErrorsHandler.length === 0) {
            component.addNetworkErrors(errors);
        }
    }
}