export class Models {
    public static isPreviewModel(objet: any): boolean {
        return objet['baseUrl'] != undefined;
    }

    public static isContactModel(object: any): boolean {
        return object['sessionsCount'] != undefined;
    }

    public static isMediaModel(object): boolean {
        return object["extension"] != undefined;
    }

    public static isAccountModel(object: any): boolean {
        return object['account'] != undefined;
    }


    public static isEntityModel(object: any): boolean {
        return this.isAccountModel(object) || this.isContactModel(object);
    }

}