@use "../../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "../../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentBlogArticles {
  width: 100%;
  padding: 25px 0px 25px 0px;

  &.full {
    height: calc(100% - 50px);
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;

    img {
      height: 65px;
      width: 65px;
      position: relative;
      margin-bottom: 20px;
    }

    .name {
      font-size: 16px;

      @include texts.font("semi-bold");
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      color: var(--grey-text-dark);
      text-align: center;
    }

  }

  .articles {
    gap: 20px;
    column-gap: 60px;
    row-gap: 25px;
    display: flex;
    flex-wrap: wrap;

    .article {
      width: calc(50% - 30px);
      border-radius: 16px;
      position: relative;
      cursor: pointer;
      transition: 0.07s;
      margin-bottom: 15px;
    }
  }

  .paging {
    width: 100%;
    height: 100px;
    margin-top: 25px;
  }

  .loader {
    height: calc(100% - 160px);
    width: 100%;
  }

  @media screen and (max-width: 950px) {
    .articles {
      .article {
        width: 100%;
        margin-bottom: 50px;
      }
    }
  }
}