import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IRoleAccountModel} from "../../../models/roles/account/IRoleAccountModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {RoleService} from "../RoleService";
import {ProductType} from "../../../models/product/ProductType";

export class RoleAccountService {
    public static accounts: IRoleAccountModel[] = observable([]);

    public static dispose(): void {
        this.accounts = observable([]);
    }

    public static init(): void {

    }

    /**
     * http
     */

    public static async delete(id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/roles/account/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return true;
        }

        return false;
    }

    public static async create(accountId: string, roleId: string, component?: INetworkComponent): Promise<IRoleAccountModel> {
        let request = await Network.post(ProductType.PANEL, "/roles/account",
            {
                accountId: accountId,
                roleId: roleId
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }


    /**
     * store
     */
    public static storeAll(rolesAccount: IRoleAccountModel[]): IRoleAccountModel[] {
        for (let key in rolesAccount)
            rolesAccount[key] = this.store(rolesAccount[key]);

        return Services.storeAll(rolesAccount);
    }

    public static store(roleAccount: IRoleAccountModel): IRoleAccountModel {
        roleAccount.role = RoleService.store(roleAccount.role);

        roleAccount = Services.store("id", this.accounts, roleAccount);
        return roleAccount;
    }
}