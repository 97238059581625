import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IPreviewModel} from "../../models/preview/IPreviewModel";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {PreviewType} from "../../models/preview/PreviewType";
import {config} from "../../config";
import {ProductType} from "../../models/product/ProductType";

export class PreviewService {
    public static previews: IPreviewModel[] = observable([]);

    public static dispose(): void {
        this.previews = observable([]);
    }

    public static init(): void {

    }

    /**
     * load
     */

    public static async findByUrl(url: string, component?: INetworkComponent): Promise<IPreviewModel> {
        //TODO store and cache
        let request = await Network.post(ProductType.PANEL, `/preview/get`, {url: url}, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static proxyUrl(preview: IPreviewModel): string {
        switch (preview.type) {
            case PreviewType.GIF:
            case PreviewType.FILE:
            case PreviewType.AUDIO:
            case PreviewType.IMAGE:
            case PreviewType.VIDEO:
                if (preview.baseUrl != undefined && !this.proxyBypass(preview)) {
                    return config.httpPanelUrl + `/proxy?hash=${preview.id}&url=${encodeURI(preview.baseUrl)}`;
                }
                break;
            case PreviewType.WEBSITE:
                if (preview.fileUrl != undefined) {
                    console.log(preview.fileUrl);
                    return config.httpPanelUrl + `/proxy?hash=${preview.id}&url=${encodeURI(preview.fileUrl)}`;
                }
                break;
        }

        return preview.baseUrl;
    }

    public static proxyBypass(preview: IPreviewModel): boolean {
        return preview.baseUrl.startsWith(config.tenorMediaHost);
    }

    /**
     * store
     */
    public static storeAll(previews: IPreviewModel[]): IPreviewModel[] {
        for (let key in previews)
            previews[key] = this.store(previews[key]);

        return Services.storeAll(previews);
    }

    public static store(preview: IPreviewModel): IPreviewModel {
        preview = Services.store("id", this.previews, preview);
        return preview;
    }
}