import {SedestralInterface} from "../../SedestralInterface";
import {Component} from "../Component";

/**
 * draggable
 */
export function onDrag(component: Component, func: (left: number, top: number) => void, onFinish?: () => void) {
    let initX, initY, mousePressX, mousePressY;
    let down = false;
    let tDown = false;
    let moveListener = undefined;
    let upListener = undefined;
    let tMoveListener = undefined;
    let tUpListener = undefined;

    let touchDown = (e) => {
        SedestralInterface.body.setStyle(`user-select:none;`);
        if (component.element instanceof HTMLElement) {
            initX = component.element.offsetLeft;
            initY = component.element.offsetTop;
            mousePressX = e.touches[0].pageX;
            mousePressY = e.touches[0].pageY;
            tDown = true;
            tMoveListener = component.putListener(window, 'touchmove', move);
            tUpListener = component.putListener(window, 'touchend', touchUp);
        }
    };

    let touchUp = (e) => {
        SedestralInterface.body.setStyle(`user-select:unset;`);
        if (tDown) {
            tDown = false;
            component.deleteListener(tMoveListener);
            component.deleteListener(tUpListener);
            if (onFinish) {
                onFinish();
            }
        }
    };

    let mouseDown = (event) => {
        SedestralInterface.body.setStyle(`user-select:none;`);
        if (component.element instanceof HTMLElement) {
            initX = component.element.offsetLeft;
            initY = component.element.offsetTop;
            mousePressX = event.pageX;
            mousePressY = event.pageY;

            down = true;
            moveListener = component.putListener(window, 'mousemove', move);
            upListener = component.putListener(window, 'mouseup', mouseUp);
        }
    };

    let mouseUp = () => {
        SedestralInterface.body.setStyle(`user-select:unset;`);
        if (down) {
            down = false;
            component.deleteListener(moveListener);
            component.deleteListener(upListener);
            if (onFinish) {
                onFinish();
            }
        }
    };

    component.addListener('touchstart', touchDown);
    component.addListener('mousedown', mouseDown);

    let move = (event) => {
        if (tDown) {
            let left = initX + event.touches[0].pageX - mousePressX;
            let top = initY + event.touches[0].pageY - mousePressY;

            func(left, top);
        }

        if (down) {
            let left = initX + event.pageX - mousePressX;
            let top = initY + event.pageY - mousePressY;

            func(left, top);
        }
    }
}