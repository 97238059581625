/**
 * retourne la date pour les metas
 */

export function dateFormatMeta(date: Date): string {
    const isoDate = date.toISOString();

    const offset = -date.getTimezoneOffset();
    const offsetSign = offset >= 0 ? '+' : '-';
    const offsetHours = Math.abs(Math.floor(offset / 60)).toString().padStart(2, '0');
    const offsetMinutes = Math.abs(offset % 60).toString().padStart(2, '0');
    const timezoneFormat = `${offsetSign}${offsetHours}:${offsetMinutes}`;

    const localISODate = `${isoDate.substring(0, isoDate.length - 1)}${timezoneFormat}`;

    return localISODate;
}
