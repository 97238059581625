import {Filter} from "../../Filter";
import {ConditionType} from "../../../../enums/ConditionType";
import {FilterOperatorIdentifiersEntries} from "../operator/FilterOperatorIdentifiersEntries";
import {FiltersOperatorIdentifiers} from "../operator/FiltersOperatorIdentifiers";
import {OperatorType} from "../../../../enums/OperatorType";
import {ValueType} from "../../../../enums/ValueType";
import {
    arrayRemove
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";

/**
 * Décode les filtres exporter au format JSON
 */
export class FilterBuilderReader {

    public filtersData: any;
    public conditionType: ConditionType;
    public filters: Filter[];

    constructor(filtersData?: any) {
        filtersData = typeof filtersData == "string" ? JSON.parse(filtersData) : filtersData;

        this.filtersData = filtersData ? filtersData : {conditionType: ConditionType.AND, filters: []};
        this.conditionType = this.filtersData.conditionType;
        this.filters = [];

        for (let filterData of this.filtersData.filters) {
            let filter = new Filter(filterData.operator, filterData.identifier, filterData.values, FilterOperatorIdentifiersEntries.get(filterData.identifier));
            this.filters.push(filter);
        }
    }

    getEntry(identifier: string): Filter {
        return this.filters.find(value => value.identifier == identifier);
    }

    removeEntry(identifier: string) {
        arrayRemove(this.filters, this.getEntry(identifier));
    }

    addEntry(p: { operator: OperatorType, identifier: string, values: any[] }) {
        let filter = new Filter(p.operator, p.identifier, p.values, FilterOperatorIdentifiersEntries.get(p.identifier));
        this.filters.push(filter);
    }

    addSort(sort: OperatorType, columnId: string, valueType: ValueType, isJson?: boolean) {
        this.addEntry({
            operator: sort,
            identifier: isJson ? FiltersOperatorIdentifiers.SORT_JSON_COLUMN_NAME : FiltersOperatorIdentifiers.SORT_COLUMN_NAME,
            values: [columnId + "-" + valueType]
        });
    }

    export(json?: boolean): any {
        let filters = [];
        this.filters.forEach(value => filters.push(value.jsonify()));
        let object = {
            "conditionType": this.conditionType,
            "filters": filters
        };
        return json ? object : JSON.stringify(object);
    }

}