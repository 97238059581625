import {MachineListener} from "../../../machine/listener/MachineListener";
import {Component} from "../Component";
import {SedestralMachine} from "../../../machine/SedestralMachine";
import * as s from "../../../../sedestral-interface-sass/imports/scroll.scss";

import {isTabletNative} from "../../../utilities/IsTabletNative";

export class ComponentScrollbar {

    public component: Component;

    public track: Component = undefined;
    public bar: Component = undefined;

    public dragScrollTop: number = 0;
    public timeOutResize: number = 0;
    public isDragging: boolean = false;
    public trackRect: DOMRect = undefined;
    public scrollRatio: number = 1;
    public lastPageY: number;

    public mouseDragListener: MachineListener;
    public mouseStopDragListener: MachineListener;

    public now: number;
    public autoPlace: boolean;

    constructor(component: Component, autoPlace?: boolean) {
        this.component = component;
        this.now = performance.now();
        this.autoPlace = autoPlace;

        this.build();
        this.binding();
    }

    build(): void {
        if (this.component.element instanceof HTMLElement) {
            let template = `
                <div scrollTrack class="${s.scrollVirtualTrack}">
                    <div scrollBar class="${s.scrollVirtualBar}"></div>
                </div>`;

            this.component.prepend(template);
            this.track = this.component.el(s.scrollVirtualTrack);
            this.bar = this.component.el(s.scrollVirtualBar);

            this.component.addClass(s.scrollVirtual);

            if (this.autoPlace) {
                this.place();
                this.component.putListener(window, "resize", () => this.place());
            }
        }
    }

    binding(): void {
        this.bar.addListener("mousedown", (e) => {
            this.onBarMouseDown(e);
        });
        this.track.addListener("click", (e) => {
            this.onTrackClick(e);
        });


        this.component.onScroll(() => this.onScrollResize());
        //this.component.onChange(() => this.onScrollResize());
        this.component.onResize(() => this.onScrollResize());
        this.component.interval(() => this.onScrollResize(), 500);
    }

    onScrollResize(): void {
        if (performance.now() - this.now > 1) {
            if (!isTabletNative() && this.component.element instanceof HTMLElement) {
                let difference = this.component.element.scrollHeight - this.component.element.clientHeight;
                if (difference <= 0) {
                    this.track.setStyle("display:none;");
                    this.component.removeClass(s.scrollVirtual);
                    SedestralMachine.requestFrame()(() => {
                        this.component.removeAttribute("scrollVirtual");
                    });
                } else {
                    this.track.setStyle("display:block;");
                    this.component.addClass(s.scrollVirtual);
                    this.component.setAttribute("scrollVirtual", "");
                }

                this.moveBar();
                this.timeOutResize = this.component.timeOut(() => this.getTrackBounds(), 200);
            }
            this.now = performance.now();
        }
    }

    onDrag(e: any): void {
        this.isDragging = true;
        let delta = e.pageY - this.lastPageY;

        SedestralMachine.requestFrame()(() => {
            let sTop = document.documentElement.scrollTop;
            let isDragWithinTrackBounds = e.pageY >= (this.trackRect.top + sTop) && e.pageY <= (this.trackRect.bottom + sTop);

            if (isDragWithinTrackBounds) {
                this.component.setScrollTop(this.dragScrollTop + delta / this.scrollRatio);
            } else {
                this.dragScrollTop = this.component.getScrollTop();
                this.lastPageY = e.pageY;
            }
        });
    }

    onStopDrag(e: any): void {
        this.bar.removeClass(s.scrollVirtualGrabbed);
        this.track.removeClass(s.scrollVirtualGrabbed);

        this.bar.removeAttribute("scrollGrabbed");
        this.track.removeAttribute("scrollGrabbed");

        document.body.classList.remove(s.scrollVirtualSelect);

        this.component.deleteListener(this.mouseDragListener);
        this.component.deleteListener(this.mouseStopDragListener);

        this.component.timeOut(() => {
            this.isDragging = false;
        }, 100);

    }

    onBarMouseDown(e: any): void {
        this.dragScrollTop = this.component.getScrollTop();
        this.lastPageY = e.pageY;

        this.bar.addClass(s.scrollVirtualGrabbed);
        this.track.addClass(s.scrollVirtualGrabbed);

        this.bar.setAttribute("scrollGrabbed", "");
        this.track.setAttribute("scrollGrabbed", "");
        document.body.classList.add(s.scrollVirtualSelect);

        this.mouseDragListener = this.component.putListener(window, "mousemove", (e) => {
            this.onDrag(e);
        });
        this.mouseStopDragListener = this.component.putListener(window, "mouseup", (e) => {
            this.onStopDrag(e);
        });
    }

    onTrackClick(e: any): void {
        if (this.isDragging) {
            return;
        }
        if (this.component.element instanceof HTMLElement) {
            let perc = (e.clientY - this.trackRect.top) / (this.trackRect.height - this.trackRect['topPad'] - this.trackRect['bottomPad']);
            let scrollHeight = this.component.element.scrollHeight;
            let ownHeight = this.component.element.clientHeight;
            let newScrollTop = perc * (scrollHeight - ownHeight);

            this.component.element.style.scrollBehavior = 'smooth';
            this.component.timeOut(() => {
                if (this.component.element instanceof HTMLElement) {
                    this.component.element.style.scrollBehavior = 'unset'
                }
            }, 500)

            this.component.element.scrollTop = newScrollTop;
        }
    }

    getTrackBounds(): void {
        if (this.track.element instanceof HTMLElement) {
            this.trackRect = this.track.element.getBoundingClientRect();
            let styles = window.getComputedStyle(this.track.element, undefined);

            this.trackRect['topPad'] = parseInt(styles.paddingTop, 10);
            this.trackRect['bottomPad'] = parseInt(styles.paddingBottom, 10);
        }
    }

    moveBar(): void {
        if (this.component.element instanceof HTMLElement && this.track.element instanceof HTMLElement) {
            let scrollHeight = this.component.element.scrollHeight;
            let clientHeight = this.component.element.clientHeight;
            this.scrollRatio = this.track.element.clientHeight / scrollHeight;


            SedestralMachine.requestFrame()(() => {
                if (this.component.element instanceof HTMLElement) {
                    let height = (clientHeight / scrollHeight) * 100;
                    let top = (this.component.element.scrollTop / scrollHeight) * 100;
                    this.bar.setStyle(`height:${height.toFixed(1)}%;top:${top.toFixed(1)}%;`);
                }
            });
        }
    }

    place(): void {
        this.track.setStyle(`position:fixed;transition:0s;right:${this.component.getOffsets().right + 2}px;height:${this.component.getHeight() - 8}px;top: ${this.component.getOffsets().top + 4}px;`);
        SedestralMachine.requestFrame()(() => this.track.setStyle(`transition: 0.1s`));
    }
}