import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./community-network-error.scss";
import {Resources} from "../../../../../resources/Resources";

export class CommunityNetworkLostComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentCommunityNetworkLost}">
                <div>
                    <div class="${s.imageContainer}">
                        <div class="${s.image}"></div>
                    </div>
                    <div class="${s.text}">
                        ${Resources.t("words.cantHelpYouNow")} 😇
                    </div>
                </div>
            </div>
        `;
    }
}