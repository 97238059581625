import {Network} from "../../../../network/Network";
import {CommunityService} from "../../../../services/community/CommunityService";
import {PreferencesService} from "../../../../services/preferences/PreferencesService";
import {SedestralSsr} from "../../../../sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {CommunityVisitorsService} from "../../../../services/community/visitors/CommunityVisitorsService";

export class CommunityNetwork {

    static async init() {
        Network.onToken = async (): Promise<boolean> => {
            return SedestralSsr.hasSsr() ? true : await CommunityService.token() != undefined;
        }

        Network.onLogout = async (): Promise<boolean> => {
            return true;
        }

        Network.onLogin = async (): Promise<boolean> => {
            return true;
        }

        Network.lostFunctions = [];
        Network.onNetworkLost(() => {

        });

        Network.lostSolvedFunctions = [];
        Network.onNetworkLostSolved(() => {

        });

        this.initServices();
    }

    static initServices() {
        CommunityService.init();
        CommunityVisitorsService.init();
        PreferencesService.init();
    }
}