import {Network} from "../../network/Network";
import {IAccountModel} from "../../models/account/IAccountModel";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {HttpStatus} from "../../network/status/HttpStatus";
import {AvatarService} from "../avatar/AvatarService";
import {EntityType} from "../../models/entity/types/EntityType";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {RoleAccountService} from "../roles/account/RoleAccountService";
import {IRoleModel} from "../../models/roles/IRoleModel";
import {EntityService} from "../entity/EntityService";
import {IAccountRegisterModel} from "../../models/account/IAccountRegistrationModel";
import {ProductType} from "../../models/product/ProductType";
import {ErrorCode} from "../../network/status/error/ErrorCode";
import {Resources} from "../../resources/Resources";
import {IAccountMe} from "../../models/account/IAccountMe";
import {IAccount2faEnable} from "../../models/account/IAccount2faEnable";
import {IAccountAdminModel} from "../../models/account/IAccountAdminModel";

export class AccountService {

    public static accounts: IAccountModel[] = observable([]);

    public static dispose(): void {
        this.accounts = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ACCOUNT_UPDATE, (data) => {
            this.store(data);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ACCOUNT_LOGOUT, () => {
            location.reload();
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ACCOUNT_KICK, (data) => {
            let account = this.store(data);
            account.siteId = undefined;
            if (account.id == EntityService.activeEntity.id) {
                location.reload();
            }
        });
    }

    /**
     * rest
     */

    public static async create(data: IAccountRegisterModel, component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {status: HttpStatus.TOO_MANY_REQUESTS, message: Resources.t("words.accountRegisterToManyRequests")}
        ]);
        let request = await Network.postJson(ProductType.PANEL, `/account`, data, component);
        return request.status == HttpStatus.OK;
    }

    public static async update(name: string, email: string, work: string, numberPhone: string, component?: INetworkComponent): Promise<number> {
        let request = await Network.post(ProductType.PANEL, "/account/update",
            {
                name: name,
                language: Resources.language,
                email: email,
                work: work,
                numberPhone: numberPhone
            }, component);

        return request.status;
    }

    public static async registerTransaction(transactionId: string, sessionId?: string, component?: INetworkComponent) {
        return await Network.post(ProductType.PANEL, "/account/register/transaction",
            {
                transactionId: transactionId,
                sessionId: sessionId
            }, component);
    }

    public static async updatePassword(oldPassword: string, newPassword: string, component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.PASSWORD, message: Resources.t("words.account.currentPasswordUnavailable")}
        ]);

        let request = await Network.post(ProductType.PANEL, "/account/update/password",
            {
                oldPassword: oldPassword,
                newPassword: newPassword
            }, component);

        return request.status == HttpStatus.OK;
    }

    public static async activity(active: boolean, component?: INetworkComponent): Promise<IAccountModel> {
        let request = await Network.post(ProductType.PANEL, `/account/activity`, {active: active}, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async findByMe(sessionId: string, component?: INetworkComponent): Promise<IAccountMe> {
        let request = await Network.get(ProductType.PANEL, `/account/me/${sessionId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async findBySiteId(siteId: string, component?: INetworkComponent): Promise<IAccountModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/account/site/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findRolesBySiteId(siteId: string, component?: INetworkComponent): Promise<IAccountModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/account/roles/site/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findById(id: string, component?: INetworkComponent): Promise<IAccountModel> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/account/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async twoFactorVerify(id: string, token: string, component?: INetworkComponent): Promise<IAccountModel> {
        let request = await Network.get(ProductType.PANEL, `/account/2fa/verify/${id}/${token}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async twoFactorDisabled(component?: INetworkComponent): Promise<IAccountModel> {
        let request = await Network.get(ProductType.PANEL, `/account/2fa/disable`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async twoFactorEnabled(component?: INetworkComponent): Promise<IAccount2faEnable> {
        let request = await Network.get(ProductType.PANEL, `/account/2fa/enable`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getAccountAdmin(id: string, component?: INetworkComponent): Promise<IAccountAdminModel> {
        let request = await Network.get(ProductType.PANEL, `/account/admin/` + id, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async updateEmailNotifyAccountAdmin(id: string, state: boolean, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.get(ProductType.PANEL, `/account/admin/emailNotify/` + id + '/' + state, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }


    /**
     * has
     */

    public static hasRole(account: IAccountModel, role: IRoleModel): boolean {
        if (account.rolesAccount != undefined) {
            return account.rolesAccount.filter(roleAccount => roleAccount.role.id == role.id).length > 0;
        }

        return false;
    }

    /**
     * logic
     */

    public static async requestLogin(request?) {
        EntityService.headersToken(request?.headers);
        await Network.onToken();
        await Network.onLogin();
        Network.router.onLogin();
    }

    /**
     * get
     */

    public static getAll(): IAccountModel[] {
        return this.accounts;
    }

    /**
     * store
     */

    public static storeAll(accounts: IAccountModel[]): IAccountModel[] {
        for (let key in accounts) {
            accounts[key] = this.store(accounts[key]);
        }
        return Services.storeAll(accounts);
    }

    public static store(account: IAccountModel): IAccountModel {
        if (account != undefined) {

            if (account.avatar != undefined) {
                account.avatar = AvatarService.store(account.avatar);
            }

            if (account.rolesAccount != undefined) {
                account.rolesAccount = RoleAccountService.storeAll(account.rolesAccount);
            }

            account.type = EntityType.ACCOUNT;

            if (account.id === EntityService.activeEntity?.id && account.email === "") {
                account.email = EntityService.activeEntity?.email;
            }

            return Services.store("id", this.accounts, account);
        }
    }


}