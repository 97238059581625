import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-object-list.scss";

import {randomInteger} from "../../../../sedestral-interface-component/utilities/RandomInteger";

export class LoaderObjectListComponent extends Component {
    public minHeight?: number;
    public maxHeight?: number;

    constructor(shine: boolean, size?: number, minHeight?: number, maxHeight?: number) {
        super();
        this.minHeight = minHeight;
        this.maxHeight = maxHeight;

        if (typeof size == "undefined") {
            size = 16;
        }

        let objects = this.object();
        for (let i = 0; i < size; i++) {
            objects += this.object();
        }

        this.template = `
            <div loaderObjectList class="${s.globalLoaderObjectList} ${shine ? s.shine : ``}">
                ${objects}
            </div>
        `;
    }

    object(): string {
        let height = randomInteger(this.minHeight == undefined ? 60 : this.minHeight, this.maxHeight ? this.maxHeight : 120);
        return `<div style="height: ${height}px;" class="${s.object}"></div>`;
    }
}