import {
    ISedestralRouterConfig
} from "../../../../sedestral-interface-modules/sedestral-interface-router/types/ISedestralRouterConfig";
import {CommunityRouter} from "../../abstract/components/CommunityRouter";
import {BlogCategoryPage} from "./pages/BlogCategoryPage";
import {BlogArticlePage} from "./pages/BlogArticlePage";
import {Blog404Page} from "./pages/Blog404Page";

export class BlogRouter extends CommunityRouter {
    async init(config?: ISedestralRouterConfig): Promise<void> {
        await super.init(config);
    }

    registerPages() {
        this.pages.registerPage("/category", () => new BlogCategoryPage());
        this.pages.registerPage("/article", () => new BlogArticlePage());
        this.pages.registerPage("/404", () => new Blog404Page());

        this.pages.path404 = "/404";
        this.pages.pathHome = "/home";
        this.pages.pathLogin = "/home";

        super.registerPages();
    }
}