import {BlogPageComponent} from "../BlogPageComponent";
import * as s from "../blog-page.scss";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {BlogReadComponent} from "../../blog/read/BlogReadComponent";
import {
    LoaderObjectListComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/objects/list/LoaderObjectListComponent";
import {BlogReadDetailsComponent} from "../../blog/read/details/BlogReadDetailsComponent";
import {Network} from "../../../../../../../network/Network";
import {ICommunityLiveModel} from "../../../../../../../models/community/ICommunityLiveModel";

export class BlogPageArticleComponent extends BlogPageComponent {

    public navigationContentContainer: Component;

    constructor(live?: ICommunityLiveModel) {
        super(live);
    }

    /**
     * render
     */

    renderBody() {
        //language=HTML
        this.bodyContainer = this.append(`
            <div class="${s.body}"></>`);

        let blogComponent = new BlogReadComponent(this.live);
        this.bodyContainer.render(blogComponent);

        blogComponent.onReady = (view) => {
            this.navigationContentContainer.clearAll();
            this.navigationContentContainer.render(new BlogReadDetailsComponent(this.live, view.article))
            Network.router.pages.updateMetasCommunity(this.live.community, true, view.article.title, view.article.snippet, view.article.keywords, view.article.imageFile, new Map(Object.entries(view.article.alternateLanguages)));
        }
    }

    renderNavigation() {
        super.renderNavigation();
        //language=HTML
        let content = this.navigationContainer.append(`
            <div class="${s.content}">
                <div class="${s.loader}">
                    ${this.navigationContainer.draw(new LoaderObjectListComponent(true, 2, 60, 50))}
                </div>
            </div>`);

        this.navigationContentContainer = content.el(s.content);
        content.pendAfter(this.navigationBrandContainer);
    }

    /**
     * update
     */
}