import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {IMailAliasModel} from "../../../models/mail/alias/IMailAliasModel";
import {INetworkComponentError} from "../../../network/types/INetworkComponentError";
import {ProductType} from "../../../models/product/ProductType";

export class MailAliasService {
    public static aliases: IMailAliasModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.aliases = observable([]);
    }

    /**
     * rest
     */

    public static async findByServer(serverId: string, component: INetworkComponent): Promise<IMailAliasModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/mails/alias/${serverId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async create(names: string[], mailId: string, errors: INetworkComponentError[], component?: INetworkComponent): Promise<{
        alias: IMailAliasModel,
        status: HttpStatus
    }> {
        Services.handleErrors(component, errors);
        let request = await Network.post(ProductType.PANEL, "/mails/alias",
            {
                names: names,
                mailId: mailId
            }, component);

        if (request.status != HttpStatus.OK) {
            return {alias: undefined, status: request.status};
        }

        return {alias: this.store(request.data), status: request.status};
    }

    public static async update(id: string, names: string[], errors: INetworkComponentError[], component?: INetworkComponent): Promise<{
        alias: IMailAliasModel,
        status: HttpStatus
    }> {
        Services.handleErrors(component, errors);
        let request = await Network.post(ProductType.PANEL, "/mails/alias/update",
            {
                names: names,
                id: id
            }, component);

        if (request.status != HttpStatus.OK) {
            return {alias: undefined, status: request.status};
        }

        return {alias: this.store(request.data), status: request.status};
    }

    public static async delete(alias: IMailAliasModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/mails/alias/${alias.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(alias);
            return true;
        }

        return false;
    }

    public static storeAll(aliases: IMailAliasModel[]): IMailAliasModel[] {
        for (let key in aliases)
            aliases[key] = this.store(aliases[key]);

        return Services.storeAll(aliases);
    }

    public static store(alias: IMailAliasModel): IMailAliasModel {
        return Services.store("id", this.aliases, alias);
    }

    public static unStore(alias: IMailAliasModel) {
        Services.unStore("id", this.aliases, alias);
    }

}