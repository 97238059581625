import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./empty.scss";
import {Network} from "../../../network/Network";
import {LoaderLightComponent} from "../loader/light/LoaderLightComponent";

export class EmptyBasicComponent extends Component {

    public buttonContainer: Component;
    public button: Component;

    public textContainer: Component;

    public loading: Component;

    constructor(emoji: string, text: string, buttonText?: string, loader?: boolean) {
        super();

        //language=HTML
        this.template = `
            <div emptyBasic class="${s.globalEmptyBasic}">
                <div class="${s.emptyContent}">
                    <div emptyemoji class="${s.emoji}">${emoji}</div>
                    ${text == undefined ? `` : `<div emptyText class="${s.text}">${text}</div>`}
                    ${buttonText == undefined ? `` : `<div class="${s.buttonContainer}"><div emptyButton class="${s.button}">${buttonText}</div></div>`}
                    ${loader == undefined ? `` : `<div class="${s.loader}">${this.draw(new LoaderLightComponent({
                        width: 50,
                        strokeWidth: 3
                    }))}</div>`}
                </div>
            </div>
        `;
    }

    commit() {
        this.buttonContainer = this.el(s.buttonContainer);
        this.button = this.el(s.button);
        this.textContainer = this.el(s.text);

        if (this.buttonContainer && !this.buttonContainer.isNull()) {
            this.buttonContainer.onClick(() => {
                if (!this.button.hasClass(s.loading))
                    this.onButtonClick()
            });
        }

        super.commit();
    }

    onButtonClick() {
        Network.router.pages.redirect("/general/categories");
    }

    setButtonLoading() {
        if (!this.buttonContainer.isNull()) {
            this.loading = this.button.render(new LoaderLightComponent());
            this.button.addClass(s.loading);
        }
    }

    remButtonLoading() {
        if (!this.buttonContainer.isNull() && this.loading) {
            this.button.removeClass(s.loading);
            this.loading.remove();
            this.loading = undefined;
        }
    }
}