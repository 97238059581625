export class SedestralPageStatsActivity {

    public currentPageDuration: number;
    public intervalId: any;

    constructor() {
        this.currentPageDuration = 0;
        this.intervalId = null;
        this.setupActivityTracking();
    }

    init() {
        this.currentPageDuration = 0;
    }

    setupActivityTracking() {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.startTracking();
            } else {
                this.stopTracking();
            }
        });

        if (document.visibilityState === 'visible') {
            this.startTracking();
        }
    }

    startTracking() {
        if (this.intervalId !== null) return;

        this.intervalId = setInterval(() => {
            this.currentPageDuration++;
        }, 1000);
    }

    stopTracking() {
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    dispose() {
        this.stopTracking();
        this.currentPageDuration = 0;
    }
}
