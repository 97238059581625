import {MachineListener} from "./listener/MachineListener";
import {Component} from "../interface/component/Component";
import {HotKeyListener} from "./listener/HotKeyListener";
import {createKeybindingsHandler} from "tinykeys"
import {MachineInput} from "./input/MachineInput";

export class SedestralMachine {

    public static log: boolean = false;
    public static listeners: MachineListener[] = [];
    public static inputs: MachineInput[] = [];
    public static hotkeyListeners: HotKeyListener[] = [];
    public static outsideClickListeners: MachineListener[] = [];
    public static resizeObserver: ResizeObserver;
    public static promises: { component: Component, promise: Promise<any>, displayRequired: boolean }[] = [];

    public static dispose() {
        this.listeners.forEach(value => {
            this.removeListener(value);
        });
        this.resizeObserver.disconnect();
    }

    public static async init(): Promise<void> {
        this.listeners = [];
        this.outsideClickListeners = [];
        if (this.log) {
            this.setInterval(() => {
                console.log("listeners " + this.listeners.length);
            }, 3000);
        }

        this.resizeObserver = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }

                for (let entry of entries) {
                    for (let f of entry.target['resizeFunctions']) {
                        f(entry.contentRect);
                    }
                }
            });
        });


        this.addListener(window, "keydown", (e) => {
            let nextIndex = e.code == "ArrowDown" ? 1 : (e.code == "ArrowUp" ? -1 : 0);
            if (nextIndex != 0) {
                let focusedInput = this.inputs.find(value => value.isFocus());
                if (focusedInput) {
                    let nextInput = this.inputs[this.inputs.indexOf(focusedInput) + nextIndex];
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
            }
        })
    }

    /**
     * timeout/interval
     */
    public static setTimeout(func: () => void, time: number): any {
        return setTimeout(() => {
            func();
        }, time);
    }

    public static setInterval(func: () => void, time: number): any {
        return setInterval(() => {
            func();
        }, time);
    }

    /**
     * listeners
     */
    public static removeListener(listener: MachineListener): void {

        if (listener == undefined) {
            return;
        }

        listener.element.removeEventListener(listener.name, listener.callback, listener.options);
        let index = this.listeners.indexOf(listener);
        if (index >= 0) {
            this.listeners.splice(index, 1);
        }

        let outsideClickIndex = this.outsideClickListeners.indexOf(listener);
        if (outsideClickIndex >= 0) {
            this.outsideClickListeners.splice(outsideClickIndex, 1);
        }
    }

    public static addListener(element: EventTarget, name: string, callback: any, options?: boolean): MachineListener {
        element.addEventListener(name, callback, options);
        let listener = new MachineListener(element, name, callback, options);
        this.listeners.push(listener);
        return listener;
    }

    public static addHotKeyListener(component: Component, keys: string, func: (e: any) => void): HotKeyListener {
        let existingKey = this.hotkeyListeners.find(value => value.hotkeys == keys && value.element == component.getHTMLElement());
        if (!existingKey) {
            existingKey = {hotkeys: keys, element: component.getHTMLElement(), entries: []};
            this.hotkeyListeners.push(existingKey);

            let map = {};
            map[keys] = (event) => {
                existingKey.entries.forEach(value => value.function(event));
            }
            let handler = createKeybindingsHandler(map);
            component.getHTMLElement().addEventListener("keydown", handler)
        }

        existingKey.entries.push({
            function: (e) => func(e)
        });

        return existingKey;
    }

    public static deleteHotKeyListener(component: Component, keys: string) {
        let existingKey = this.hotkeyListeners.find(value => value.hotkeys == keys && value.element == component.getHTMLElement());
        if (existingKey) {
            this.hotkeyListeners.splice(this.hotkeyListeners.indexOf(existingKey), 1);
        }
    }

    public static addOutsideClickListener(listener: MachineListener, component: Component) {
        listener['component'] = component;
        this.outsideClickListeners.push(listener);
    }

    /**
     * delay
     */
    public static async wait(func: () => Promise<any>, time: number): Promise<void> {
        return SedestralMachine.promise((end) => {
            let promise = SedestralMachine.promise((async (resolve) => {
                setTimeout(() => {
                    resolve(-1);
                }, time);
                let result = await func();
                resolve(result);
            }));

            promise.then((data: any) => {
                end(data);
            });
        });
    }

    public static async delay<T>(asyncFunc: () => Promise<any>, minimumTime: number): Promise<T> {
        let result = undefined;

        let run = async () => result = await asyncFunc();
        await Promise.all([
            run(),
            this.sleep(minimumTime)
        ]);

        return result;
    }

    public static async sleep(ms: number): Promise<void> {
        await SedestralMachine.promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, ms);
        })
    }

    /**
     * promise
     */
    public static promiseCancelable<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void): Promise<T> {
        let rejectFn;

        const wrappedPromise = new Promise((resolve, reject) => {
            rejectFn = reject;

            Promise.resolve(new Promise(executor))
                .then(resolve)
                .catch(reject);
        });

        wrappedPromise['cancel'] = () => {
            rejectFn({canceled: true});
        };

        return wrappedPromise as any;
    }

    public static async promise<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, displayRequired?: boolean, component?: Component): Promise<T> {
        let promise = this.promiseCancelable(executor);
        let savedPromise = {component: component, promise: promise, displayRequired: displayRequired};
        this.promises.push(savedPromise);
        promise.then(() => {
            this.promises.splice(this.promises.indexOf(savedPromise), 1);
        });

        if (component) {
            component.onRemove(() => {
                SedestralMachine.requestFrame()(() => {
                    if (this.promises.indexOf(savedPromise) >= 0) {
                        savedPromise.promise['cancel']();
                        this.promises.splice(this.promises.indexOf(savedPromise), 1);
                    }
                });
            })
        }

        return promise;
    }

    /**
     * frame
     */
    public static requestFrame(): any {
        // @ts-ignore
        let raf = window.requestAnimationFrame || window['mozRequestAnimationFrame'] || window.webkitRequestAnimationFrame ||
            function (fn) {
                return window.setTimeout(fn, 20);
            };
        return function (fn) {
            return raf(fn);
        };
    }

    public static cancelFrame(): any {
        // @ts-ignore
        let cancel = window.cancelAnimationFrame || window['mozCancelAnimationFrame'] || window.webkitCancelAnimationFrame ||
            window.clearTimeout;
        return function (id) {
            return cancel(id);
        };
    }
}