import {observable} from "@nx-js/observer-util";
import {IMailServerModel} from "../../../models/mail/server/IMailServerModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {Services} from "../../Services";
import {IMailServerDiskSpaceModel} from "../../../models/mail/server/IMailServerDiskSpaceModel";
import {IMailServerViewModel} from "../../../models/mail/server/IMailServerViewModel";
import {IMailServerStatsModel} from "../../../models/mail/server/IMailServerStatsModel";
import {MailServerSpamMode} from "../../../models/mail/server/MailServerSpamMode";
import {ProductType} from "../../../models/product/ProductType";

export class MailServerService {
    public static servers: IMailServerModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.servers = observable([]);
    }

    /**
     * rest
     */
    public static async create(name: string, component?: INetworkComponent): Promise<IMailServerModel> {
        let request = await Network.post(ProductType.PANEL, "/mails/server",
            {
                name: name
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(server: IMailServerModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/mails/server/${server.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(server);
            return true;
        }
        return false;
    }

    public static async updateSpam(server: IMailServerModel, spamMode: MailServerSpamMode, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.put(ProductType.PANEL, `/mails/server/spam/${server.id}`, {
            mailServerSpamModeCode: spamMode
        }, component);

        if (request.status == HttpStatus.OK) {
            server.spamMode = spamMode;
            return true;
        }

        return false;
    }

    public static async findDiskSpace(serverId: string, component: INetworkComponent): Promise<IMailServerDiskSpaceModel> {
        let request = await Network.get(ProductType.PANEL, `/mails/server/disk/space/${serverId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findView(serverId: string, component?: INetworkComponent): Promise<IMailServerViewModel> {
        let request = await Network.get(ProductType.PANEL, `/mails/server/view/${serverId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findStats(serverId: string, component?: INetworkComponent): Promise<IMailServerStatsModel> {
        let request = await Network.get(ProductType.PANEL, `/mails/server/stats/${serverId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findBySite(siteId: string, component: INetworkComponent): Promise<IMailServerModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/mails/server/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static storeAll(servers: IMailServerModel[]): IMailServerModel[] {
        for (let key in servers)
            servers[key] = this.store(servers[key]);

        return Services.storeAll(servers);
    }

    public static store(server: IMailServerModel): IMailServerModel {
        return Services.store("id", this.servers, server);
    }

    public static unStore(server: IMailServerModel) {
        Services.unStore("id", this.servers, server);
    }
}