@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalPaging {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    margin-left: 5px;
    margin-right: 5px;
  }

  .round {
    width: 36px;
    height: 36px;
    border: 1px solid transparent;
    border-radius: 6px;
    display: flex;
    margin-right: 4px;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur);
    }

    &.selected {
      border-color: transparent;
      border: 1px solid var(--grey-sur);
    }

    &.left {
      .icon {
        @include icons.ico("black-left-bold");
      }
    }

    &.right {
      .icon {
        @include icons.ico("black-right-bold");
      }
    }

    .icon {
      height: 10px;
      width: 10px;
    }
  }

}