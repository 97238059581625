export class RolePermissionsType {
    public static ADMINISTRATOR = "a";

    public static PUBLISHER_ADMINISTRATOR = "pa";
    public static PUBLISHER_PUBLISH = "be";
    public static PUBLISHER = "p";
    public static PUBLISHER_FACEBOOK_PAGE_READ = "ba";
    public static PUBLISHER_INSTAGRAM_PAGE_READ = "bb";
    public static PUBLISHER_TWITTER_READ = "bc";

    public static INBOX_ADMINISTRATOR = "m";
    public static INBOX = "b";
    public static INBOX_ASSIGNMENT = "h";
    public static INBOX_FILTERS_PUBLIC = "j";
    public static INBOX_SHORTCUTS_PUBLIC = "q";
    public static INBOX_FILES = "z";
    public static INBOX_CATEGORIES_PUBLIC = "ac";
    public static INBOX_EDIT_ALL = "af";

    public static INBOX_LIVECHAT_READ = "f";
    public static INBOX_MAIL_READ = "ak";
    public static INBOX_TWITTER_READ = "bd";
    public static INBOX_FACEBOOK_PAGE_READ = "rfb";
    public static INBOX_INSTAGRAM_PAGE_READ = "ris";
    public static INBOX_LINKEDIN_READ = "bg";
    public static INBOX_SHOPIFY_READ = "bo";

    public static GENERAL = "c";
    public static ACCOUNT_MANAGE = "g";
    public static INVITATIONS_MANAGE = "k";
    public static SITE_CHANNEL_MANAGE = "l";
    public static SITE_CHANNEL_LIVECHAT_MANAGE = "r";
    public static SITE_CHANNEL_MAILS_MANAGE = "ah";
    public static ROLES_MANAGE = "n";
    public static SITE_MANAGE = "ad";

    public static CONTACT_ADMINISTRATOR = "s";
    public static CONTACT_NOTES = "t";
    public static CONTACT_NOTES_MANAGE = "v";
    public static CONTACT_PRIVATE_DATA = "aa";
    public static CONTACT_ACTIVES = "ad";
    public static CONTACT_BAN = "ae";
    public static CONTACT_EDIT_DATA = "ag";
    public static CONTACT_CREATE = "bh";

    public static STATISTICS_ADMINISTRATOR = "ab";
    public static STATISTICS_INBOX = "e";
    public static STATISTICS_INBOX_CONVERSATION = "u";
    public static STATISTICS_INBOX_MESSAGES = "w";
    public static STATISTICS_INBOX_MEMBERS = "x";

    public static HELPCENTER_ADMINISTRATOR = "bn";
    public static HELPCENTER_READING = "bp";
    public static HELPCENTER_WRITING = "bq";
    public static HELPCENTER_PUBLISH = "bs";
    public static HELPCENTER_DELETE = "br";

    public static BLOG_ADMINISTRATOR = "bi";
    public static BLOG_READING = "bj";
    public static BLOG_WRITING = "bk";
    public static BLOG_PUBLISH = "bt";
    public static BLOG_DELETE = "bl";

    public static SUBSCRIPTION_ADMINISTRATOR = "bm";
}