import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {ICommunityAutopilotOutgoingModel} from "../../../models/community/autopilot/ICommunityAutopilotOutgoingModel";
import {ICommunityModel} from "../../../models/community/ICommunityModel";
import {CommunityService} from "../CommunityService";
import {Services} from "../../Services";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {Resources} from "../../../resources/Resources";
import {
    Component
} from "../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {ICommunityAutopilotIncomingModel} from "../../../models/community/autopilot/ICommunityAutopilotIncomingModel";

export class CommunityAutopilotService {

    public static dispose(): void {

    }

    public static init(): void {
    }

    public static async update(communityId: string, communityAutopilot: ICommunityAutopilotIncomingModel, component: Component): Promise<ICommunityModel> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.COMMUNITY_AUTOPILOT_DAYS_MAX, message: Resources.t("words.communityAutopilotDaysMax")}
        ]);
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/autopilot/update/${communityId}`, communityAutopilot);
        if (request.status == HttpStatus.OK) {
            return CommunityService.store(request.data);
        }
        return undefined;
    }
}