import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {AccountService} from "../account/AccountService";
import {IEntityModel} from "../../models/entity/IEntityModel";
import {Models} from "../../models/Models";
import {ContactService} from "../contact/ContactService";
import {Services} from "../Services";
import {IAvatarModel} from "../../models/avatar/IAvatarModel";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {EntityType} from "../../models/entity/types/EntityType";
import {IFileModel} from "../../models/file/IFileModel";
import {IPreviewModel} from "../../models/preview/IPreviewModel";
import {ISiteModel} from "../../models/site/ISiteModel";
import {IAccountModel} from "../../models/account/IAccountModel";
import {FileService} from "../file/FileService";
import {PreviewService} from "../preview/PreviewService";
import {IContactModel} from "../../models/contact/IContactModel";
import {INotificationCountModel} from "../../models/notification/count/INotificationCountModel";
import {NetworkHeaders} from "../../network/types/NetworkHeaders";
import {ITypingModel} from "../../models/entity/ITypingModel";
import {SiteService} from "../site/SiteService";
import {ProductType} from "../../models/product/ProductType";
import {config} from "../../config";
import {ProductName} from "../../models/product/ProductName";
import {
    SedestralStorage
} from "../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralStorage";
import {IOfferProductModel} from "../../models/offer/product/IOfferProductModel";
import {SedestralMemory} from "../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralMemory";

export class EntityService {

    public static typingEvents: ((entity: IEntityModel, content?: string, files?: IFileModel[], previews?: IPreviewModel[]) => void)[] = [];
    public static activeEntities: { product: string, entity: IEntityModel }[];
    public static activeSites: { product: string, site: ISiteModel }[];

    public static activeNotificationsList: { product: string, notifications: INotificationCountModel }[];
    public static activeOfferProducts: { [K in ProductType]: IOfferProductModel } = {};
    public static activeOfferIsFailureToPay: boolean = false;

    public static _activeWebPushTokenValue: string;
    private static _activeWebPushToken: string = "_activeWebPushToken";

    /**
     * get and set
     */

    static get activeNotifications(): INotificationCountModel {
        this.initActives();
        return EntityService.activeNotificationsList.find(value => value.product == config.product)?.notifications;
    }

    static set activeNotifications(notifications: INotificationCountModel) {
        EntityService.activeNotificationsList.push({product: config.product, notifications: notifications});
    }

    static get activeEntity(): IEntityModel {
        this.initActives();
        return EntityService.activeEntities.find(value => value.product == config.product)?.entity;
    }

    static set activeEntity(entity: IEntityModel) {
        EntityService.activeEntities.push({product: config.product, entity});
    }

    static get activeSite(): ISiteModel {
        this.initActives();
        return EntityService.activeSites.find(value => value.product == config.product)?.site;
    }

    static set activeSite(entity: ISiteModel) {
        EntityService.activeSites.push({product: config.product, site: entity});
    }

    static get activeWebPushToken(): string {
        return this._activeWebPushTokenValue;
    }

    static set activeWebPushToken(token: string) {
        this._activeWebPushTokenValue = token;
        SedestralStorage.setItem(this._activeWebPushToken, this._activeWebPushTokenValue);
    }

    static get activeToken(): string {
        return SedestralStorage.getItem(`_activeToken` + config.product + config.environment);
    }

    static set activeToken(token: string) {
        SedestralStorage.setItem(`_activeToken` + config.product + config.environment, token);
    }

    static clearActiveToken() {
        SedestralStorage.removeItem(`_activeToken` + config.product + config.environment);
    }

    static get saveActiveToken(): string {
        return SedestralMemory.getItem(`_saveActiveToken` + config.product + config.environment);
    }

    static set saveActiveToken(token: string) {
        SedestralMemory.setItem(`_saveActiveToken` + config.product + config.environment, token);
    }

    static clearSaveActiveToken() {
        SedestralMemory.removeItem(`_saveActiveToken` + config.product + config.environment);
    }

    static get activeTokenCookie(): string | null {
        this.initActives();
        return SedestralStorage.getCookie(`_activeTokenCookie` + config.environment + config.product);
    }

    static set activeTokenCookie(token: string) {
        this.initActives();
        SedestralStorage.setCookie(`_activeTokenCookie` + config.environment + config.product, token, "." + config.domainParent);
    }

    static clearTokenCookie() {
        SedestralStorage.deleteCookie(`_activeTokenCookie` + config.environment + config.product);
    }

    public static dispose(): void {
        this.typingEvents = [];
    }

    public static initActives(): void {
        if (!EntityService.activeEntities)
            EntityService.activeEntities = [];

        if (!EntityService.activeSites)
            EntityService.activeSites = [];

        if (!EntityService.activeNotificationsList)
            EntityService.activeNotificationsList = [];
    }

    public static init(): void {
        this.initActives();

        Services.beforeInit(this);
        this._activeWebPushTokenValue = SedestralStorage.getItem(this._activeWebPushToken);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ENTITY_UPDATE, (data) => {
            let entity: IEntityModel = data;
            if (Models.isAccountModel(entity)) {
                AccountService.store(entity as IAccountModel);
            } else if (Models.isContactModel(entity)) {
                ContactService.store(entity as IContactModel);
            }
        });


        //TODO remove typing only if typing is active
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ENTITY_TYPING, (data: ITypingModel) => {
            // console.log(data)
            let entity = this.store(data.entity as IEntityModel, data.entityType);

            data.files = FileService.storeAll(data.files);
            data.previews = PreviewService.storeAll(data.previews);

            this.typingEvents.forEach(value => value(entity, data.content, data.files, data.previews));
        });

        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["EntityService"] = this;
        }
    }

    /**
     * http
     */

    public static async updateAvatar(entityId: string, entityType: EntityType, files: File[], component?: INetworkComponent): Promise<IEntityModel> {
        let request = await Network.postFormData(ProductType.PANEL, `/entities/avatar/update`, {
            files: files,
            entityId: entityId,
            entityType: entityType
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    /**
     * get
     */

    public static getEntity(type: EntityType, id: string): IEntityModel {
        if (type == EntityType.ACCOUNT) {
            return AccountService.accounts.filter(value => value.id == id)[0];
        } else {
            return ContactService.contacts.filter(value => value.id == id)[0];
        }
    }

    public static getType(entity: IEntityModel): EntityType {
        return Models.isContactModel(entity) ? EntityType.CONTACT : EntityType.ACCOUNT;
    }

    /**
     * websocket
     */

    public static typing(conversationId: string, typing: boolean, content?: string, files?: IFileModel[], previews?: IPreviewModel[]) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.ENTITY_TYPING, {
            conversationId: conversationId,
            typing: typing,
            content: content,
            filesIds: files ? files.map(value => value.id) : undefined,
            previewsIds: previews ? previews.map(value => value.id) : undefined
        });
    }

    /**
     * headers
     */

    public static headersToken(headers, saveCookie: boolean = true) {
        if (headers !== undefined && headers[NetworkHeaders.SESSION_HEADER] != undefined) {
            EntityService.activeToken = headers[NetworkHeaders.SESSION_HEADER];

            if (saveCookie) {
                EntityService.activeTokenCookie = headers[NetworkHeaders.SESSION_HEADER];
            }

        }
    }

    /**
     * virtual
     */

    public static virtualGenerate(avatar: IAvatarModel): IEntityModel {
        return {
            work: "",
            avatar: avatar,
            siteId: "virtualId",
            name: "name",
            id: "virtualId",
            email: "email",
            active: true,
            avatarId: avatar.id,
            twoFactor: false,
        }
    }

    /**
     * events
     */

    public static onTyping(func: ((entity: IEntityModel, content?: string, files?: IFileModel[], previews?: IPreviewModel[]) => void), component: INetworkComponent) {
        this.typingEvents.push(func);
        component.onRemove(() => this.typingEvents.splice(this.typingEvents.indexOf(func), 1));
    }

    /**
     * store
     */

    public static storeContext(entity: IEntityModel, site: ISiteModel) {
        this.activeEntity = this.store(entity, entity.type);
        this.activeSite = SiteService.store(site);
    }

    public static storeAll(entities: IEntityModel[], type?: EntityType): IEntityModel[] {
        for (let key in entities)
            entities[key] = this.store(entities[key], type);

        return Services.storeAll(entities);
    }

    public static store(entity: IEntityModel, type?: EntityType): IEntityModel {
        if (type == undefined) {
            if (Models.isContactModel(entity))
                // @ts-ignore
            {
                entity = ContactService.store(entity as IContactModel);
            } else if (Models.isAccountModel(entity))
                // @ts-ignore
            {
                entity = AccountService.store(entity);
            }
        } else {

            if (type == EntityType.ACCOUNT) {
                // @ts-ignore
                entity = AccountService.store(entity);
            } else {
                // @ts-ignore
                entity = ContactService.store(entity);
            }
        }


        return entity;
    }

}