import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {ICommunityCategoryModel} from "../../../models/community/category/ICommunityCategoryModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {ICommunityCategoryViewModel} from "../../../models/community/category/ICommunityCategoryViewModel";
import {CommunityArticleService} from "../articles/CommunityArticleService";
import {ProductType} from "../../../models/product/ProductType";
import {ICommunityCategoryCreateOutgoing} from "../../../models/community/category/ICommunityCategoryCreateOutgoing";
import {CommunityFilesService} from "../files/CommunityFilesService";
import {Resources} from "../../../resources/Resources";
import {SedestralSsr} from "../../../sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {config} from "../../../config";
import {ProductName} from "../../../models/product/ProductName";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {WebSocketCommunityEventName} from "../../../network/socket/names/WebSocketCommunityEventName";
import {arrayRemove} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";
import {randomString} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/RandomString";
import {
    txtFormatLink
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtFormatLink";

export class CommunityCategoryService {
    public static categories: ICommunityCategoryModel[] = observable([]);
    public static createEvents: ((category: ICommunityCategoryModel) => void)[] = [];
    public static deleteEvents: ((category: ICommunityCategoryModel) => void)[] = [];
    public static updateEvents: ((category: ICommunityCategoryModel) => void)[] = [];

    public static dispose(): void {
        this.createEvents = [];
        this.deleteEvents = [];
        this.updateEvents = [];
    }

    public static init(): void {
        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["CommunityCategoryService"] = this;
        }

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CATEGORY_CREATE, (data) => {
            this.createEvents.forEach(value => value(this.store(data)))
        });
        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CATEGORY_UPDATE, (data) => {
            this.updateEvents.forEach(value => value(this.store(data)))
        });
        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CATEGORY_DELETE, (data) => {
            this.deleteEvents.forEach(value => value(this.store(data)))
        });
    }


    /**
     * websocket
     */

    static onCreateEvent(component: INetworkComponent, func: (category: ICommunityCategoryModel) => void) {
        this.createEvents.push(func);
        component.onRemove(() => arrayRemove(this.createEvents, func));
    }

    static onDeleteEvent(component: INetworkComponent, func: (category: ICommunityCategoryModel) => void) {
        this.deleteEvents.push(func);
        component.onRemove(() => arrayRemove(this.deleteEvents, func));
    }

    static onUpdateEvent(component: INetworkComponent, func: (category: ICommunityCategoryModel) => void) {
        this.updateEvents.push(func);
        component.onRemove(() => arrayRemove(this.updateEvents, func));
    }

    /**
     * HTTP
     */
    public static async findCategories(communityId: string, language: string, component?: INetworkComponent): Promise<ICommunityCategoryModel[]> {
        if (SedestralSsr.hasSsr() && this.categories) {
            return Promise.resolve(this.categories);
        }

        let request = await Network.get(ProductType.COMMUNITY, `/community/categories/${communityId}/${language}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static async delete(categoryId: string, component?: INetworkComponent): Promise<ICommunityCategoryModel[]> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.SIZE_MIN,
                message: Resources.t("words.minCategoryError")
            },
        ]);

        let request = await Network.delete(ProductType.COMMUNITY, `/community/category/${categoryId}`, component);
        if (request.status == HttpStatus.OK) {
            let category = this.categories.find(value => value.id == categoryId);
            if (category) {
                this.unStore(category);
            }
        }

        return undefined;
    }

    public static async create(category: ICommunityCategoryCreateOutgoing, component?: INetworkComponent): Promise<ICommunityCategoryModel> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.ROUTE_EXIST,
                message: Resources.t("words.communityCategoryRouteExist")
            },
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/category`, category, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async update(id: string, category: ICommunityCategoryCreateOutgoing, component?: INetworkComponent): Promise<ICommunityCategoryModel> {
        let request = await Network.putJson(ProductType.COMMUNITY, `/community/category/${id}`, category, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async alternates(id: string, component?: INetworkComponent): Promise<ICommunityCategoryModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/category/alternates/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    /**
     * logic
     */

    public static async createAlternate(language: string, category: ICommunityCategoryModel): Promise<ICommunityCategoryModel> {
        let title = `(${language.toUpperCase()}) ${category.title}`;
        return await CommunityCategoryService.create({
            communityId: category.communityId,
            language: language,
            title: title,
            route: randomString(10, false) + txtFormatLink(title),
            imageFileId: category.imageFile?.id,
            description: `(${language.toUpperCase()}) ${category.description}`,
            alternateCategoryId: category.id
        });
    }

    /**
     * store
     */


    public static storeView(view: ICommunityCategoryViewModel): ICommunityCategoryViewModel {
        view.articles = CommunityArticleService.storeArticles(view.articles);
        view.category = this.store(view.category);

        return view;
    }

    public static storeAll(categories: ICommunityCategoryModel[]): ICommunityCategoryModel[] {
        for (let key in categories) {
            categories[key] = this.store(categories[key]);
        }
        return Services.storeAll(categories);
    }

    public static store(category: ICommunityCategoryModel): ICommunityCategoryModel {
        if (category.imageFile) {
            category.imageFile = CommunityFilesService.store(category.imageFile);
        }

        return Services.store("id", this.categories, category);
    }

    public static unStore(category: ICommunityCategoryModel): void {
        Services.unStore("id", this.categories, category);
    }
}