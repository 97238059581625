import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {SedestralInterface} from "../../sedestral-interface-component/interface/SedestralInterface";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";

import {elementOffsets} from "../../sedestral-interface-component/utilities/ElementOffsets";

export class VisualFloatingComponent extends Component {
    private time: number;
    private firstPlace: boolean;

    constructor(component: Component) {
        super();
        this._component = component;
        this.time = performance.now();
        this.firstPlace = true;

        this.putListener(window, "resize", () => this.replace());
    }

    private _component: Component;

    /**
     * get and set
     */
    get component(): Component {
        return this._component;
    }

    set component(value: Component) {
        this._component = value;
    }

    /**
     * place and position
     */
    place() {
        if (this.component.getOffsets().top > (window.innerHeight / 2)) {
            this.setStyle(`transition-duration:0;left:${this.calcMiddle()}px;top:unset;bottom:${this.calcOutsideBottom()}px;`);
        } else {
            this.setStyle(`transition-duration:0;left:${this.calcMiddle()}px;top:${this.calcTop()}px;`);
        }

        if (this.outsideBottom()) {
            this.setStyle(`top:unset;bottom:${this.calcOutsideBottom()}px`);
        }

        if (this.outsideRight()) {
            this.setStyle(`left:unset;right:${this.calcOutsideRight()}px;`);
        }

        if (this.outsideLeft()) {
            this.setStyle(`right:unset;left:${this.calcOutsideLeft()}px;`);
        }

        if (this.firstPlace) {
            this.firstPlace = false;
            SedestralMachine.requestFrame()(() => {
                if (this.outsideBottom() || this.outsideRight() || this.outsideLeft()) {
                    this.place();
                }
            });
        }
    }

    placeTop() {
        this.setStyle(`top:unset;bottom:${this.calcOutsideBottom()}px`);

        if (this.outsideTop()) {
            this.setStyle(`bottom:unset;top:${this.calcTop()}px;`);
        }
    }

    placeRight() {
        this.setStyle(`left:unset;right:${this.calcOutsideRight()}px;`);

        if (this.outsideLeft()) {
            this.setStyle(`right:unset;left:${this.calcOutsideLeft()}px;`);
        }
    }

    replace() {
        let timeNow = performance.now();
        if ((timeNow - 10) > this.time) {
            this.time = timeNow;
            this.setStyle(`transition-duration: 0s;`);
            this.place();
        }
    }

    /**
     * calc position
     */

    //place element to middle
    calcMiddle(): number {
        return (this._component.getOffsets().left + (this._component.getWidth() / 2)) - (this.getWidth() / 2);
    }

    // place to left element
    calcLeft(): number {
        return this._component.getOffsets().left;
    }

    // place if limit left
    calcOutsideLeft(): number {
        return 10;
    }

    //place on bottom of element
    calcTop(): number {
        return this._component.getOffsets().top + this._component.getHeight() + 5;
    }

    //place on top of element if bottom is limit
    calcOutsideBottom(): number {
        return this.component.getOffsets().bottom + this._component.getHeight() + 4;
    }

    // place if limit right
    calcOutsideRight(): number {
        return 10;
    }

    /**
     * calc if element is outside screen
     */

    outsideLeft(): boolean {
        return elementOffsets(this.getHTMLElement()).left < 10;
    }

    outsideBottom(): boolean {
        return (this.getHTMLElement().getBoundingClientRect().height + this.getOffsets().top) > window.innerHeight;
    }

    outsideTop(): boolean {
        return this.getOffsets().top < 0;
    }

    outsideRight(): boolean {
        return (this.getWidth() + this.getOffsets().left) > window.innerWidth;
    }

    /**
     * append and remove
     */
    dispose() {
        if (!this.isNull()) {
            this.remove();
        }
        this.onDispose();

    }

    create(): void {
        SedestralInterface.main.render(this);
        SedestralInterface.main.removeChildren(this);
    }

    /**
     *override
     */

    onDispose() {

    }

    beforeDispose() {

    }
}