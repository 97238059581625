import {SedestralMachine} from "./machine/SedestralMachine";
import {SedestralInterface} from "./interface/SedestralInterface";
import {SedestralMemory} from "./memory/SedestralMemory";
import {SedestralDebugger} from "./debugger/SedestralDebugger";
import {SedestralSsr} from "./ssr/SedestralSsr";
import {SedestralStorage} from "./memory/SedestralStorage";

export class SedestralLogic {

    public static async init(): Promise<void> {
        await Promise.all([
            SedestralDebugger.init(),
            SedestralMachine.init(),
            SedestralInterface.init(),
            SedestralMemory.init(),
            SedestralStorage.init(),
            SedestralSsr.init()
        ]);
    }

    public static ready(callback): void {
        if (document.readyState !== 'loading') {
            callback();
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', callback);
        } else {
            // @ts-ignore
            document.attachEvent('onreadystatechange', () => {
                if (document.readyState == 'complete') {
                    callback();
                }
            });
        }
    }
}