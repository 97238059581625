import {IChannelModel} from "../../models/channel/IChannelModel";
import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";

export class ChannelService {
    public static channels: IChannelModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.channels = observable([]);
    }

    public static findById(id: string): IChannelModel {
        return ChannelService.channels.find(channel => channel.id === id);
    }

    public static findAllByFiter(): IChannelModel[] {
        return ChannelService.channels.filter(channel => channel.inboxProperties);
    }

    public static storeAll(channels: IChannelModel[]): IChannelModel[] {
        return channels.map(value => this.store(value));
    }

    public static store(channel: IChannelModel): IChannelModel {
        return Services.store("id", this.channels, channel);
    }
}