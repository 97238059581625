import {Component} from "../Component";
import {SedestralInterface} from "../../SedestralInterface";
import * as s from "./../../../../sedestral-interface-sass/imports/tooltip.scss";
import {elementOffsets} from "../../../utilities/ElementOffsets";

export class ComponentTooltip {

    public component: Component;
    public tooltipComponent: Component;
    public hovered: boolean;
    public hoverDelay: number;
    public leaveDelay: number;
    private time: number;
    private preference?: string;
    private noAnimation?: boolean;

    constructor(component: Component, template: any, preference?: string, noAnimation?: boolean) {
        this.component = component;
        this.hoverDelay = 0;
        this.leaveDelay = 0;
        this.time = performance.now();
        this.noAnimation = noAnimation;
        this.preference = preference;

        this.tooltipComponent = new Component();
        //language=HTML
        this.tooltipComponent.template = `
            <div class="${s.tooltip}">
                <div class="${s.content}">
                    ${typeof template == "string" ? template : this.tooltipComponent.draw(template())}
                </div>
                <div class="${s.arrow}"></div>
            </div>
        `;

        SedestralInterface.main.render(this.tooltipComponent);
        SedestralInterface.main.removeChildren(this.tooltipComponent);

        this.component.onLeave(async () => this.dispose());
        this.tooltipComponent.putListener(window, "resize", () => this.replace());
        this.tooltipComponent.putListener(window, "scroll", () => this.replace(), true);

        this.place();
    }

    place(replace?: boolean) {
        switch (this.preference) {
            case "left":
                this.placeLeft();
                break;
            case "leftTop":
                this.placeLeftTop();
                break;
            case "rightTop":
                this.placeRightTop();
                break;
            case "right":
                this.placeRight();
                break;
            case "bottom":
                this.placeBottom();
                break;
            default:
                this.placeTop();
                break;
        }

        let tooltipOffsets = elementOffsets(this.tooltipComponent.element);
        if (this.isOutside(tooltipOffsets)) {
            let places = ["placeTop", "placeRight", "placeBottom", "placeLeft"];
            if (this.preference == "leftTop") {
                places = ["placeTop", "placeLeft", "placeBottom", "placeRight"];
            }


            for (let placeFunc of places) {
                this[placeFunc]();
                tooltipOffsets = elementOffsets(this.tooltipComponent.element);
                if (!this.isOutside(tooltipOffsets)) {
                    break;
                }
            }
        }

        if (!this.noAnimation) {
            if (!replace) {
                this.tooltipComponent.setStyle(`opacity:0;transform:scale(0.9);`);
                this.tooltipComponent.translate({delay: 40, opacity: 1, scale: 1});
            } else {
                this.tooltipComponent.setStyle(`transition-duration: 0s;`);
            }
        } else {
            this.tooltipComponent.setStyle(`transition-duration: 0s;opacity:1;transform:scale(1);`);
        }
    }

    replace() {
        let timeNow = performance.now();
        if ((timeNow - 1) > this.time) {
            this.time = timeNow;
            this.place(true);
        }
    }

    placeTop() {
        let offsets = elementOffsets(this.component.element);
        let left = offsets.left + ((this.component.getWidth() / 2) - (this.tooltipComponent.getWidth() / 2));
        let top = offsets.top - this.tooltipComponent.getHeight() - 8;
        this.tooltipComponent.setStyle(`left:${left}px;top:${top}px;`);
        this.placeArrowTop();
    }

    placeBottom() {
        let offsets = elementOffsets(this.component.element);
        let left = offsets.left + ((this.component.getWidth() / 2) - (this.tooltipComponent.getWidth() / 2));
        let top = offsets.top + this.component.getHeight() + 8;
        this.tooltipComponent.setStyle(`left:${left}px;top:${top}px;`);
        this.placeArrowBottom();
    }

    placeLeftTop() {
        let offsets = elementOffsets(this.component.element);
        let left = offsets.left;
        let top = offsets.top - this.tooltipComponent.getHeight() - 8;
        this.tooltipComponent.setStyle(`left:${left}px;top:${top}px;`);
        this.placeArrowLeftTop();
    }

    placeRightTop() {
        let offsets = elementOffsets(this.component.element);
        let left = offsets.left + this.component.getWidth() - this.tooltipComponent.getWidth();
        let top = offsets.top - this.tooltipComponent.getHeight() - 8;
        this.tooltipComponent.setStyle(`left:${left}px;top:${top}px;`);
        this.placeArrowRightTop();
    }

    placeLeft() {
        let offsets = elementOffsets(this.component.element);
        let left = offsets.left - this.tooltipComponent.getWidth() - 8;
        let top = offsets.top + (this.component.getHeight() / 2) - this.tooltipComponent.getHeight() / 2;
        this.tooltipComponent.setStyle(`left:${left}px;top:${top}px;`);
        this.placeArrowLeft();
    }

    placeRight() {
        let offsets = elementOffsets(this.component.element);
        let left = offsets.left + this.component.getWidth() + 8;
        let top = offsets.top + (this.component.getHeight() / 2) - this.tooltipComponent.getHeight() / 2;
        this.tooltipComponent.setStyle(`left:${left}px;top:${top}px;`);
        this.placeArrowRight();
    }

    placeArrowLeft() {
        let offsets = elementOffsets(this.component.element);
        let tooltipOffsets = elementOffsets(this.tooltipComponent.element);
        this.tooltipComponent.el(s.arrow).setStyle(`right:-5px;left:unset;bottom:unset;top:${(offsets.top - tooltipOffsets.top) + ((this.component.getHeight() / 2) - 5)}px`);
    }

    placeArrowLeftTop() {
        let left = (this.tooltipComponent.getWidth() / 2) - 5;
        this.tooltipComponent.el(s.arrow).setStyle(`bottom:-5px;right:unset;top:unset;left:${left > 15 ? 15 : left}px`);
    }

    placeArrowRightTop() {
        let left = (this.tooltipComponent.getWidth() / 2) - 5;
        this.tooltipComponent.el(s.arrow).setStyle(`bottom:-5px;right:unset;top:unset;right:${left > 15 ? 15 : left}px`);
    }

    placeArrowRight() {
        this.placeArrowLeft();
        this.tooltipComponent.el(s.arrow).setStyle(`left:-5px;right:unset;`);
    }

    placeArrowTop() {
        let offsets = elementOffsets(this.component.element);
        let tooltipOffsets = elementOffsets(this.tooltipComponent.element);
        this.tooltipComponent.el(s.arrow).setStyle(`bottom:-5px;right:unset;top:unset;left:${(offsets.left - tooltipOffsets.left) + ((this.component.getWidth() / 2) - 5)}px`);
    }

    placeArrowBottom() {
        this.placeArrowTop();
        this.tooltipComponent.el(s.arrow).setStyle(`bottom:unset;top:-5px`);
    }

    isOutside(offsets) {
        return offsets.top < -1 || offsets.left < -1 || offsets.right < -1 || offsets.bottom < -1;


    }

    async dispose() {
        if (!this.noAnimation) {
            await this.tooltipComponent.translate({delay: 40, opacity: 0, scale: 0.9});
        }

        if (!this.tooltipComponent.isNull()) {
            this.tooltipComponent.remove();
        }
    }
}