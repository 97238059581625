/**
 * Concat two json
 */
export function jsonConcat(o1, o2) {
    if (!o2) {
        return o1;
    }

    for (let key in o2)
        o1[key] = o2[key];

    return o1;
}