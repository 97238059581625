export class MachineListener {
    public constructor(element: EventTarget, name: string, callback: any, options?: boolean) {
        this._element = element;
        this._name = name;
        this._callback = callback;
        this._options = options;
    }

    private _element: EventTarget;

    public get element(): EventTarget {
        return this._element;
    }

    public set element(value: EventTarget) {
        this._element = value;
    }

    private _name: string;

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    private _callback: ((e?) => void);

    public get callback(): ((e) => void) {
        return this._callback;
    }

    public set callback(value: ((e) => void)) {
        this._callback = value;
    }

    private _options?: boolean;

    get options(): boolean {
        return this._options;
    }

    set options(value: boolean) {
        this._options = value;
    }
}