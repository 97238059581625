import {BlogPageCategoryComponent} from "../category/BlogPageCategoryComponent";
import {
    EmptyBasicComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class BlogPage404Component extends BlogPageCategoryComponent {


    constructor() {
        super();
    }

    /**
     * render
     */

    async renderBodyContent(): Promise<void> {
        this.bodyContainer.render(new EmptyBasicComponent("👀", Resources.t("words.theRequestedPageDoesNotExistOrHasBeenRemoved")));
    }
}