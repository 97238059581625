import {observable} from "@nx-js/observer-util";
import {AccountService} from "../../../account/AccountService";
import {ContactService} from "../../../contact/ContactService";
import {Services} from "../../../Services";
import {
    IInboxConversationParticipantModel
} from "../../../../models/inbox/conversation/participant/IInboxConversationParticipantModel";
import {INetworkComponent} from "../../../../network/types/INetworkComponent";
import {Network} from "../../../../network/Network";
import {HttpStatus} from "../../../../network/status/HttpStatus";
import {IAvatarModel} from "../../../../models/avatar/IAvatarModel";
import {EntityService} from "../../../entity/EntityService";
import {EntityType} from "../../../../models/entity/types/EntityType";
import {AssignableType} from "../../../../models/assignment/AssignableType";
import {AssignableState} from "../../../../models/assignment/AssignableState";
import {ProductType} from "../../../../models/product/ProductType";

export class InboxConversationParticipantService {
    public static participants: IInboxConversationParticipantModel[] = observable([]);

    public static dispose(): void {
        this.participants = observable([]);
    }

    public static init(): void {

    }

    /**
     * load
     */
    public static async findByConversationId(conversationId: string, component?: INetworkComponent): Promise<IInboxConversationParticipantModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/conversations/participants/${conversationId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    /**
     * create
     */

    public static async create(conversationsIds: string[], participantId: string, message: string, assignableType: AssignableType, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/participants",
            {
                conversationsIds: conversationsIds,
                participantId: participantId,
                message: message,
                type: assignableType
            }, component);

        return request.status == HttpStatus.OK;


    }

    /**
     * delete
     */

    public static async delete(participantsIds: string[], component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/participants/delete",
            {
                participantsIds: participantsIds
            }, component);
        return request.status == HttpStatus.OK;
    }

    /**
     * virtual
     */

    public static virtualGenerate(avatar: IAvatarModel, type: EntityType): IInboxConversationParticipantModel {
        return {
            lastReadTime: 0,
            conversationMuted: false,
            participant: EntityService.virtualGenerate(avatar),
            participantId: "virtualId",
            participantType: type,
            lastMessageTime: 0,
            state: AssignableState.JOINED,
            id: "virtualId",
            message: "message",
            owner: true,
            unreadCount: 0,
            conversationId: "virtualId"
        }
    }

    /**
     * store
     */
    public static storeAll(participants: IInboxConversationParticipantModel[]): IInboxConversationParticipantModel[] {
        for (let key in participants)
            participants[key] = this.store(participants[key]);

        return Services.storeAll(participants);
    }

    public static store(participant: IInboxConversationParticipantModel): IInboxConversationParticipantModel {
        if (participant.participantType == EntityType.ACCOUNT) {
            // @ts-ignore
            participant.participant = AccountService.store(participant.participant);
        }

        if (participant.participantType == EntityType.CONTACT) {
            // @ts-ignore
            participant.participant = ContactService.store(participant.participant);
        }

        participant = Services.store("id", this.participants, participant);
        return participant;
    }
}