/**
 * Retourne la liste des parents des éléments
 */
export function elementParents(element: Node): Array<Node> {
    let nodes = [];
    nodes.push(element);
    while (element.parentNode) {
        nodes.unshift(element.parentNode);
        element = element.parentNode;
    }
    return nodes;
}