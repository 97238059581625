import {SedestralRouter} from "../SedestralRouter";
import {StaticComponent} from "./component/StaticComponent";

export class SedestralStaticRouter {
    constructor(router: SedestralRouter) {
        this._components = {};
    }

    private _components: any;

    /**
     * get and set
     */


    get components(): any {
        return this._components;
    }

    set components(value: any) {
        this._components = value;
    }

    register(name: string, component: StaticComponent) {
        this._components[name] = component;
    }

    onHash(): void {
        for (let key in this._components) {
            if (this._components.hasOwnProperty(key)) {
                let component = this._components[key];
                component.onHash();
            }
        }
    }
}