import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-read-details.scss";
import {ICommunityArticleModel} from "../../../../../../../../models/community/article/ICommunityArticleModel";
import {ShareComponent} from "../../../../../../../panel/components/components/share/ShareComponent";
import {Resources} from "../../../../../../../../resources/Resources";
import {ICommunityLiveModel} from "../../../../../../../../models/community/ICommunityLiveModel";
import {
    HexFilterConverter
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/colors/types/HexFilterConverter";
import {
    dateSecondsToIso8601
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateSecondsToISO8601";
import {ProductType} from "../../../../../../../../models/product/ProductType";

export class BlogReadDetailsComponent extends Component {

    public live: ICommunityLiveModel;

    public shareComponent: ShareComponent;

    constructor(live: ICommunityLiveModel, article: ICommunityArticleModel) {
        super();
        this.live = live;
        this.shareComponent = new ShareComponent(article.title, document.URL);

        //language=HTML
        this.template = `
            <div class="${s.componentBlogReadDetails}">
                <a aria-label="${Resources.t("words.backHome")}" href="/home">
                    <div class="${s.entry} ${s.clickable}">
                        <div class="${s.icon} ${s.back}"></div>
                        <div>${Resources.t("words.allArticles")}</div>
                    </div>
                </a>
                <div class="${s.entry}">
                    <div class="${s.icon} ${s.read}"></div>
                    <div>${Math.round(article.readingSeconds / 60)} ${Resources.t("words.timeToRead")}</div>
                    <meta itemprop="timeRequired" content="${dateSecondsToIso8601(article.readingSeconds)}">
                </div>
                <div class="${s.entry}">
                    ${this.draw(this.shareComponent)}
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();

        let readIcon = this.el(s.read);
        let backIcon = this.el(s.back);

        this.onReactiveObserve(() => {
            this.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor};`);
            backIcon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor));
            readIcon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor));

            this.shareComponent.networkIcons.forEach(icon => {
                icon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor));
            });
        });
    }

}