@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";

.visualTooltip {
  padding: 15px;
  z-index: 99999999;
  height: fit-content;
  position: fixed;

  @include boxs.sur;
}

.visualScreen {
  top: 0;
  left: 0;
  z-index: 99999;
  position: fixed;
  width: 100%;
  height: 100%;
  cursor: pointer;
}