import {
    PageComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-router/pages/types/PageComponent";
import * as s from "./blog-page.scss";
import {BlogPage404Component} from "../components/pages/404/BlogPage404Component";

export class Blog404Page extends PageComponent {

    constructor() {
        super();
        this.title = "404";
        this.status.online = undefined;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${s.pageBlogPage}">
                ${this.draw(new BlogPage404Component())}
            </div>
        `;
    }

    onHash(): void {
    }
}