export class SedestralMemory {

    private static _items: {};

    public static async init(): Promise<void> {
        this._items = [];
    }

    public static setItem(key: string, value: any): void {
        this._items[key] = value;
    }

    public static getItem(key: string): string | null {
        return this._items[key] ? this._items[key] : null;
    }

    public static removeItem(key: string): void {
        delete this._items[key];
    }
}