@use "../../../../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentBlogReadDetails {
  a {
    color: unset;
  }

  .entry {
    border: 1px solid var(--grey-sur);
    display: flex;
    padding: 15px;
    border-radius: 10px;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;

    &.clickable {
      &:hover {
        text-decoration: underline;
      }
    }

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 10px;

      &.read {
        @include icons.ico("black-lineal-time");
      }

      &.back {
        @include icons.ico("black-left-arrow");
      }
    }
  }

  @media screen and (max-width: 950px) {
    .entry {
      display: none;
    }
  }
}