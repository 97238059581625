import {Resources} from "../../../resources/Resources";

export function join(strings: string[]): string {
    const filteredStrings = strings.filter(str => str);
    if (filteredStrings.length > 1) {
        const allButLast = filteredStrings.slice(0, -1).join(", ");
        const lastAuthor = filteredStrings[filteredStrings.length - 1];
        return `${allButLast} ${Resources.t("words.coWriteAnd")} ${lastAuthor}`;
    } else {
        return filteredStrings[0] || "";
    }
}
