@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.globalLoaderObjectBlock {
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: var(--grey-sur);
  position: relative;
  border-radius: 15px;

  &.shine {
    @include animations.shine;
  }

}