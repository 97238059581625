/**
 * retourne tous les enfants d'un élément ainsi que lui même
 */

export function elementChildrens(element: HTMLElement): HTMLElement[] {
    let elements = [];
    let childs = element.getElementsByTagName("*");
    for (let child of childs) {
        elements.push(child);
    }
    elements.push(element);
    return elements;
}