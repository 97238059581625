import {PageComponent} from "../types/PageComponent";

export class SedestralSsrPage extends PageComponent {

    constructor() {
        super();

        this.title = "Sedestral SSR";
        this.status.online = false;
    }

    init(): void {
        //language=HTML
        console.log('finish')
        this.template = `
            <div class="ssrPageLoadReady"></div>`;
    }

    onHash(): void {

    }
}