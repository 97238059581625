import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IRoleModel} from "../../models/roles/IRoleModel";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {IRoleAccountModel} from "../../models/roles/account/IRoleAccountModel";
import {RolePermissionsType} from "../../models/roles/types/RolePermissionsType";
import {IAccountModel} from "../../models/account/IAccountModel";
import {ISiteModel} from "../../models/site/ISiteModel";
import {EntityService} from "../entity/EntityService";
import {IAvatarModel} from "../../models/avatar/IAvatarModel";
import {AvatarType} from "../../models/avatar/AvatarType";
import {SiteChannelService} from "../site/channel/SiteChannelService";
import {ProductType} from "../../models/product/ProductType";

export class RoleService {
    public static roles: IRoleModel[] = observable([]);
    public static rolesChange: ((role: IRoleModel) => void)[] = [];

    public static dispose(): void {
        this.roles = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ROLE_UPDATE, (data) => {
            this.store(data);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ROLE_CREATE, (data) => {
            let role = this.store(data);
            this.roleChange(role);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ROLE_DELETE, (data) => {
            let role = this.store(data);
            this.unStore(role);
            this.roleChange(role);
        });
    }

    /**
     * http
     */
    public static async findBySite(siteId: string, component?: INetworkComponent): Promise<IRoleModel[]> {
        let request = await Network.get(ProductType.PANEL, `/roles/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async updateRanks(siteId: string, orders: any, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.postJson(ProductType.PANEL, "/roles/update/ranks",
            {
                siteId: siteId,
                orders: orders
            }, component);

        return request.status == HttpStatus.OK;
    }

    public static async updatePermissions(roleId: string, permissions: string[], siteChannelsIdsInbox: string[], siteChannelsIdsPublisher: string[], component?: INetworkComponent): Promise<IRoleModel> {
        let request = await Network.post(ProductType.PANEL, "/roles/update/permissions",
            {
                roleId: roleId,
                permissions: permissions,
                siteChannelsIdsInbox: siteChannelsIdsInbox,
                siteChannelsIdsPublisher: siteChannelsIdsPublisher
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static getAll(): IRoleModel[] {
        return this.roles;
    }

    public static async update(roleId: string, name: string, color: string, component?: INetworkComponent): Promise<IRoleModel> {
        let request = await Network.post(ProductType.PANEL, "/roles/update",
            {
                roleId: roleId,
                name: name,
                color: color
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async create(siteId: string, name: string, color: string, model: number, component?: INetworkComponent): Promise<IRoleModel> {
        let request = await Network.post(ProductType.PANEL, "/roles",
            {
                siteId: siteId,
                name: name,
                color: color,
                model: model
            }, component);

        if (request.status == HttpStatus.OK) {
            let role = this.store(request.data);
            this.roleChange(role);
            return role;
        }


        return undefined;
    }

    public static async delete(roleId: string, component?: INetworkComponent): Promise<boolean> {
        let role = this.roles.filter(role => role.id == roleId)[0];
        this.unStore(role);
        let request = await Network.delete(ProductType.PANEL, `/roles/${roleId}`, component);
        if (request.status == HttpStatus.OK) {
            this.roleChange(role);
            return true;
        }

        return false;
    }

    public static async duplicate(roleId: string, component?: INetworkComponent): Promise<IRoleModel> {
        let request = await Network.post(ProductType.PANEL, "/roles/duplicate",
            {
                roleId: roleId
            }, component);

        if (request.status == HttpStatus.OK) {
            let role = this.store(request.data);
            this.roleChange(role);
            return role;
        }

        return undefined;
    }


    /**
     * utilities
     */

    public static maxRank(account: IAccountModel): number {
        let maxRank = 0;
        for (let role of account.rolesAccount.map(roleAccount => roleAccount.role)) {
            if (role.rank > maxRank) {
                maxRank = role.rank;
            }
        }

        return maxRank;
    }

    public static greater(account: IAccountModel) {
        let myAccount = EntityService.activeEntity as IAccountModel;
        let mySite = EntityService.activeSite;

        if (myAccount.id == mySite.accountId) {
            return true;
        }

        if (account.id == mySite.accountId) {
            return false;
        }

        if (myAccount.rolesAccount == undefined || myAccount.rolesAccount.length == 0) {
            return false;
        }

        if (account.rolesAccount == undefined || account.rolesAccount.length == 0) {
            return true;
        }

        return this.maxRank(myAccount) > this.maxRank(account);
    }

    public static can(...permissions: string[]): boolean {
        if (EntityService.activeSite == undefined) {
            return false;
        }

        return this.canAccess(EntityService.activeEntity as IAccountModel, ...permissions);
    }

    public static updateRole(role: IRoleModel) {
        return this.canUpdateRole(EntityService.activeEntity as IAccountModel, EntityService.activeSite, role);
    }

    public static hasPermission(rolesAccounts: IRoleAccountModel[], ...permissions: string[]): boolean {
        for (let permission of permissions) {
            if (rolesAccounts.filter(roleAccount => roleAccount.role.permissions.includes(permission)).length > 0) {
                return true;
            }
        }
        return false;
    }

    public static canAccess(account: IAccountModel, ...permissions: string[]): boolean {
        if (account.rolesAccount == undefined) {
            return false;
        }

        if (this.hasPermission(account.rolesAccount, RolePermissionsType.ADMINISTRATOR)) {
            return true;
        }

        return this.hasPermission(account.rolesAccount, ...permissions);
    }

    public static canUpdateRole(account: IAccountModel, site: ISiteModel, role: IRoleModel): boolean {
        if (role.id == site.superRoleId) {
            return false;
        }

        if (account.id == site.accountId) {
            return true;
        }

        return this.maxRank(account) > role.rank;
    }


    /**
     * avatar
     */

    public static avatar(role: IRoleModel): IAvatarModel {
        return {
            type: AvatarType.ROLE,
            color: {
                color: role.color
            }
        }
    }


    /**
     * events
     */

    public static roleChange(role: IRoleModel): void {
        this.rolesChange.forEach(value => value(role));
    }

    public static onRoleChange(func: ((role: IRoleModel) => void), component: INetworkComponent) {
        this.rolesChange.push(func);
        component.onRemove(() => this.rolesChange.splice(this.rolesChange.indexOf(func), 1));
    }

    /**
     * store
     */
    public static storeAll(roles: IRoleModel[]): IRoleModel[] {
        for (let key in roles) {
            roles[key] = this.store(roles[key]);
        }
        return Services.storeAll(roles);
    }

    public static store(role: IRoleModel): IRoleModel {
        role.siteChannelsInbox = SiteChannelService.storeAll(role.siteChannelsInbox);
        role.siteChannelsPublisher = SiteChannelService.storeAll(role.siteChannelsPublisher);
        role = Services.store("id", this.roles, role);
        return role;
    }

    public static unStore(role: IRoleModel): void {
        Services.unStore("id", this.roles, role);
    }
}