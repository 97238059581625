/**
 * vérifie si le localstorage est dispo
 */

export function isLocalStorageAvailable(): boolean {
    let test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}