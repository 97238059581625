import {ConditionType} from "../../../enums/ConditionType";
import {FilterBuilderReader} from "./reader/FilterBuilderReader";
import {OperatorType} from "../../../enums/OperatorType";
import {Filter} from "../Filter";
import {ValueType} from "../../../enums/ValueType";
import {FiltersOperatorIdentifiers} from "./operator/FiltersOperatorIdentifiers";

import {
    txtCamelCase
} from "../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtCamelCase";

/**
 * Constructeur de filtre
 */
export class FilterBuilder {

    public registers: { identifier: string; func: (filter: Filter) => void; }[];
    public reader: FilterBuilderReader;
    public conditions: boolean[];

    constructor(filtersData: any) {
        this.reader = new FilterBuilderReader(filtersData);
        this.registers = [];
        this.conditions = [];
    }

    public build(): boolean {
        this.reader.filters.forEach(filter => {
            this.registers.forEach(register => {
                if (register.identifier == filter.identifier) {
                    register.func(filter);
                }
            })
        });

        if (this.reader.conditionType == ConditionType.AND) {
            return this.conditions.filter(value => value == false).length == 0;
        } else {
            return this.conditions.filter(value => value == true).length >= 0;
        }
    }

    public sort(objects: any[]): any[] {
        let filter = this.reader.getEntry(FiltersOperatorIdentifiers.SORT_COLUMN_NAME) ? this.reader.getEntry(FiltersOperatorIdentifiers.SORT_COLUMN_NAME) : this.reader.getEntry(FiltersOperatorIdentifiers.SORT_JSON_COLUMN_NAME);
        if (filter) {
            let columnId = filter.values[0].split("-")[0];
            columnId = columnId.includes("_") ? txtCamelCase(columnId) : columnId;

            let valueType = parseInt(filter.values[0].split("-")[1]);

            if (filter.identifier == FiltersOperatorIdentifiers.SORT_COLUMN_NAME) {
                objects.forEach(object => object[columnId] = this.parse(valueType, object[columnId]));
                objects = objects.sort((a, b) => (a[columnId] < b[columnId]) ? 1 : -1);
            } else {
                objects.forEach(object => object.columnsData[columnId] = this.parse(valueType, object.columnsData[columnId]));
                objects = objects.sort((a, b) => (a.columnsData[columnId] < b.columnsData[columnId]) ? 1 : -1);
            }

            if (filter.operator == OperatorType.ORDER_ASC) {
                objects = objects.reverse();
            }
        }

        return objects;
    }

    public register(identifier: string, func: any): void {
        this.registers.push({
            identifier: identifier, func: (filter) => {
                let values = func(filter);
                this.registerLogic(filter, values);
            }
        });
    }

    public registerLogic(filter: Filter, currentValues: any[]) {
        let localConditions = [];
        if (filter.operator == OperatorType.CONTAINS_ALL) {
            localConditions.push(filter.values.filter(value => currentValues.filter(cValue => cValue.includes(value)).length > 0).length == filter.values.length);
        } else if (filter.operator == OperatorType.INTERVAL) {
            localConditions.push(currentValues[0] > filter.values[0] && currentValues[0] < filter.values[1]);
        } else {
            filter.values.forEach(value => {
                value = this.parse(filter.builderEntry.valueType, value);
                switch (filter.operator) {
                    case OperatorType.IS:
                        localConditions.push(currentValues.filter(v => this.clean(v) == value)[0] != undefined);
                        break;
                    case OperatorType.IS_NOT:
                        localConditions.push(!(currentValues.filter(v => this.clean(v) == value)[0] != undefined));
                        break;
                    case OperatorType.CONTAINS:
                        localConditions.push(currentValues.filter(cValue => this.clean(cValue).includes(value)).length > 0);
                        break;
                    case OperatorType.NOT_CONTAINS:
                        localConditions.push(!(currentValues.filter(cValue => this.clean(cValue).includes(value)).length > 0));
                        break;
                    case OperatorType.STARTS_WITH:
                        localConditions.push(currentValues.filter(cValue => this.clean(cValue).startsWith(value)).length > 0);
                        break;
                    case OperatorType.ENDS_WITH:
                        localConditions.push(currentValues.filter(cValue => this.clean(cValue).endsWith(value)).length > 0);
                        break;
                    case OperatorType.EXIST:
                        localConditions.push(currentValues.length > 0);
                        break;
                    case OperatorType.NOT_EXIST:
                        localConditions.push(currentValues.length == 0);
                        break;
                    case OperatorType.AFTER:
                    case OperatorType.GREATER:
                        localConditions.push(currentValues.filter(cValue => cValue > value).length > 0);
                        break;
                    case OperatorType.BEFORE:
                    case OperatorType.LESS:
                        localConditions.push(currentValues.filter(cValue => cValue < value).length > 0);
                        break;
                }
            });
        }
        this.conditions.push(localConditions.filter(value => value == true).length >= 1);
    }

    public clean(value: string) {
        if (typeof value == "string") {
            value = value.toLowerCase();
        }

        return value;
    }

    public parse(valueType: ValueType, value: string): any {
        switch (valueType) {
            case ValueType.INTEGER:
            case ValueType.LONG:
                return Number(value);
            case ValueType.STRING:
                return this.clean(value);
            case ValueType.BOOLEAN:
                return value == "true"
        }

        return value;
    }

}