import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {IInboxConversationModel} from "../../../models/inbox/conversation/IInboxConversationModel";
import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {SiteChannelService} from "../../site/channel/SiteChannelService";
import {InboxConversationParticipantService} from "./participant/InboxConversationParticipantService";
import {WebSocketPanelEventName} from "../../../network/socket/names/WebSocketPanelEventName";
import {EntityService} from "../../entity/EntityService";
import {
    IInboxConversationMessageModel
} from "../../../models/inbox/conversation/message/IInboxConversationMessageModel";
import {InboxConversationStatus} from "../../../models/inbox/conversation/InboxConversationStatus";
import {ComType} from "../../../models/enums/ComType";
import {IAvatarModel} from "../../../models/avatar/IAvatarModel";
import {InboxConversationCategoryService} from "./category/InboxConversationCategoryService";
import {
    FilterBuilderConversation
} from "../../../models/filter/types/builder/models/conversation/FilterBuilderConversation";
import {EntityType} from "../../../models/entity/types/EntityType";
import {
    IInboxConversationParticipantModel
} from "../../../models/inbox/conversation/participant/IInboxConversationParticipantModel";
import {RatingType} from "../../../models/enums/RatingType";
import {
    IInboxConversationMessageAttachmentModel
} from "../../../models/inbox/conversation/message/attachment/IInboxConversationMessageAttachmentModel";
import {
    InboxConversationMessageAttachmentService
} from "./message/attachment/InboxConversationMessageAttachmentService";
import {PreferencesService} from "../../preferences/PreferencesService";
import {ComState} from "../../../models/enums/ComState";
import {FiltersOperatorIdentifiers} from "../../../models/filter/types/builder/operator/FiltersOperatorIdentifiers";
import {ISiteChannelModel} from "../../../models/site/channel/ISiteChannelModel";
import {ContactChannelService} from "../../contact/channel/ContactChannelService";
import {OperatorType} from "../../../models/enums/OperatorType";
import {AssignableState} from "../../../models/assignment/AssignableState";
import {InboxConversationMessageService} from "./message/InboxConversationMessageService";
import {ProductType} from "../../../models/product/ProductType";
import {IInboxConversationStartOutgoing} from "../../../models/inbox/conversation/IInboxConversationStartOutgoing";
import {Resources} from "../../../resources/Resources";
import {ChannelType} from "../../../models/channel/ChannelType";
import {AccountComposerService} from "../../account/composers/AccountComposerService";
import {ComposerType} from "../../../products/panel/components/static/composer/types/ComposerType";
import {
    IInboxConversationMessageMetaDataMailRecipient
} from "../../../models/inbox/conversation/message/metadata/mail/IInboxConversationMessageMetaDataMailRecipient";
import {arrayEquals} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayEquals";

export class InboxConversationService {
    public static conversations: IInboxConversationModel[] = observable([]);
    public static newConversationEvents: ((conversation: IInboxConversationModel) => void)[] = [];
    public static messageConversationEvents: ((conversation: IInboxConversationModel, message: IInboxConversationMessageModel) => void)[] = [];
    public static conversationUpdatesEvents: ((conversation: IInboxConversationModel) => void)[] = [];
    public static conversationUpdateStatusEvents: ((conversation: IInboxConversationModel) => void)[] = [];
    public static conversationUpdateDeleteEvents: ((conversationIds: string[]) => void)[] = [];

    public static dispose(): void {
        this.conversations = observable([]);
        this.newConversationEvents = [];
        this.messageConversationEvents = [];
        this.conversationUpdatesEvents = [];
        this.conversationUpdateStatusEvents = [];
        this.conversationUpdateDeleteEvents = [];
    }

    public static init(): void {
        Services.beforeInit(this);

        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATION_UPDATE_LIGHT_LIST, (conversations) => this.storeAll(conversations));
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATION_UPDATE_LIGHT, (conversation) => this.store(conversation));
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATION_UPDATE_LIST, (conversations) => conversations.forEach(c => this.execUpdate(c)));
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATION_UPDATE, (conversation) => this.execUpdate(conversation));
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATIONS_DELETE, (conversationsIds) => {
            conversationsIds.forEach((id) => {
                let conversation = this.getById(id);
                if (conversation) {
                    conversation.state = ComState.DELETED;
                    this.execUpdate(conversation);
                    this.unStore(conversation);
                }
            });
            this.deleteConversation(conversationsIds);
        });
    }

    /**
     * Websocket
     */

    public static join(id: string) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATION_JOIN, id);
    }

    public static leave(id: string) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.INBOX_CONVERSATION_LEAVE, id);
    }

    /**
     * rest
     */

    public static async findOnInbox(siteId: string, count: number, beforeTime: number, filterEntryId: string, filterId: string, searchFilterData?: string, searchValue?: string, searchSort?: OperatorType, component?: INetworkComponent): Promise<IInboxConversationModel[]> {
        let request = await Network.request(ProductType.PANEL, (token) => Network.postJson(ProductType.PANEL, "/conversations/site", {
            siteId: siteId,
            count: count,
            beforeTime: beforeTime,
            filterEntryId: filterEntryId,
            filterId: filterId,
            searchFilterData: JSON.parse(searchFilterData ? searchFilterData : "{}"),
            searchValue: searchValue,
            searchSort: searchSort
        }), component);
        if (request != undefined && request.status == HttpStatus.OK) {
            let conversations = this.storeAll(request.data);
            conversations = this.sort(conversations);
            if (searchSort == OperatorType.ORDER_ASC) {
                conversations = conversations.reverse();
            }

            return conversations;
        }

        return [];
    }

    public static async findBySiteAndFilter(siteId: string, count: number, beforeTime: number, waste: boolean, filterData: string, component?: INetworkComponent): Promise<IInboxConversationModel[]> {
        let request = await Network.request(ProductType.PANEL, (token) => Network.postJson(ProductType.PANEL, `/conversations/site/filters/${siteId}/${count}/${beforeTime}/${waste}`, JSON.parse(filterData)), component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findInboxCountsBySite(siteId: string, filterId: string, component?: INetworkComponent): Promise<any[]> {
        let request = await Network.get(ProductType.PANEL, `/conversations/site/inboxCounts/${siteId}/${filterId}`, component);
        if (request && request.status == HttpStatus.OK) {
            return request.data;
        }
        return [];
    }

    public static async findById(id: string, component: INetworkComponent): Promise<IInboxConversationModel> {
        let conversation = this.getById(id);
        if (conversation != undefined) {
            return conversation;
        }

        Services.handleErrors(component, [
            {status: HttpStatus.UNAUTHORIZED, message: Resources.t("words.conversationsNotAvailableMessage")}
        ]);

        let request = await Network.get(ProductType.PANEL, `/conversations/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async findAttachmentsById(id: string, component?: INetworkComponent): Promise<IInboxConversationMessageAttachmentModel[]> {
        let request = await Network.get(ProductType.PANEL, `/conversations/attachments/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return InboxConversationMessageAttachmentService.storeAll(request.data);
        }

        return undefined;
    }

    public static async updateStatus(conversationsIds: string[], conversationStatus: number, categoriesIds?: string[], component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/status/update",
            {
                conversationsIds: conversationsIds,
                conversationStatus: conversationStatus,
                categoriesIds: categoriesIds
            }, component);

        if (request.status == HttpStatus.OK) {
            return true;
        }

        return false;
    }

    public static async updateCategory(conversationsIds: string[], categoryId: string, remove: boolean, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/categories/update",
            {
                remove: remove,
                conversationsIds: conversationsIds,
                categoryId: categoryId
            }, component);

        if (request.status == HttpStatus.OK) {
            return true;
        }

        return false;
    }

    public static async updateRating(id: string, rating: number, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/rating/update",
            {
                id: id,
                rating: rating
            }, component);

        if (request.status == HttpStatus.OK) {
            this.store(request.data);
            return true;
        }

        return false;
    }

    public static async create(siteChannelId: string, comType: ComType, snippet: string, component?: INetworkComponent): Promise<IInboxConversationModel> {
        let request = await Network.post(ProductType.PANEL, "/conversations", {
            siteChannelId: siteChannelId,
            comType: comType,
            snippet: snippet
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async start(start: IInboxConversationStartOutgoing, component?: INetworkComponent): Promise<{
        conversation: IInboxConversationModel,
        message: IInboxConversationMessageModel
    }> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/start`, start, component);

        if (request.status == HttpStatus.OK) {
            request.data.conversation = this.store(request.data.conversation);
            if (request.data.message) {
                request.data.message = InboxConversationMessageService.store(request.data.message);
            }

            return request.data;
        }

        return undefined;
    }

    public static async read(conversationsIds: string[], component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/read", {
            conversationsIds: conversationsIds
        }, component);

        if (request.status == HttpStatus.OK) {
            return true;
        }

        return false;
    }

    public static async markUnread(conversationsIds: string[], component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/markUnread",
            {
                conversationsIds: conversationsIds
            }, component);

        if (request.status == HttpStatus.OK) {
            return true;
        }

        return false;
    }

    public static async mute(conversationsIds: string[], mute: boolean, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/mute",
            {
                conversationsIds: conversationsIds,
                mute: mute,
            }, component);

        if (request.status == HttpStatus.OK) {
            return true
        }

        return false;
    }

    public static async state(conversationsIds: string[], state: number, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/conversations/state",
            {
                conversationsIds: conversationsIds,
                state: state,
            }, component);

        if (request.status == HttpStatus.OK) {
            return true
        }

        return false;
    }

    public static async compose(recipients?: IInboxConversationMessageMetaDataMailRecipient[], component?: INetworkComponent): Promise<boolean> {
        if (Network.router.static.components.composer.canTab()) {
            let siteChannel = await SiteChannelService.pickedByPerms(component, ChannelType.MAIL);
            if (siteChannel) {
                let comType = SiteChannelService.comType(siteChannel);
                let message = InboxConversationMessageService.virtualGenerateDto(comType, undefined);
                message.comState = ComState.DRAFT;
                message.comType = ComType.EMAIL;

                if (siteChannel.channel.type == ChannelType.MAIL) {
                    message.metaData = {
                        mail: {
                            to: recipients
                        }
                    };
                }

                let startData = await InboxConversationService.start({
                    comType: comType,
                    sendMessage: true,
                    comState: ComState.DRAFT,
                    siteChannelId: siteChannel.id,
                    message: message
                });

                if (startData) {
                    await AccountComposerService.createTab({
                        type: ComposerType.CONVERSATION_MESSAGE,
                        contentId: startData.message.id
                    }, component);

                    return true;
                }
            } else {
                Network.router.static.components.composer.renderTab({
                    type: ComposerType.EMPTY,
                    contentId: Resources.t('words.createEmailBeforeConversation')
                }, true);
            }
        }

        return false;
    }

    /**
     * get
     */

    public static getById(id: string): IInboxConversationModel {
        return this.conversations.filter(value => value.id == id)[0];
    }

    public static getAll(count?: number): IInboxConversationModel[] {
        this.conversations.length;
        let conversations = [...this.conversations];

        if (count == undefined) {
            return this.sort(conversations);
        } else {
            return this.sort(conversations).slice(0, count);
        }
    }

    public static getUnReadsByEntity(entityType: EntityType, entityId: string, conversations: IInboxConversationModel[]): number {
        let unReads = 0;
        conversations.map(conversation => {
            let participant = InboxConversationService.getParticipantByEntity(entityType, entityId, conversation);
            if (participant != undefined) {
                unReads += participant.unreadCount;
            }
        });

        return unReads;
    }

    public static getParticipantByEntity(entityType: EntityType, entityId: string, conversation: IInboxConversationModel): IInboxConversationParticipantModel {
        return conversation.participants.filter(value => value.participantId == entityId && value.participantType == entityType)[0];
    }

    public static getMyParticipant(conversation: IInboxConversationModel) {
        return InboxConversationService.getParticipantByEntity(EntityService.activeEntity.type, EntityService.activeEntity.id, conversation);
    }

    public static iamParticipant(conversation: IInboxConversationModel) {
        let myParticipant = InboxConversationService.getMyParticipant(conversation);
        return myParticipant != undefined && myParticipant.state == AssignableState.JOINED;
    }

    public static getParticipantsByType(type: EntityType, conversation: IInboxConversationModel): IInboxConversationParticipantModel[] {
        let participants = conversation.participants.filter(value => value.participantType == type);
        participants.sort((a, b) => (a.lastMessageTime > b.lastMessageTime) ? 1 : -1);
        participants.reverse();
        return participants.length > 0 ? participants : [conversation.owner];
    }


    /**
     * logic
     */

    public static newMessage(message: IInboxConversationMessageModel): void {
        let conversation = InboxConversationService.getById(message.conversationId);
        if (conversation != undefined) {
            conversation.newMessages.push(message);
            this.messageConversationEvents.forEach(value => value(conversation, message));

            conversation.isNew = false;
        }
    }

    public static newConversation(conversation: IInboxConversationModel): void {
        conversation.isNew = true;
        this.newConversationEvents.forEach(value => value(conversation));
    }

    public static updateConversation(conversation: IInboxConversationModel) {
        this.conversationUpdatesEvents.forEach(value => value(conversation));
    }

    public static updateStatusConversation(conversation: IInboxConversationModel) {
        this.conversationUpdateStatusEvents.forEach(value => value(conversation));
    }

    public static deleteConversation(conversationsIds: string[]) {
        //conversations.isNew = false;
        this.conversationUpdateDeleteEvents.forEach(value => value(conversationsIds));
    }

    public static sort(conversations: IInboxConversationModel[]): IInboxConversationModel[] {
        return conversations.sort((a, b) => (a.updatedTime > b.updatedTime) ? 1 : -1).reverse();
    }

    public static activeEntityRead(conversation: IInboxConversationModel): void {
        let participant = InboxConversationService.getMyParticipant(conversation);
        if (participant != undefined && participant.unreadCount > 0) {
            InboxConversationService.read([conversation.id]);
        }
    }

    public static execUpdate(conversation: IInboxConversationModel) {
        let updateStatus = false;
        let storedConversation = this.getById(conversation.id);
        if (storedConversation != undefined) {
            if (storedConversation.updatedTime < conversation.updatedTime) {
                updateStatus = true;
            }

            if (!arrayEquals(
                storedConversation.participants
                    .filter(value => value.state != AssignableState.DELETED)
                    .map(value => value.id),
                conversation.participants
                    .filter(value => value.state != AssignableState.DELETED)
                    .map(value => value.id))) {
                updateStatus = true;
            }
        }

        conversation = this.store(conversation);
        this.updateConversation(conversation);

        if (updateStatus) {
            this.updateStatusConversation(conversation);
        }
    }

    /**
     * events
     */

    public static onNewConversation(func: ((conversation: IInboxConversationModel) => void), component: INetworkComponent) {
        this.newConversationEvents.push(func);
        component.onRemove(() => this.newConversationEvents.splice(this.newConversationEvents.indexOf(func), 1));
    }

    public static onNewMessage(func: ((conversation: IInboxConversationModel, message: IInboxConversationMessageModel) => void), component: INetworkComponent) {
        this.messageConversationEvents.push(func);
        component.onRemove(() => this.messageConversationEvents.splice(this.messageConversationEvents.indexOf(func), 1));
    }

    public static onUpdateConversation(func: ((conversation: IInboxConversationModel) => void), component: INetworkComponent) {
        this.conversationUpdatesEvents.push(func);
        component.onRemove(() => this.conversationUpdatesEvents.splice(this.conversationUpdatesEvents.indexOf(func), 1));
    }

    public static onUpdateStatusConversation(func: ((conversation: IInboxConversationModel) => void), component: INetworkComponent) {
        this.conversationUpdateStatusEvents.push(func);
        component.onRemove(() => this.conversationUpdateStatusEvents.splice(this.conversationUpdateStatusEvents.indexOf(func), 1));
    }

    public static onDeleteConversations(func: ((conversationsIds: string[]) => void), component: INetworkComponent) {
        this.conversationUpdateDeleteEvents.push(func);
        component.onRemove(() => this.conversationUpdateDeleteEvents.splice(this.conversationUpdateDeleteEvents.indexOf(func), 1));
    }

    /**
     * filter
     */

    public static filters(conversation: IInboxConversationModel, filters: string[]): boolean {
        return filters.filter(value => value != undefined && value.startsWith("{"))
            .filter(value => !this.filter(conversation, value)).length == 0;
    }

    public static filter(conversation: IInboxConversationModel, filters: string): boolean {
        let builder = new FilterBuilderConversation(filters, conversation);

        if (PreferencesService.inboxSearchValue != undefined && PreferencesService.inboxSearchValue.length > 0) {
            if (!conversation.owner.participant.name.toLowerCase().startsWith(PreferencesService.inboxSearchValue.toLowerCase())) {
                return false;
            }
        }

        if (conversation.state == ComState.DELETED) {
            return false;
        }

        let filter = builder.reader.getEntry(FiltersOperatorIdentifiers.STATE);
        if (filter != undefined) {
            if ((!filter.values.includes(ComState.SPAM) && conversation.state == ComState.SPAM)) {
                return false;
            }

            if ((!filter.values.includes(ComState.SPAM_ASSASSIN) && conversation.state == ComState.SPAM_ASSASSIN)) {
                return false;
            }

            if ((!filter.values.includes(ComState.TRASH) && conversation.state == ComState.TRASH)) {
                return false;
            }
        }

        return builder.build();
    }

    /**
     * generate
     */
    public static virtualGenerate(avatar: IAvatarModel, siteChannel: ISiteChannelModel): IInboxConversationModel {
        return {
            lastResponseContactTime: 0,
            importance: false,
            contactChannels: [],
            siteChannelId: siteChannel.id,
            siteChannels: [siteChannel],
            state: ComState.NONE,
            remoteId: "remoteId",
            id: "virtualId",
            siteId: "virtualId",
            snippet: "snippet",
            unreadCount: 0,
            status: InboxConversationStatus.SOLVED,
            comType: ComType.DIRECT_MESSAGE,
            updatedTime: new Date().getTime(),
            time: new Date().getTime(),
            owner: InboxConversationParticipantService.virtualGenerate(avatar, EntityType.ACCOUNT),
            entities: [],
            newMessages: [],
            siteChannel: siteChannel,
            participants: [InboxConversationParticipantService.virtualGenerate(avatar, EntityType.ACCOUNT)],
            categories: [],
            rating: RatingType.NO_RATING,
            isNew: false
        };
    }


    /**
     * store
     */

    public static storeAll(conversations: IInboxConversationModel[]): IInboxConversationModel[] {
        for (let key in conversations)
            conversations[key] = this.store(conversations[key]);

        return Services.storeAll(conversations);
    }

    public static store(conversation: IInboxConversationModel): IInboxConversationModel {
        if (conversation.participants.length > 0) {
            conversation.owner = InboxConversationParticipantService.store(conversation.participants.filter(value => value.owner)[0]);
            conversation.participants = InboxConversationParticipantService.storeAll(conversation.participants.filter(value => value.participant != undefined));
        }


        conversation.categories = InboxConversationCategoryService.storeAll(conversation.categories);
        conversation.entities = EntityService.storeAll(conversation.entities);
        conversation.siteChannels = SiteChannelService.storeAll(conversation.siteChannels);
        conversation.contactChannels = ContactChannelService.storeAll(conversation.contactChannels);
        conversation.siteChannel = SiteChannelService.store(conversation.siteChannels.filter(value => value.id == conversation.siteChannelId)[0]);

        if (conversation.newMessages == undefined) {
            conversation.newMessages = observable([]);
        }


        conversation = Services.store("id", this.conversations, conversation);
        return conversation;
    }

    public static unStore(conversation: IInboxConversationModel): void {
        Services.unStore("id", this.conversations, conversation);
    }
}