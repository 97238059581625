@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/keyframes";

.globalLoaderLight {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  @include positioning.flex-globally;

  .lightContainer {
    width: 100%;
    height: 100%;
    position: relative;

    @include positioning.flex-globally;

    circle {
      stroke-width: 4px;
    }

    svg {
      @include animations.spinner-loader;

      width: 30px;
      height: 30px;
    }
  }
}