@use "../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentCommunityNetworkLost {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--white);

  @include positioning.flex-globally;

  .imageContainer {
    @include positioning.flex-globally;

    .image {
      margin-bottom: 35px;
      position: relative;
      height: 120px;
      width: 120px;

      @include icons.ico("3d-help");
    }
  }

  .text {
    width: 600px;
    text-align: center;
    font-size: 26px;

    @include texts.font("semi-bold");
  }
}