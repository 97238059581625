import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {ICategoryModel} from "../../models/category/ICategoryModel";
import {CategoryType} from "../../models/category/CategoryType";
import {FilterService} from "../filter/FilterService";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {ProductType} from "../../models/product/ProductType";

export class CategoryService {
    public static categories: ICategoryModel[] = observable([]);

    public static init() {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.CATEGORY_UPDATE, (data) => {
            this.store(data);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.CATEGORY_DELETE, (data) => {
            this.unStore(data);
            this.onDelete(data);
        });
    }

    public static dispose() {
        this.categories = observable([]);
    }

    public static async findByType(typeId: number, manage?: boolean, component?: INetworkComponent): Promise<ICategoryModel[]> {
        let request = await Network.get(ProductType.PANEL, `/categories/${typeId}/${manage == undefined ? false : manage}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findByTypeAndJoined(typeId: number, joinedId: string, categorizableId?: string, component?: INetworkComponent): Promise<ICategoryModel[]> {
        let request = await Network.get(ProductType.PANEL, `/categories/joined/${typeId}/${joinedId}${categorizableId == undefined ? `` : `/${categorizableId}`}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async delete(id: string, component?: INetworkComponent): Promise<void> {
        let request = await Network.delete(ProductType.PANEL, `/categories/${id}`, component);
        if (request.status == HttpStatus.OK) {
            let c = this.categories.filter(category => category.id == id)[0];
            this.unStore(c);
        }
    }

    public static async create(name: string, color: string, type: CategoryType, joinedIds: string[], personal: boolean, component?: INetworkComponent): Promise<ICategoryModel> {
        let request = await Network.post(ProductType.PANEL, "/categories",
            {
                name: name,
                color: color,
                type: type,
                joinedIds: joinedIds,
                personal: personal
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async update(id: string, name: string, color: string, joinedIds: string[], personal: boolean, component?: INetworkComponent): Promise<ICategoryModel> {
        let request = await Network.post(ProductType.PANEL, "/categories/update",
            {
                id: id,
                name: name,
                color: color,
                joinedIds: joinedIds,
                personal: personal
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    /**
     * events
     */

    public static onDelete(category: ICategoryModel) {

    }

    public static storeAll(categories: ICategoryModel[]): ICategoryModel[] {
        for (let key in categories)
            categories[key] = this.store(categories[key]);

        return Services.storeAll(categories);
    }

    public static store(category: ICategoryModel): ICategoryModel {
        if (category.filters != undefined) {
            category.filters = FilterService.storeAll(category.filters);
        }

        return Services.store("id", this.categories, category);
    }

    public static unStore(category: ICategoryModel): void {
        Services.unStore("id", this.categories, category);
    }
}