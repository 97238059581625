import {ValueType} from "../../enums/ValueType";
import {OperatorType} from "../../enums/OperatorType";
import {FilterBuilderOperator} from "./builder/operator/FilterBuilderOperator";

export class Filter {

    public operator: OperatorType;
    public identifier: string;
    public values: any[];
    public builderEntry: FilterBuilderOperator;

    constructor(operator: OperatorType, identifier: string, values: any[], builderEntry: FilterBuilderOperator) {
        this.operator = operator;
        this.identifier = identifier;
        this.values = values;
        this.builderEntry = builderEntry;
    }

    public jsonify(): any {
        let values = [];


        if (this.builderEntry.type == ValueType.NULL) {
            values = ['null'];
        } else {
            this.values.forEach(value => values.push(value.value ? value.value : value));
        }

        return {
            operator: this.operator,
            identifier: this.identifier,
            values: values
        };
    }
}