import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-page.scss";
import {CommunityService} from "../../../../../../services/community/CommunityService";
import {Network} from "../../../../../../network/Network";
import {Resources} from "../../../../../../resources/Resources";
import {ICommunityCategoryModel} from "../../../../../../models/community/category/ICommunityCategoryModel";
import {CommunitySearchComponent} from "../../../../abstract/components/community/search/CommunitySearchComponent";
import {ICommunityLiveModel} from "../../../../../../models/community/ICommunityLiveModel";
import {
    SedestralInterface
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {ThemeComponent} from "../../../../../panel/components/components/theme/ThemeComponent";
import {AppearanceTheme} from "../../../../../../models/enums/AppearanceTheme";
import {CommunityThemeComponent} from "../../../../abstract/components/community/theme/CommunityThemeComponent";
import {ProductType} from "../../../../../../models/product/ProductType";

export abstract class BlogPageComponent extends Component {

    public live: ICommunityLiveModel;

    public navigationContainer: Component;
    public navigationBrandContainer: Component;
    public navigationBrandLogoContainer: Component;
    public navigationBrandInput: Component;
    public bodyContainer: Component;

    public searchTooltip: CommunitySearchComponent;

    protected constructor(live?: ICommunityLiveModel) {
        super();
        this.live = live ? live : CommunityService.live;

        //language=HTML
        this.template = `
            <div class="${s.componentBlogPage}"></div>`;
    }

    commit() {
        this.renderNavigation();
        this.renderBody();

        this.onReactiveObserve(() => SedestralInterface.main.setStyle(`background:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].backgroundColor};`));

        super.commit();
    }

    /**
     * render
     */
    renderNavigation() {
        //language=HTML
        this.navigationContainer = this.append(`
            <div class="${s.navigation}"></div>`);


        //language=HTML
        this.navigationBrandContainer = this.navigationContainer.append(`
            <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization" class="${s.brand}">
                <meta itemprop="name" content="${this.live.community.text.name}">
                <link itemprop="url" href="${this.live.community.text.websiteUrl}">
                <div class="${s.top}">
                    <div class="${s.logo}"></div>
                    ${this.live.community.text.websiteUrl != null 
                            ? `<a href="${this.live.community.text.websiteUrl}" target="_blank" class="${s.mobileWebsite}">${this.live.community.text.backWebsite}</a>` 
                            : ""}
                </div>
                <meta itemprop="name" content="${this.live.community.text.metaTitle}">
                <p itemprop="description" class="${s.description}">
                    ${this.reactive(() => this.live.community.text.welcome)}
                </p>
                <div class="${s.search}">
                    <div class="${s.icon}"></div>
                    <input class="${s.input}"
                           placeholder="${this.live.community.text.searchInput ?? Resources.t("words.search")}"/>
                </div>
                <div class="${s.line}"></div>
            </div>
        `);

        this.navigationBrandInput = this.el(s.input);
        this.navigationBrandLogoContainer = this.navigationBrandContainer.el(s.logo);


        this.navigationBrandInput.onClick(() => this.renderSearch(), true);
        this.navigationBrandInput.onKeyUp(() => this.renderSearch());

        this.navigationBrandContainer.onReactiveObserve(() => {
            this.navigationBrandLogoContainer.clearAll();
            let link = this.live.community.logoFile?.link;
            if (link) {
                //language=HTML
                this.navigationBrandLogoContainer.append(`
                    <a aria-label="${Resources.t("words.backHome")}" href="/home">
                        <img height="62" width="unset" itemprop="logo"
                             alt="${Resources.t("words.logo")} ${this.live.community.text?.metaTitle}"
                             src="${link}"/>
                    </a>
                `, "a");
            }
        });


        //language=HTML
        let theme = this.navigationContainer.append(`
            <div class="${s.theme}">
                ${this.navigationContainer.draw(new CommunityThemeComponent(this.live, Resources.realTheme(ProductType.BLOG)))}
            </div>
        `);

        if (this.live.community.text.websiteUrl != null) {
            //language=HTML
            let website = this.navigationContainer.append(`
            <a href="${this.live.community.text.websiteUrl}" target="_blank" class="${s.website}">
                ${this.reactive(() => this.live.community.text.backWebsite)}
            </a>
        `);
            this.onReactiveObserve(() => website.setStyle(`background:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor};`));
        }

        let mobileWebsite = this.navigationContainer.el(s.mobileWebsite);
        this.onReactiveObserve(() => mobileWebsite.setStyle(`background:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor};`));

        let description = this.navigationBrandContainer.el(s.description);
        this.onReactiveObserve(() => description.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondTextColor};`));
    }

    renderBody() {
        //language=HTML
        this.bodyContainer = this.append(`
            <section aria-label="${Resources.t("words.lastNews")}" class="${s.body}"></>`);
    }

    renderSearch() {
        let value = this.navigationBrandInput.getValue();
        if (value.length > 0) {
            if (!this.searchTooltip || this.searchTooltip.isNull()) {
                this.searchTooltip = new CommunitySearchComponent(this.navigationBrandInput, this.live.community, 500);
                this.searchTooltip.onPick = (article) => {
                    let category = this.live.categories.find(value => value.id == article.categoryId);
                    Network.router.pages.reload(CommunityService.linkLocalArticle(category, article));
                }

                this.searchTooltip.screen(false);
                this.searchTooltip.create();
            }
        } else {
            if (this.searchTooltip) {
                this.searchTooltip.dispose();
                this.searchTooltip = undefined;
            }
        }
    }

    /**
     * get
     */

    getBasePath(): string {
        return `/${Resources.language}/${Network.router.pages.getParamAtIndex(2)}`;
    }

    getBaseCategory(): ICommunityCategoryModel {
        return this.live.categories.find(value => value.route == this.getBasePath());
    }
}