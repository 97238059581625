import {observable} from "@nx-js/observer-util";
import {IFilterModel} from "../../models/filter/IFilterModel";
import {Services} from "../Services";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {FilterType} from "../../models/filter/types/FilterType";
import {EntityService} from "../entity/EntityService";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {ProductType} from "../../models/product/ProductType";
import {arrayRemove} from "../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";

export class FilterService {

    public static filters: IFilterModel[] = observable([]);
    public static filtersLoadedTypes: FilterType[] = [];

    public static dispose(): void {
        this.filters = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.FILTER_UPDATE, (data) => {
            this.store(data);
            this.onCreate(data);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.FILTER_DELETE, (data) => {
            this.unStore(data);
            this.onDelete(data);
        });
    }

    /**
     * load
     */

    public static async findBySiteIdAndType(siteId: string, type: FilterType, component?: INetworkComponent): Promise<IFilterModel[]> {
        let request = await Network.get(ProductType.PANEL, `/filters/site/${siteId}/${type}`, component);
        if (request.status == HttpStatus.OK) {
            this.filters.filter(filter => filter.type == type).forEach(filter => arrayRemove(this.filters, filter));
            this.filtersLoadedTypes.push(type);
            return this.storeAll(request.data);
        }

        return undefined;
    }

    /**
     * get
     */
    public static async getAll(type: FilterType): Promise<IFilterModel[]> {
        if (this.filtersLoadedTypes.includes(type)) {
            return this.filters.filter(value => value.type == type);
        } else {
            return await this.findBySiteIdAndType(EntityService.activeSite.id, type);
        }
    }

    /**
     * create
     */

    public static async create(name: string, data: string, type: number, isPrivate: boolean, component?: INetworkComponent): Promise<IFilterModel> {
        let request = await Network.postJson(ProductType.PANEL, "/filters",
            {
                name: name,
                data: JSON.parse(data),
                type: type,
                isPrivate: isPrivate
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    /**
     * update
     */

    public static async update(id: string, name: string, data: string, type: number, isPrivate: boolean, component?: INetworkComponent): Promise<IFilterModel> {
        let request = await Network.postJson(ProductType.PANEL, `/filters/update/${id}`,
            {
                name: name,
                data: JSON.parse(data),
                type: type,
                isPrivate: isPrivate
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    /**
     * delete
     */

    public static async delete(filter: IFilterModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/filters/${filter.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(filter);
            return true;
        }

        return false;
    }

    /**
     * events
     */

    public static onDelete(filter: IFilterModel) {

    }

    public static onCreate(filter: IFilterModel) {

    }


    /**
     * store
     */
    public static storeAll(filters: IFilterModel[]): IFilterModel[] {
        for (let key in filters) {
            filters[key] = this.store(filters[key]);
        }
        return Services.storeAll(filters);
    }

    public static store(filter: IFilterModel): IFilterModel {
        return Services.store("id", this.filters, filter);
    }

    public static unStore(filter: IFilterModel): void {
        arrayRemove(this.filters, this.filters.filter(value => value.id == filter.id)[0]);
    }
}