enum PaymentProviderType {
    STRIPE = 0,
    SHOPIFY = 1
}

class PaymentProvider {
    id: PaymentProviderType;
    invoice: boolean;
    cb: boolean;
    address: boolean;
    directDeleteDefinitiveOffer: boolean;

    constructor(id: PaymentProviderType, invoice: boolean, cb: boolean, address: boolean, directDeleteDefinitiveOffer: boolean) {
        this.id = id;
        this.invoice = invoice;
        this.cb = cb;
        this.address = address;
        this.directDeleteDefinitiveOffer = directDeleteDefinitiveOffer;
    }
}

const stripe = new PaymentProvider(PaymentProviderType.STRIPE, true, true, true, false);
const shopify = new PaymentProvider(PaymentProviderType.SHOPIFY, false, false, false, true);

const paymentProvidersMap: { [key: number]: PaymentProvider } = {
    [PaymentProviderType.STRIPE]: stripe,
    [PaymentProviderType.SHOPIFY]: shopify
};

function getPaymentProviderById(id: number): PaymentProvider | undefined {
    return paymentProvidersMap[id];
}

export { PaymentProviderType, PaymentProvider, stripe, shopify, getPaymentProviderById };
