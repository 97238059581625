import {Resources} from "../../../resources/Resources";

/**
 * retourne la date pour belle pour article
 */
export function dateFormatArticle(date: Date) {
    return new Intl.DateTimeFormat(Resources.language, {
        day: "numeric",
        month: "long",
        year: "numeric"
    }).format(new Date(date))
}