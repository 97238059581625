import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./community-search.scss";
import {ICommunityModel} from "../../../../../../models/community/ICommunityModel";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {CommunityArticleService} from "../../../../../../services/community/articles/CommunityArticleService";
import {Resources} from "../../../../../../resources/Resources";
import {ICommunityArticleModel} from "../../../../../../models/community/article/ICommunityArticleModel";
import {
    EmptyBasicComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {
    filterXSS
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {isMobile} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";

export class CommunitySearchComponent extends VisualClickTooltipWhiteComponent {

    public community: ICommunityModel;

    public container: Component;

    constructor(component: Component, community: ICommunityModel, width: number) {
        super(component, 70, width);
        this.community = community;
        this.mobileStyle(true);
        this.autoMaxHeight(true);

        //language=HTML
        this.template = `
            <div class="${s.componentCommunitySearch}">
                ${this.draw(new LoaderLightComponent())}
            </div>
        `;
    }

    commit() {
        this.swapable(() => this.dispose());

        this.container = this.el(s.componentCommunitySearch);

        let firstSearchTimeout = this.timeOut(() => this.search(), 500);
        this.component.onKeyUp(() => clearTimeout(firstSearchTimeout));
        this.component.onKeyUp(() => this.search(), 500);

        super.commit();
    }

    async search() {
        let searchText = this.component.getValue().trim();

        if (searchText !== undefined && searchText.length > 0) {
            let search = await CommunityArticleService.search(this.community.id, Resources.language, this.component.getValue(), this);

            this.container.clearAll();

            if (!this.isNull())
                this.container.scrollable();

            if (search.articles.length > 0) {
                search.articles.forEach(article => this.renderArticle(article));
            } else {
                this.renderNoResults();
            }

        } else {
            this.container.clearAll();
            this.renderNoResults();
        }
    }

    renderNoResults() {
        this.container.append(`
                <div>
                    ${this.container.draw(new EmptyBasicComponent("🔍", Resources.t("words.searchNotFound")))}
                </div>
            `);
    }

    renderArticle(article: ICommunityArticleModel) {
        //language=HTML
        let component = this.container.append(`
            <div class="${s.entry}">
                <div class="${s.title}">${filterXSS(article.title)}</div>
            </div>
        `);

        component.onClick(() => this.onPick(article));
    }

    calcOutsideBottom(): number {
        return 0;
    }

    calcMiddle(): number {
        return super.calcLeft();
    }

    onPick(article: ICommunityArticleModel) {

    }

}