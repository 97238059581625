@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.swapBar {
  height: 30px;
  width: 100%;
  margin-top: -6px;

  @include positioning.flex-globally;

  .bar {
    height: 5px;
    width: 40%;
    border-radius: 10px;
    left: 30%;
    background: var(--grey-sur);
  }
}

.swap {
  max-height: calc(100% - 6px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}