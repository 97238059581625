import {
    IInboxConversationMessageAttachmentModel
} from "../../../../../models/inbox/conversation/message/attachment/IInboxConversationMessageAttachmentModel";
import {observable} from "@nx-js/observer-util";
import {Services} from "../../../../Services";
import {AttachmentType} from "../../../../../models/enums/AttachmentType";
import {FileService} from "../../../../file/FileService";
import {PreviewService} from "../../../../preview/PreviewService";
import {IFileModel} from "../../../../../models/file/IFileModel";
import {IPreviewModel} from "../../../../../models/preview/IPreviewModel";
import {EntityService} from "../../../../entity/EntityService";

export class InboxConversationMessageAttachmentService {
    public static attachments: IInboxConversationMessageAttachmentModel[] = observable([]);

    public static dispose(): void {
        this.attachments = observable([]);
    }

    public static init(): void {

    }

    /**
     * virtual
     */

    public static virtualGenerate(files?: IFileModel[], previews?: IPreviewModel[]): IInboxConversationMessageAttachmentModel[] {
        let attachments = [];
        if (files != undefined && files.length != undefined) {
            files.forEach(value => {
                attachments.push(observable({
                    id: "virtualId",
                    type: AttachmentType.FILE,
                    content: value
                }))
            });
        }
        if (previews != undefined && previews.length != undefined) {
            previews.forEach(value => {
                attachments.push(observable({
                    id: "virtualId",
                    type: AttachmentType.PREVIEW,
                    content: value
                }))
            });
        }

        return attachments;
    }

    /**
     * store
     */

    public static storeAll(attachments: IInboxConversationMessageAttachmentModel[]): IInboxConversationMessageAttachmentModel[] {
        for (let key in attachments)
            attachments[key] = this.store(attachments[key]);

        return Services.storeAll(attachments);
    }

    public static store(attachment: IInboxConversationMessageAttachmentModel): IInboxConversationMessageAttachmentModel {
        if (attachment.type == AttachmentType.FILE) {
            attachment.content = FileService.store(attachment.content as IFileModel);
        }

        if (attachment.type == AttachmentType.PREVIEW) {
            attachment.content = PreviewService.store(attachment.content as IPreviewModel);
        }

        if (attachment.owner != undefined) {
            attachment.owner = EntityService.store(attachment.owner);
        }

        attachment = Services.store("id", this.attachments, attachment);
        return attachment;
    }
}