import {FilterBuilder} from "../../FilterBuilder";
import {FiltersOperatorIdentifiers} from "../../operator/FiltersOperatorIdentifiers";
import {IInboxConversationModel} from "../../../../../inbox/conversation/IInboxConversationModel";
import {EntityType} from "../../../../../entity/types/EntityType";
import {AssignableState} from "../../../../../assignment/AssignableState";

/**
 * Builder pour conversations
 */
export class FilterBuilderConversation extends FilterBuilder {
    constructor(filtersData: any, conversation: IInboxConversationModel) {
        super(filtersData);

        this.register(FiltersOperatorIdentifiers.CONTACT_NAME, () => {
            return [conversation.owner.participant.name];
        });

        this.register(FiltersOperatorIdentifiers.CONTACT_MAIL, () => {
            return [conversation.owner.participant.email];
        });

        this.register(FiltersOperatorIdentifiers.SITE_CHANNELS, () => {
            return [conversation.siteChannel.id];
        });

        this.register(FiltersOperatorIdentifiers.CHANNELS, () => {
            return [conversation.siteChannel.channelId];
        });

        this.register(FiltersOperatorIdentifiers.ASSIGNED, () => {
            return [conversation.participants.filter(value => value.participantType == EntityType.ACCOUNT && value.state != AssignableState.DELETED).length > 0];
        });

        this.register(FiltersOperatorIdentifiers.ASSIGNED_TO, () => {
            return [conversation.participants.filter(value => value.state != AssignableState.DELETED).map(value => value.participantId)];
        });

        this.register(FiltersOperatorIdentifiers.CONTACT_ID, () => {
            return [conversation.participants.filter(value => value.state != AssignableState.DELETED && value.participantType == EntityType.CONTACT).map(value => value.participantId)];
        });

        this.register(FiltersOperatorIdentifiers.STATUS, () => {
            return [conversation.status];
        });

        this.register(FiltersOperatorIdentifiers.STATE, () => {
            return [conversation.state];
        });

        this.register(FiltersOperatorIdentifiers.CATEGORY, () => {
            return [conversation.categories.map(value => value.category.id)];
        });

        this.register(FiltersOperatorIdentifiers.ARCHIVED, () => {
            return [conversation.categories.length > 0];
        });

        this.register(FiltersOperatorIdentifiers.TIME, () => {
            return [conversation.time];
        });

        this.register(FiltersOperatorIdentifiers.UPDATED_TIME, () => {
            return [conversation.updatedTime];
        });
    }
}