@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentBlogPage {
  width: 100%;
  display: flex;
  position: absolute;
  gap: 70px;

  .navigation {
    position: sticky;
    top: 0;
    padding: 25px 0 25px 0;
    width: 260px;
    flex: 0 0 260px;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;

    .brand {
      margin-bottom: 20px;

      .top {
        display: flex;

        .logo {
          margin-bottom: 20px;
        }

        .mobileWebsite {
          margin-left: auto;
          background: var(--black);
          color: var(--white);
          padding: 6px 10px;
          height: fit-content;
          border-radius: 24px;
          font-size: 14px;
          display: none;
        }
      }


      .description {
        font-size: 14px;
        line-height: 20px;
        color: var(--grey-text-dark-blog);
      }

      .search {
        display: flex;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          position: absolute;
          margin-left: 10px;
          filter: var(--icon-grey-dark);

          @include icons.ico("black-search");
        }

        .input {
          height: 34px;
          padding-left: 35px;
          border-width: 1px;
        }
      }

      .line {
        position: relative;
        height: 1px;
        width: 50%;
        background: var(--grey-sur);
        margin-top: 20px;
      }
    }

    .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .loader {
        position: relative;
        width: 100%;
        overflow: hidden;
      }

      .list {
        list-style-type: none;
        margin: 0;
        padding: 0;


        li {

          &.active {
            .category {
              border-left: 6px solid var(--black);
              padding-left: 8px;

              @include texts.font("semi-bold");
            }
          }

          .category {
            display: flex;
            height: 18px;
            align-items: center;
            margin-bottom: 15px;
            cursor: pointer;
            transition: 0.1s;

            &:hover {
              text-decoration: underline;
            }

            .icon {
              img {
                height: 14px;
                width: 14px;
                margin-right: 10px;
              }
            }

            .name {
              font-size: 16px;
            }
          }
        }
      }
    }

    .website {
      background: var(--black);
      width: 100%;
      color: var(--white);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      border-radius: 30px;

      @include texts.font("semi-bold");
    }

    .theme {
      height: 42px;
      overflow: hidden;
      border-radius: 30px;
      margin-bottom: 10px;
      border: 1px solid var(--grey-sur);
    }
  }

  .body {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media screen and (max-width: 950px) {
    display: block;

    .navigation {
      padding: 15px 0 15px 0;
      position: relative;
      height: fit-content;
      width: 100%;

      .content {
        flex-grow: unset;

        .list {
          display: flex;

          li {
            flex: 0 0 auto;
            margin-right: 15px;
          }
        }
      }

      .website, .theme {
        display: none;
      }

      .mobileWebsite {
        display: block !important;
      }

      .input {
        font-size: 16px;
      }
    }
  }
}