import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./paging.scss";
import {Network} from "../../../network/Network";

export class PagingComponent extends Component {

    public paramId = "page";
    public noHash: boolean;

    constructor(noHash?: boolean) {
        super();
        this.noHash = noHash;

        //language=HTML
        this.template = `
            <div class="${s.globalPaging}"></div>`;
    }

    commit() {
        super.commit();
    }

    /**
     * count
     */

    getLimit(): number {
        return 10;
    }

    getOffsetLimit() {
        let page = this.getPageParam();
        if (!page) {
            return 0;
        } else {
            return ((page - 1) > 0 ? (page - 1) : 0) * this.getLimit();
        }
    }

    getCurrentPage(): number {
        return (this.getOffsetLimit() / this.getLimit()) + 1;
    }

    getPageParam(): number {
        return parseInt(Network.router.pages.getParam(this.paramId));
    }

    /**
     * render
     */

    renderPages(findCount: number) {
        let pages = Math.ceil(findCount / this.getLimit());

        let currentPage = this.getCurrentPage();

        let startPage = currentPage - 2;
        let endPage = currentPage + 2;

        if (startPage < 1) {
            startPage = 1;
            endPage = (pages >= 5) ? 5 : pages;
        }

        if (endPage > pages) {
            endPage = pages;
            startPage = pages - 4 > 0 ? pages - 4 : 1;
        }

        if ((currentPage - 1) > 0) {
            //language=HTML
            this.append(`
                <a href="${this.pageUrl(currentPage - 1)}" class="${s.round} ${s.left}">
                    <div class="${s.icon}"></div>
                </a>
            `);
        }

        for (let i = startPage; i <= endPage; i++) {
            this.renderPage(i);
        }

        if ((currentPage + 1) <= pages) {
            //language=HTML
            this.append(`
                <a href="${this.pageUrl(currentPage + 1)}" class="${s.round} ${s.right}">
                    <div class="${s.icon}"></div>
                </a>
            `);
        }
    }

    renderPage(number: number) {
        //language=HTML
        let page = this.append(`
            <a href="${this.pageUrl(number)}" class="${s.round} ${number == this.getCurrentPage() ? s.selected : ""}">
                ${number}
            </a>
        `);

    }

    setPage(number: number) {
        Network.router.pages.setParam(this.paramId, number, true, this.noHash);
        this.onPick();
    }

    pageUrl(number: number) {
        let path = Network.router.pages.getPath();
        path = Network.router.pages.setParamStr(path, "page", number);
        return path;
    }

    /**
     * get
     */

    getPagesRounds(): Component[] {
        return this.els(s.round);
    }

    /**
     * override
     */
    onPick() {

    }
}