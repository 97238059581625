import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IAvatarColorModel} from "../../../models/avatar/color/IAvatarColorModel";

export class AvatarColorService {
    public static colors: IAvatarColorModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.colors = observable([]);
    }


    public static storeAll(colors: IAvatarColorModel[]): IAvatarColorModel[] {
        for (let key in colors)
            colors[key] = this.store(colors[key]);

        return Services.storeAll(colors);
    }

    public static store(color: IAvatarColorModel): IAvatarColorModel {
        return Services.store("color", this.colors, color);
    }
}